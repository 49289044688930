import Navbar from "../../components/Navbar/navbar.component"
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component"
import Cadastro_evento_corp from "../../components/Criar evento corporativo/criar_evento_corp.component"
const PaginaCriarEventoCorp = () => {
  return (<>
    <Cadastro_evento_corp></Cadastro_evento_corp>
    <Navbar active="exercicios"></Navbar>
    <Sidebar_exercicios></Sidebar_exercicios>
  </>)
}
export default PaginaCriarEventoCorp
