import React, { useState, useEffect } from 'react';
import { TiArrowBackOutline, TiArrowForwardOutline } from 'react-icons/ti';
import { IoMdClose } from 'react-icons/io';
import { Body_tutorial, Robo_tutorial, Balao_exemplo, Balao_exemplo_2, Caixa_navegacao } from './tutorial.style.component';

interface ConteudoTutorial {
  setor?: string;
  imagem?: string;
  descricao?: string;
}

interface TutorialScreenProps {
  conteudos?: ConteudoTutorial[];
  estado?: string;
  onClose?: () => void;
}

const Tutorial_screen: React.FC<TutorialScreenProps> = ({ conteudos, estado, onClose }) => {
  type RoboState = 'standby' | 'hello';
  const [estadoRobo, setEstadoRobo] = useState<RoboState>('hello');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [exibir, setExibir] = useState(estado);

  const statesMap: { [key in RoboState]: string } = {
    standby: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/04/Design_sem_nome__6_-removebg-preview.png',
    hello: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/04/Design_sem_nome__8_-removebg-preview.png',
  };

  const handleNext = () => {
    if (conteudos && currentIndex < conteudos.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (conteudos && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const trocarEstadoRobo = () => {
    if (estadoRobo === 'hello') {
      setTimeout(() => {
        setEstadoRobo('standby');
      }, 2000);
    }
  };

  useEffect(() => {
    setExibir(estado);
    trocarEstadoRobo();
  }, [estado]);

  const { setor, imagem, descricao } = conteudos ? conteudos[currentIndex] : { setor: '', imagem: '', descricao: '' };

  return (
    <>
      {exibir === 'show' && (
        <Body_tutorial>
          <h3>Seja bem vindo!</h3>
          <div className="fechar" onClick={onClose}>
            <IoMdClose size={20} />
          </div>
          <div>
            <Robo_tutorial src={statesMap[estadoRobo]} />
            <div className="balao_fala">
              <span>Olá! Este é o setor de {setor}</span>
            </div>
          </div>
          <Balao_exemplo>
            <img alt="imagem_exemplo" src={imagem} />
          </Balao_exemplo>
          <Balao_exemplo_2>
            <span>{descricao}</span>
          </Balao_exemplo_2>
          {conteudos && conteudos.length > 1 && (
            <Caixa_navegacao>
              <button className='left' onClick={handlePrev} disabled={currentIndex === 0}>
                <TiArrowBackOutline color='white' />
              </button>
              <button className='right' onClick={handleNext} disabled={currentIndex === conteudos.length - 1}>
                <TiArrowForwardOutline color='white' />
              </button>
            </Caixa_navegacao>
          )}
        </Body_tutorial>
      )}
    </>
  );
};

export default Tutorial_screen;
