import { Corpo_batePapo, Mensagem_chat, ProfilePic, Head_conversa, Form_chat } from "./chat.style";
import { RiSendPlaneFill } from "react-icons/ri";

interface Mensagem {
  id_mensagem: string;
  autor: string;
  conteudo: string;
  data: string;
  anexo?: string;
}

interface dadosChat {
  id_chat: string;
  participantes: string[];
  mensagens: Mensagem[];
}

const dadosCv: dadosChat = {
  id_chat: '564654654',
  participantes: ['6546545646546', '456465456465', '6546546545646'],
  mensagens: [{ id_mensagem: '5645646', autor: '6546545646546', data: '26/05/2024T04:00:00', conteudo: 'Mensagem de teste' }, { id_mensagem: '5645646', autor: '6546545646546', data: '26/05/2024T04:00:00', conteudo: 'Mensagem de teste' }, { id_mensagem: '5645646', autor: '6546545646546', data: '26/05/2024T04:00:00', conteudo: 'Mensagem de teste' }, { id_mensagem: '5645646', autor: '6546545646546', data: '26/05/2024T04:00:00', conteudo: 'Mensagem de teste' }, { id_mensagem: '5645646', autor: '6546545646546', data: '26/05/2024T04:00:00', conteudo: 'Mensagem de teste' }],
};

const conversas = [dadosCv, dadosCv, dadosCv, dadosCv];

const Conversa_component = () => {
  const userId = '654654564646'; // ID do usuário atual para validação

  const Mapchats = (): React.ReactNode[] => {
    return conversas.flatMap((element) => {
      return element.mensagens.map((mensagem, index) => {
        const messageClass = mensagem.autor === userId ? 'sent' : 'received';
        return (
          <Mensagem_chat className={messageClass} key={mensagem.id_mensagem}>
            <span>{mensagem.conteudo}</span>
          </Mensagem_chat>
        );
      });
    });
  };

  return (
    <Corpo_batePapo>
      <Head_conversa>
        <ProfilePic src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_0.jpg"></ProfilePic>
        <h2>Fulano</h2>
      </Head_conversa>
      {Mapchats()}
      <Form_chat>
        <input type="text" className="input_message" placeholder="Escreva uma mensagem"></input>
        <button className="send_message"><RiSendPlaneFill color="white"></RiSendPlaneFill></button>
      </Form_chat>
    </Corpo_batePapo >
  );
};

export default Conversa_component;
