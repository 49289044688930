import { Section, Section_image, Title, Subtitle } from "./mini_section.style";
import { IconType } from "react-icons";

interface sectProperties {
  title: string;
  description: string;
  icon: IconType;
  className?: string,
  onClick?: () => void; // Adicione a propriedade onClick como opcional
}

const Mini_section: React.FC<sectProperties> = ({ className, title, description, icon: Icon, onClick }: sectProperties) => {
  return (
    <>
      <Section className={className} onClick={onClick}> {/* Propague a propriedade onClick para o elemento Section */}
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
        <Section_image className={className}><Icon size={100} /></Section_image>
      </Section>
    </>
  );
};

export default Mini_section;
