import { Sidebar_base, Sidebar_icon, List, LItem, LoginArea, Profile_pic, Profile, UserName, Button, Botao_ajuda } from "./sidebar.style.component";
import { useState, useEffect } from "react";
import { ImExit } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { manterLogado, obterDadosSociais } from "../logics/def_functions";
import { MdOutlineLiveHelp } from "react-icons/md";
import Tutorial_screen from "../Tutorial/tutorial.component";
import meditar from '../Assets/Images/meditar.png'
import psicologia from '../Assets/Images/psicologia.png'
import playlists from '../Assets/Images/playlist-meditar.png'
const Sidebar_meditacao = () => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const [emailDeUsuario, setEmailDeUsuario] = useState('')
  const [imagemPerfil, defImagemPerfil] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
  const [tutorial, showTutorial] = useState(false)

  const inserirUsuario = async () => {
    const usuarioLogado = await obterDadosSociais({ metodo: 'self' })
    if (usuarioLogado.status == 200) {
      setEmailDeUsuario(usuarioLogado?.info?.user_full_name)
      defImagemPerfil(usuarioLogado?.info?.prof_pic)
    } else {
      navigate('/social/edit_social')
    }
  }
  const logoff = () => {
    ////console.log('Realizando logoff')
    localStorage.removeItem('token_acesso_LCMFIT');
    navigate('/login')
  };

  useEffect(() => {
    const logado = manterLogado()
    if (!logado) {
      navigate('/login')
    }
    inserirUsuario()
  }, [])
  const toPlaylist = () => {
    navigate("/meditacao/playlist_concentrar")
  }
  const toMeditar = () => {
    navigate('/meditacao/playlist_meditar')
  }
  const toSaudeMental = () => {
    window.location.href = 'https://consultorioonline.psicologiaviva.com.br/cadastro'
  }


  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  }

  const conteudos = [{
    setor: 'Meditação',
    imagem: meditar,
    descricao: 'Escolha uma frequência, musica de fundo ou som ambiente para meditar e se concentrar'
  }, {
    setor: 'Saúde mental',
    imagem: psicologia, descricao: "Cuide da sua mente usando o Psicologia Viva, um programa com psicologos acessíveis remotamente a preços a partir de 35R$ por sessão, podendo utilizar do plano de saúde da LCM."
  }, {
    setor: 'Playlists', imagem: playlists, descricao: "Escolha uma playlist de sons voltados a concentração e produtividade."
  },]

  return (
    <>
      <Sidebar_base isExpanded={isExpanded}>
        {isExpanded && (<><List>
          <LItem onClick={toMeditar}>Iniciar meditacao</LItem>
          <LItem onClick={toSaudeMental}>Saude mental</LItem>
          <LItem onClick={toPlaylist}>Playlists</LItem>
        </List>
          {tutorial && (<Tutorial_screen estado='show' conteudos={conteudos} onClose={() => showTutorial(false)}></Tutorial_screen>)}<Botao_ajuda onClick={() => showTutorial(true)}><MdOutlineLiveHelp size={50} color="white"></MdOutlineLiveHelp><span>Ajuda</span></Botao_ajuda>
          <LoginArea>
            <Profile>
              <Profile_pic src={imagemPerfil}></Profile_pic>
              <UserName>{emailDeUsuario}</UserName>
            </Profile>
            <Button><ImExit size={30} onClick={() => logoff()} /></Button>
          </LoginArea>
        </>)}
        <Sidebar_icon onClick={toggleSidebar}></Sidebar_icon>
      </Sidebar_base>
    </>
  )
}
export default Sidebar_meditacao
