import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";
import Perfil_generico from "../../components/Perfil generico/profile_main.component";
import ReturnButton from "../../components/Botao retornar/return.component";
const Perfil_social_generico = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Perfil_generico></Perfil_generico>
    <Sidebar_social />
    <Navbar active="social" />
  </>);
};
export default Perfil_social_generico;
