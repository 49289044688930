import Container_nutricao from "../../components/Sections/container_nutricao.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_nutricao from "../../components/Sidebar/sidebar.nutricao.component";

const Pagina_nutricao = () => {
  return (<>
    <Container_nutricao></Container_nutricao>
    <Navbar active="nutricao"></Navbar>
    <Sidebar_nutricao></Sidebar_nutricao></>)
}

export default Pagina_nutricao
