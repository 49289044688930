import Conversa_component from "../../components/Chat/conversa.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";
import Navbar from "../../components/Navbar/navbar.component";
import ReturnButton from "../../components/Botao retornar/return.component";
const Pagina_conversa = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Conversa_component></Conversa_component>
    <Sidebar_social></Sidebar_social>
    <Navbar></Navbar>
  </>)
}
export default Pagina_conversa
