import styled from 'styled-components'

export const Header_feed = styled.div`
  width: 85%;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  padding: 3rem 1rem 1rem 1rem;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
`

export const Buttons_feed = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.primary};
  border: 0.1rem solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &.active {
    transform: scale(1.05);
    background-color: ${(props) => props.theme.colors.primary};
  }
`
export const Posts_grid = styled.div`
  width: 85%;
  background-color: ${(props) => props.theme.colors.gray200};
  padding: 1rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr;
`
export const SolicitarMaisPosts = styled.div`
  margin: 0.5rem auto;
  border-radius: 0.5rem;
  width: 80%;
  background-color: ${(props) => props.theme.colors.gray400};
  color: white;
  text-align: center;
  padding: 1rem;
  margin-bottom: 7rem;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.02);
    background-color: ${(props) => props.theme.colors.gray600};
  }
`
