import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Formulario, Button, Input_image, Image_area, Button_back } from "./criar_evento_social.style";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { marcarEvento } from '../logics/def_functions';
import { CloudinaryService } from '../logics/cloudinaryUploader';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';

const Cadastro_evento_social: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [estadoGeral, defEstadoGeral] = useState('standby')
  const [mensagemGeral, defMensagemGeral] = useState('')
  const tipo_evento = location.pathname.split("/")[2]
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [timeError, setTimeError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const cloudinaryService = new CloudinaryService


  const backToHome = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate('/exercicios/eventos_sociais');
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageLink = await cloudinaryService.upload(file)
      setImageUrl(imageLink.info);
    }
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  const registerInfo = async (event: React.MouseEvent) => {
    event.preventDefault();
    const nomeEvento = (document.querySelector('#nome_evento') as HTMLInputElement).value;
    const descricaoEvento = (document.querySelector('#descricao_evento') as HTMLInputElement).value;
    const localizacaoEvento = (document.querySelector('#localizacao') as HTMLInputElement).value;
    const dataEvento = (document.querySelector('#data') as HTMLInputElement).value;
    const horaEvento = (document.querySelector('#hora') as HTMLInputElement).value;

    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
    const timePattern = /^\d{2}:\d{2}$/;

    if (!datePattern.test(dataEvento)) {
      setDateError('Por favor, insira uma data no formato dd/mm/yyyy.');
      alert('Por favor, insira uma data no formato dd/mm/yyyy.')
      return;
    } else {
      setDateError(null);
    }

    if (!timePattern.test(horaEvento)) {
      setTimeError('Por favor, insira uma hora no formato HH:mm.');
      alert('Por favor, insira uma hora no formato HH:mm.')
      return;
    } else {
      setTimeError(null);
    }

    const infoEvento = {
      tipo_evento: tipo_evento,
      info: {
        imagem_evento: imageUrl || '',
        titulo_evento: nomeEvento,
        descricao_evento: descricaoEvento,
        local_evento: localizacaoEvento,
        data_evento: dataEvento,
        hora_evento: horaEvento
      }
    };
    console.log('Informações do evento', infoEvento)
    const marcacao = await marcarEvento(infoEvento)
    console.log(marcacao)
    if (marcacao.status == 200) {
      defEstadoGeral('success')
      defMensagemGeral('Sucesso na criação do evento')
    } else {
      defEstadoGeral('failed')
      defMensagemGeral('Falha na criação do evento')
    }
  }

  useEffect(() => {
    const preventPullToRefresh = (event: TouchEvent) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchstart', preventPullToRefresh, { passive: false });

    return () => {
      document.removeEventListener('touchstart', preventPullToRefresh);
    };
  }, []);

  return (
    <Container>
      <Button_back onClick={backToHome}><IoIosArrowBack /></Button_back>
      <h2>Insira os dados do evento social</h2>
      <Formulario>
        <label htmlFor='nome_evento'>Insira um título para o evento:</label>
        <input className="input" id='nome_evento' type="text" />
        <label htmlFor='data'>Descreva a data do evento:</label>
        <input className="input" id='data' type="text" />
        <label htmlFor='hora'>Descreva o horário do evento:</label>
        <input className="input" id='hora' type="text" />
        <label htmlFor='descricao_evento'>Insira uma breve descrição para o evento:</label>
        <input className="input" id='descricao_evento' type="text" />
        <label htmlFor='localizacao'>Descreva aonde o evento ocorrerá:</label>
        <input className="input" id='localizacao' type="text" />
        <label htmlFor='foto_evento'>Escolher uma foto para destaque do evento:</label>
        <Image_area>
          <Input_image type='file' ref={fileInputRef} onChange={handleImageUpload} />
          <Button onClick={handleButtonClick}>
            <MdOutlineAddPhotoAlternate size={30} />
          </Button>
        </Image_area>
        {imageUrl && (
          <>
            <span>Pré-visualização da imagem</span>
            <img src={imageUrl} alt="Preview" style={{ maxWidth: '15rem', marginTop: '10px' }} />
          </>
        )}
        <Button onClick={registerInfo}>Cadastrar Evento Social</Button>
      </Formulario>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    </Container>
  );
}
/* Espaço para a lógica de criação de evento
const edicao = await EditarOuCriarSocial(infoEvento);
if (edicao?.status == 200 || edicao?.status == 201) {
  navigate('/perfil');
} else if (edicao?.status == 403) {
  alert('Autenticação inválida, realize o login novamente: ')
  navigate('/login')
} else {
  alert(`Houve uma falha: ${edicao?.status}`)
}
};*/



export default Cadastro_evento_social;
