import styled from 'styled-components'
export const Section = styled.div`
  text-align: center;
  margin-left: 1rem;
  width: 70%;
  background-color: ${(props) => props.theme.colors.primary};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  -webkit-box-shadow: inset 0px 0px 46px -15px rgba(245, 245, 245, 0.67);
  -moz-box-shadow: inset 0px 0px 46px -15px rgba(245, 245, 245, 0.67);
  box-shadow: inset 0px 0px 100px -15px rgba(245, 245, 245, 0.67);
  transition: all 0.5s;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    gap: 0.5rem !important;
  }
  &.saude {
    background-color: ${(props) => props.theme.colors.cor_paleta_3};
  }
  &.exercicios {
    background-color: ${(props) => props.theme.colors.cor_paleta_2};
  }
  &.social {
    background-color: ${(props) => props.theme.colors.cor_paleta_4};
  }
  &.meditacao {
    background-color: ${(props) => props.theme.colors.cor_paleta_5};
  }
  &.nutricao {
    background-color: ${(props) => props.theme.colors.cor_paleta_1};
  }
`
export const Section_image = styled.div`
  width: 50%;
  aspect-ratio: 16/9;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: inset 0px 0px 46px -15px rgba(245, 245, 245, 0.67);
  -moz-box-shadow: inset 0px 0px 46px -15px rgba(245, 245, 245, 0.67);
  box-shadow: inset 0px 0px 100px -15px rgba(245, 245, 245, 0.67);
  transition: all 0.5s;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 80% !important;
  }
  &.saude {
    background-color: ${(props) => props.theme.colors.cor_paleta_3};
  }
  &.exercicios {
    background-color: ${(props) => props.theme.colors.cor_paleta_2};
  }
  &.social {
    background-color: ${(props) => props.theme.colors.cor_paleta_4};
  }
  &.meditacao {
    background-color: ${(props) => props.theme.colors.cor_paleta_5};
  }
  &.nutricao {
    background-color: ${(props) => props.theme.colors.cor_paleta_1};
  }
`
export const Title = styled.h2`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize['4xl']};
  display: inline;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.fontSize['2xl']} !important;
    margin: 0;
  }
`

export const Subtitle = styled.span`
  font-size: ${(props) => props.theme.fontSize['3xl']};
  color: ${(props) => props.theme.colors.white};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.fontSize.lg} !important;
  }
`
