import React, { useState, useEffect, useCallback, useRef } from 'react';
import QuantidadeLikes from '../Like_meter/like_meter.component';
import Comentario from '../Coment/comment.component';
import Mini_Perfil from '../Mini_profile/profile_mini.component';
import { New_comment, New_comment_container, Comentar } from '../Grid_profile/grid_profile.component.style';
import { IoMdClose, IoIosSend } from "react-icons/io";
import { Posts, Post_img, Big_img_div, Big_img, Close_button } from './post.component.style';
import { obterDadosSociais, verComentario, inserirComentario, notificar } from '../logics/def_functions';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';


interface PostProps {
  _id: string;
  post_author: string;
  post_date: string;
  user_email: string;
  post_description: string;
  post_image: string;
  post_likes: string[];
  post_comments: string[];
}

interface CommentProps {
  comment_author: string;
  comment_date: string;
  comment_likes: string[];
  comment_replies: string[];
  comment_text: string;
  user_email: string;
  _id: string;
  usuario?: ProfileProps;
}

interface ProfileProps {
  posts: string[];
  _id: string;
  user_name: string;
  user_full_name: string;
  user_email: string;
  prof_pic: string;
  bio: string;
  followers: string[];
  following: string[];
}

interface DadosTarget {
  target_id: string;
  conteudo?: string;
  target: string;
}

const Post: React.FC<PostProps> = React.memo((post) => {
  const [estadoGeral, defEstadoGeral] = useState('standby')
  const [mensagemGeral, defMensagemGeral] = useState('')
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [perfilDefinido, setPerfil] = useState<ProfileProps | null>(null);
  const [meuPerfil, setMeuPerfil] = useState<ProfileProps | null>(null)
  const [comentarios, setComentarios] = useState<CommentProps[]>([]);
  const [mostrarTodosComentarios, setMostrarTodosComentarios] = useState(false);
  const comment_input = useRef<HTMLInputElement>(null);

  const makeComment = async () => {
    if (comment_input.current) {
      const textComment = comment_input.current.value || '';
      const pacote: DadosTarget = {
        target_id: post?._id || '',
        conteudo: textComment,
        target: 'post'
      };
      try {
        const salvarComentario = await inserirComentario(pacote);
        notificar({
          id_objeto: pacote.target_id, tipo_objeto: pacote.target,
          tipo_interacao: 'comment'
        });
        if (salvarComentario?.status !== 200) {
          defEstadoGeral('success')
          defMensagemGeral('Comentário publicado com sucesso')
        }
      } catch (error) {
        defEstadoGeral('failed')
        defMensagemGeral('Falha ao comentar, tente novamente mais tarde')
      }
    }
  };

  const verComment = async (id_comentario: string) => {
    const comentario = await verComentario(id_comentario);
    const usuario = await obterDadosSociais({ metodo: 'others', nome_usuario: comentario.info.comment_author });
    return { ...comentario.info, usuario: usuario.info };
  };

  const fetchComments = useCallback(async () => {
    const promises = post.post_comments.map((element) => verComment(element));
    const commentsArray = await Promise.all(promises);
    setComentarios(commentsArray);
  }, [post.post_comments]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleClickImg = useCallback((Bool: boolean) => {
    setMostrarComponente(Bool);
  }, []);

  const getUserData = useCallback(async () => {
    const obterPerfil = await obterDadosSociais({ metodo: 'others', nome_usuario: post.post_author });
    if (obterPerfil) {
      setPerfil(obterPerfil.info);
    }
  }, [post.post_author]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleMostrarTodosComentarios = () => {
    setMostrarTodosComentarios(true);
  };

  const comentariosExibidos = mostrarTodosComentarios ? comentarios : comentarios.slice(0, 3);

  return (
    <Posts>
      {mostrarComponente && (
        <Big_img_div>
          <Big_img src={post.post_image} />
          <Close_button onClick={() => handleClickImg(false)}>
            <IoMdClose />
          </Close_button>
        </Big_img_div>
      )}
      <Mini_Perfil perfil={perfilDefinido} />
      <Post_img src={post.post_image} onClick={() => handleClickImg(true)} alt="Post" />
      <span><strong>{post.post_author} </strong>{post.post_description}</span>
      <QuantidadeLikes liked={true} likes={post.post_likes.length} info_post={post} />
      <div className='comentarios_destaque'>
        {post.post_comments.length === 0 ? (
          <span>Sem comentários, seja o primeiro!</span>
        ) : (
          <>
            {comentariosExibidos.length > 0 && comentariosExibidos.map((comentario) => (
              <Comentario key={comentario._id} {...comentario} />
            ))}
            {comentarios.length > 2 && !mostrarTodosComentarios && (
              <button onClick={handleMostrarTodosComentarios}>Ver todos os comentários</button>
            )}
            <span>{post.post_comments.length} comentários</span>
          </>
        )}
        <New_comment_container>
          <New_comment ref={comment_input} placeholder='Insira um comentário'></New_comment>
          <Comentar onClick={makeComment}>
            <IoIosSend size={20} color='white' />
          </Comentar>
        </New_comment_container>
      </div>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    </Posts>
  );
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

Post.displayName = 'Post';

export default Post;
