import styled from 'styled-components'

export const Header_sugestoes = styled.div`
  width: 75svw;
  margin-left: 2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.primary};
`

export const Botao_seguir = styled.button`
  &.seguindo {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.cyan};
  }
`

export const Campo_pesquisa = styled.div`
  width: 70svw;
  margin-left: 2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 2rem 1rem;
  background-color: ${(props) => props.theme.colors.primary};
`

export const Lista_usuarios = styled.ul`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.gray500};
  width: 100%;
  border-radius: 0.5rem;
  align-self: center;
  list-style: none;
  > li {
    width: 80%;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.gray700};
  }
`

export const Perfil_usuario = styled.li`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > img {
    width: 2rem;
    aspect-ratio: 4/4;
    border-radius: 50%;
  }
  > button {
    border: none;
    background-color: ${(props) => props.theme.colors.cyan};
    color: ${(props) => props.theme.colors.white};
    border-radius: 0.5rem;
    padding: 0.3rem 0.2rem;
  }
`
