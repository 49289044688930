import styled from 'styled-components'
export const Post_grid = styled.div`
  width: calc(100% - 1rem);
  height: fit-content;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.4rem;
  padding: 0.3rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.gray400};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    align-self: center;
  }
  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Mini_post_container = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  cursor: pointer;
`

export const Mini_post = styled.img`
  width: 100%;
  object-fit: cover;
`
export const Interactions_Box = styled.div`
  width: 90%;
  background-color: ${(props) => props.theme.colors.gray200};
  height: 30%;
  padding: 1rem;
  border-radius: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${(props) => props.theme.colors.black};
`
export const Header_interactions = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Comments_section = styled.div`
  width: 90%;
  height: 45%;
  overflow-x: hidden;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  align-self: center;
  background-color: ${(props) => props.theme.colors.gray300};
`
export const New_comment = styled.input`
  width: 95%;
  padding: 1rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.white};
  align-self: center;
`
export const New_comment_container = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  gap: 0.5rem;
`
export const Comentar = styled.button`
  width: 15%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: none;
  padding: 0.5rem;
  right: 0.5rem;
  background-color: ${(props) => props.theme.colors.primary};
  &:hover {
    transform: scale(1.02);
  }
`
export const ButtonDelete = styled.button`
  width: 2.5rem;
  aspect-ratio: 1/1;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.5rem;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  position: fixed;
  top: 6rem;
  right: 2rem;
  z-index: 20;
  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.02);
  }
`
export const PopupConfirmation = styled.div`
  width: 15rem;
  height: 12rem;
  background-color: ${(props) => props.theme.colors.gray200};
  border: 0.1rem solid ${(props) => props.theme.colors.gray500};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  text-align: center;
  z-index: 20;
  position: fixed;
  border-radius: 0.5rem;
  > span {
    color: ${(props) => props.theme.colors.gray800};
  }
  > div {
    width: 8rem;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
  }
`
