const diferenca_data = (dataEspecifica: string | number | Date): string => {
  const dataEspecificaObj = new Date(dataEspecifica)
  const dataAtual = new Date()
  const diferencaMilissegundos = dataAtual.getTime() - dataEspecificaObj.getTime()
  const diferencaDias = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24))
  const diferencaHoras = Math.floor(
    (diferencaMilissegundos % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  )
  const diferencaMinutos = Math.floor((diferencaMilissegundos % (1000 * 60 * 60)) / (1000 * 60))

  if (diferencaDias > 1) {
    return `há ${diferencaDias} dias`
  } else if (diferencaHoras > 1) {
    return `há ${diferencaHoras} horas atrás`
  } else if (diferencaMinutos > 1) {
    return `há ${diferencaMinutos} minutos atrás`
  } else {
    return `agora`
  }
}

export default diferenca_data
