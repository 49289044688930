import styled from 'styled-components'

export const Corpo_lista_exames = styled.div`
  border-radius: 0.5rem;
  margin-left: 3rem;
  margin-top: 1rem;
  text-align: center;
  width: 75vw;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 1rem;
`
export const Lista_exames = styled.ul`
  padding: 0.5rem 1rem;
  margin-left: 3rem;
  width: 75vw;
  gap: 0.2rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray200};
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    width: 90%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: ${(props) => props.theme.colors.light_green};
    text-align: center;
  }
`
