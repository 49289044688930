import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReturnButton from "../../components/Botao retornar/return.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";
import Navbar from "../../components/Navbar/navbar.component";
import PostNotificacao from "../../components/Post notificacao/post.component";
import { obterPostInfo } from "../../components/logics/def_functions";

interface PostProps {
  _id: string;
  post_author: string;
  post_date: string;
  user_email: string;
  post_description: string;
  post_image: string;
  post_likes: string[];
  post_comments: string[];
}

const Post_individual = () => {
  const location = useLocation();
  const [post_a_exibir, setPostAExibir] = useState<string>('');
  const [post, defPost] = useState<PostProps>({
    _id: '',
    post_author: '',
    post_date: '',
    user_email: '',
    post_description: '',
    post_image: '',
    post_likes: [],
    post_comments: []
  });

  useEffect(() => {
    const queryParam = location.search.substring(1);
    ////console.log('Query param lido: ', queryParam);
    setPostAExibir(queryParam);
  }, [location.search]);

  useEffect(() => {
    const fetchInfo = async () => {
      if (post_a_exibir) {
        ////console.log('Informação a ser inserida na função: ', post_a_exibir+ '. Será feita a chamada a api');
        const obterPost = await obterPostInfo(`${post_a_exibir}`);
        ////console.log('Informações obtidas: ', obterPost);
        if (obterPost.info) {
          defPost(obterPost.info.info);
        }
      } else {
        ////console.log('post_a_exibir está vazio, não será feita chamada à API');
      }
    };
    fetchInfo();
  }, [post_a_exibir]);

  return (
    <>
      <PostNotificacao {...post}></PostNotificacao>
      <ReturnButton></ReturnButton>
      <Sidebar_social></Sidebar_social>
      <Navbar active="social"></Navbar>
    </>
  );
};

export default Post_individual;
