import Camera from "../../components/Cam/cam.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";
import Navbar from "../../components/Navbar/navbar.component";
const Camera_pagina = () => {
  return (<>
    <Sidebar_social></Sidebar_social>
    <Camera></Camera>
    <Navbar active="social"></Navbar>
  </>)
}

export default Camera_pagina
