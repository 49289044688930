import { useState, useEffect } from 'react';
import { Container, PlaceHolder, TitleDiv } from './container_padrao.component.style'
import Mini_section from '../Mini_section/mini_section.component'
import { MdOutlineAddAPhoto } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { BsFillPostcardHeartFill } from "react-icons/bs";
import { IoChatbubblesOutline, IoNotificationsOutline } from "react-icons/io5";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';
import { obterDadosSociais } from '../logics/def_functions';
import logo from '../Assets/Images/newlogo.svg';
import escrita from '../Assets/Images/escrita.webp'

const Container_social = () => {
  const navigate = useNavigate()
  const [nomeUsuario, setNomeUsuario] = useState('')
  const toProfile = () => {
    navigate(`/social/perfil?${nomeUsuario}`)
  }

  const toCamera = () => {
    navigate('/social/camera')
  }
  const toFeed = () => {
    navigate('/social/feed')
  }
  const toNotificacoes = () => {
    navigate('/social/notificacoes')
  }
  const toSugestoes = () => {
    navigate('/social/sugestoes')
  }

  const usuarioLogado = async () => {
    const perfilUsuario = await obterDadosSociais({ metodo: 'self' })
    setNomeUsuario(perfilUsuario.info.user_name)
  }

  useEffect(() => {
    usuarioLogado()
  }, [])
  /*const toChat = () => {
    navigate('/social/bate_papo')
  }*/

  /*<Mini_section title='Bate papo' description='Inicie uma conversa ou crie um grupo de bate papo' icon={IoChatbubblesOutline} onClick={toChat}></Mini_section> */

  return <>
    <Container>
      <TitleDiv><img
        style={{ width: '6rem', height: 'auto' }}
        src={escrita}
        alt="Logo LCM"
      /><img
          style={{ width: '8rem', height: 'auto' }}
          src={logo}
          alt="Logo LCM"
        /></TitleDiv>
      <Mini_section className='social' title='Notificações' description='Veja as interações de outros usuários' icon={IoNotificationsOutline} onClick={toNotificacoes}></Mini_section>
      <Mini_section className='social' title='Feed de atividades' description='Veja o que seus amigos estão postando' icon={BsFillPostcardHeartFill} onClick={toFeed}></Mini_section>
      <Mini_section className='social' title='Publicar' description='Publique uma nova foto' icon={MdOutlineAddAPhoto} onClick={toCamera}></Mini_section>
      <Mini_section className='social' title='Perfil' description='Veja suas publicações e seu perfil' icon={ImProfile} onClick={toProfile}></Mini_section>
      <Mini_section className='social' title='Pessoas que você talvez conheça' description='Adcione e interaja com outros usuarios' icon={LiaUserFriendsSolid} onClick={toSugestoes}></Mini_section>
      <PlaceHolder></PlaceHolder>
    </Container>
  </>
}

export default Container_social
