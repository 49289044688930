export class CloudinaryService {
  private uploadPreset: string
  private cloudname: string
  constructor() {
    this.uploadPreset = 'ss3eckog'
    this.cloudname = 'dqmss4ddc'
    if (!this.uploadPreset || !this.cloudname) {
      throw new Error('Credenciais ausentes')
    }
    console.log('Credenciais identificadas')
  }
  private async uploadFile(file: File): Promise<{ status: number; message: string; info: any }> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', this.uploadPreset)
    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${this.cloudname}/image/upload`,
        {
          method: 'POST',
          body: formData,
        },
      )
      const data = await response.json()
      console.log('Verificando resultados:', data)
      return {
        status: 200,
        message: 'Sucesso no upload',
        info: data.secure_url,
      }
    } catch (error) {
      return {
        status: 500,
        message: 'Falha no upload',
        info: error,
      }
    }
  }
  public upload(file: File): Promise<{ status: number; message: string; info: any }> {
    return this.uploadFile(file)
  }
}
