import styled from 'styled-components'

export const Mini_perfil = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.primary};
  min-width: fit-content;
  max-width: ${(props) => props.theme.breakpoints.sm};
  padding: 0.5rem;
  border-radius: 2rem;
  gap: 1rem;
  align-self: flex-start;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary} !important;
    transform: scale(1.05);
  }
`
export const Prof_pic = styled.img`
  background-color: ${(props) => props.theme.colors.light_green};
  border-radius: 50%;
  height: 32px;
  width: 32px;
`
export const Username = styled.span`
  color: ${(props) => props.theme.colors.gray300};
  font-weight: bold;
`
export const Last_active = styled.span`
  color: ${(props) => props.theme.colors.gray300};
  font-weight: bold;
`
