import { useState } from 'react';
import { Navbar_comp, Button_navigation, Image } from './navbar.component.style';
import { useNavigate } from 'react-router-dom';
import { GiHealthNormal, GiForkKnifeSpoon } from "react-icons/gi";
import { FaDumbbell } from "react-icons/fa";
import { GrGroup } from "react-icons/gr";

interface NavbarProps {
  active?: string
}

const Navbar = ({ active = '' }: NavbarProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate()
  const toSaude = () => {
    navigate('/saude')
  }
  const toExercicios = () => {
    navigate('/exercicios')
  }

  const toSocial = () => {
    navigate('/social')
  }
  const toMeditate = () => {
    navigate('/meditacao')
  }
  const toNutricao = () => {
    navigate('/nutricao')
  }
  return (
    <Navbar_comp>
      <Button_navigation className={`${isClicked ? 'clicked' : ''} ${active === 'saude' ? 'active saude' : ''}`} onClick={() => {
        setIsClicked(true)
        toSaude()
      }}>
        <GiHealthNormal color='black' size={25}></GiHealthNormal>
      </Button_navigation>
      <Button_navigation className={`${isClicked ? 'clicked' : ''} ${active === 'exercicios' ? 'active exercicios' : ''}`} onClick={() => {
        setIsClicked(true)
        toExercicios()
      }}><FaDumbbell size={25} color='black'></FaDumbbell></Button_navigation>
      <Button_navigation className={`${isClicked ? 'clicked' : ''} ${active === 'social' ? 'active social' : ''}`} onClick={() => {
        setIsClicked(true)
        toSocial()
      }}><GrGroup size={25} color='black'></GrGroup></Button_navigation>
      <Button_navigation className={`${isClicked ? 'clicked' : ''} ${active === 'meditar' ? 'active meditar' : ''}`} onClick={() => {
        setIsClicked(true)
        toMeditate()
      }}><Image width={300} height={300} src={'https://img.icons8.com/sf-regular/48/ffffff/guru.png'} /></Button_navigation>
      <Button_navigation className={`${isClicked ? 'clicked' : ''} ${active === 'nutricao' ? 'active nutricao' : ''}`} onClick={() => {
        setIsClicked(true)
        toNutricao()
      }}><GiForkKnifeSpoon size={25} color='black'></GiForkKnifeSpoon></Button_navigation>
    </Navbar_comp>)
}

export default Navbar
