import CalendarNutri from "../../components/Calendar Nutri/calendar.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_nutricao from "../../components/Sidebar/sidebar.nutricao.component";
import ReturnButton from "../../components/Botao retornar/return.component";
import { Head_container, Title } from "./style";


const Pagina_Agendar_nutri = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Head_container><Title>Agendamentos e Consultas com nutricionista</Title></Head_container>
    <CalendarNutri></CalendarNutri>
    <Navbar active="nutricao"></Navbar>
    <Sidebar_nutricao></Sidebar_nutricao></>)
}
export default Pagina_Agendar_nutri
