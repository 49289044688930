import { Container, PlaceHolder, TitleDiv } from './container_padrao.component.style'
import Mini_section from '../Mini_section/mini_section.component'
import { CiMedicalCase } from "react-icons/ci";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { GiHealthNormal } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Images/newlogo.svg';
import escrita from '../Assets/Images/escrita.webp'

const Container_saude = () => {
  const navigate = useNavigate()
  const toConsultas = () => {
    navigate('/saude/consultas')
  }
  const toDados = () => {
    navigate('/saude/dados')
  }

  const toExames = () => {
    navigate('/saude/exames')
  }
  return <>
    <Container>
      <TitleDiv><img
        style={{ width: '6rem', height: 'auto' }}
        src={escrita}
        alt="Logo LCM"
      /><img
          style={{ width: '8rem', height: 'auto' }}
          src={logo}
          alt="Logo LCM"
        /></TitleDiv>
      <Mini_section className='saude' title='Consultas' description='Agende uma consulta com a Dra. Walnéia' icon={CiMedicalCase} onClick={toConsultas}></Mini_section>
      <Mini_section className='saude' title='Meus exames' description='Consulte seus resultados de exames' icon={HiOutlineClipboardDocumentList} onClick={toExames}></Mini_section>
      <Mini_section className='saude' title='Meus dados' description='Insira e consulte informações importantes em caso de primeiros socorros' icon={GiHealthNormal} onClick={toDados}></Mini_section>
      <PlaceHolder></PlaceHolder>
    </Container>
  </>
}

export default Container_saude
