import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_meditacao from "../../components/Sidebar/sidebar.meditacao.component";
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component";
import Playlist_component from "../../components/Playlists Component/playlist.component";
import ReturnButton from "../../components/Botao retornar/return.component";

const Pagina_playlists = () => {
  const [active, setActive] = useState('');
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname;
    if (url.includes('playlist_concentrar')) {
      setActive('meditar');
    } else if (url.includes('playlist_meditar')) {
      setActive('meditar');
    } else if (url.includes('exercicios')) {
      setActive('exercicios');
    }
  }, [location.pathname]);

  return (
    <>
      <ReturnButton />
      <Playlist_component />
      {active == 'meditar' && <Sidebar_meditacao></Sidebar_meditacao>}
      {active == 'exercicios' && <Sidebar_exercicios></Sidebar_exercicios>}
      <Navbar active={active} />
    </>
  );
}

export default Pagina_playlists;
