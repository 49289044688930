import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegCalendar, FaPlus } from 'react-icons/fa';
import { Header_eventos, Container_eventos, Container_evento, Date_evento, Button_CriarNovo, ElementoLi } from "./corp_eventos.component.style";
import { getEventosCorporativos, obterDadosSociais } from '../logics/def_functions';
import { timestampToData } from '../logics/functions';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';


interface dadosEvento {
  id: string,
  creator: string,
  image: string,
  start: string,
  end: string,
  title: string,
  summary: string,
  link: string
  location: string
}

const Corp_events = () => {
  const [estadoGeral, setEstadoGeral] = useState("loading")
  const [mensagemGeral, setMensagemGeral] = useState("Carregando")
  const [eventos, setEventos] = useState<React.ReactNode[] | null>(null);
  const navigate = useNavigate()
  const toEvento = (id: string) => {
    navigate('/exercicios/eventos_corporativos/evento?id=' + id);
  };
  const toCriarEventoCorp = () => {
    navigate('/exercicios/eventos_corporativos/criar_evento')
  }
  const ObterNomeSocial = async (informacao: string) => {
    const dados = await obterDadosSociais({ email_usuario: informacao, metodo: 'others' });
    return dados.info.user_full_name;
  };

  const fetchData = useCallback(async () => {
    const eventosComunitarios = await getEventosCorporativos() || [];
    //console.log('Resposta dos eventos', eventosComunitarios)
    const eventosAExibirPromises = eventosComunitarios.events.map(async (evento: dadosEvento) => {
      const creatorName = await ObterNomeSocial(evento?.creator);
      return (
        <Container_evento key={evento.id} backgroundImage={evento.image.replace("imagem:", '')} onClick={() => toEvento(evento.id)}>
          <FaRegCalendar size={100} className="content" />
          <Date_evento className="content">{timestampToData(evento.start)}</Date_evento>
          <div className='disclaimer_evento content'>
            <h2>{String(evento.title).replace("titulo:", '') || ''}</h2>
            <span>{String(evento.location).replace("local:", '') || ''}</span>
            <span>{creatorName || ''}</span>
          </div>
        </Container_evento>
      );
    });

    const eventosAExibir = await Promise.all(eventosAExibirPromises);
    setEventos(eventosAExibir);
  }, []);

  useEffect(() => {
    fetchData().then(() => {
      setEstadoGeral('standby')
      setMensagemGeral('')
    })
  }, [fetchData]);


  return (
    <>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Header_eventos>
        <h2>Eventos corporativos no próximo mês</h2>
      </Header_eventos>
      <Container_eventos>
        {eventos}
        {eventos == null || eventos.length == 0 && <ElementoLi><span>Sem eventos</span></ElementoLi>}
      </Container_eventos>
      <Button_CriarNovo onClick={toCriarEventoCorp}><FaPlus color='white' size={30}></FaPlus></Button_CriarNovo>
    </>
  );
};

export default Corp_events;
