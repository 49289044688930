import styled from 'styled-components'
export const Perfil_emergencia = styled.div`
  width: 75vw;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 2.25rem;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  position: relative;
`
export const Imagem_perfil = styled.img`
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  border: 0.1rem solid ${(props) => props.theme.colors.white};
`
export const Edit_button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.3rem;
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`
interface normalButtonProps {
  value?: string
}
export const Normal_button = styled.button<normalButtonProps>`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0.3rem;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  &:hover {
    transform: scale(1.05);
  }
`
export const Campos_input = styled.form`
  width: 75vw;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.gray200};
  margin-left: 2.25rem;
  margin-bottom: 3rem;
  height: fit-content;
  text-align: center;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  > input {
    padding: 1rem;
    border-radius: 0.3rem;
  }
`
export const Organizer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  > * {
    width: 100%;
    display: flex;
    flex-direction: row;
    & div {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`
export const List_contacts = styled.ul`
  width: 100%;
  padding: 0 !important;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  list-style: none;
  margin-top: 0;
  margin-bottom: 8rem;
  > * {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & input {
      align-self: center;
      width: 90%;
      text-align: center;
      padding: 0.5rem;
    }
  }
`
