import React from 'react';
import { Comment as CommentStyle, Prof_pic_mini, Comment_organizer } from './comment.component.style';
import QuantidadeLikes from '../Like_meter/like_meter.component';
import diferenca_data from '../logics/timestamp_to_now';

interface CommentProps {
  comment_author: string;
  comment_date: string;
  comment_likes?: string[];
  comment_replies?: string[];
  comment_text: string;
  user_email: string;
  _id: string;
  usuario?: social_info;
}

interface social_info {
  _id?: string;
  bio?: string;
  prof_pic?: string;
  user_email?: string;
  user_full_name?: string;
  user_name?: string;
  posts?: string[];
  followers?: string[];
  following?: string[];
}

const Comentario: React.FC<CommentProps> = ({ comment_author, comment_date, comment_likes, comment_replies, comment_text, user_email, _id, usuario }) => {
  return (
    <CommentStyle>
      <Prof_pic_mini src={usuario?.prof_pic} alt="Imagem_perfil_mini" />
      <Comment_organizer>
        <span className='comment_text'><span className='username'><strong>{usuario?.user_name} </strong></span>{comment_text}</span>
        <span>{diferenca_data(comment_date)}</span>
      </Comment_organizer>
      <QuantidadeLikes info_comment={{ comment_author, comment_date, comment_likes, comment_replies, comment_text, user_email, _id }} liked={true} likes={comment_likes?.length || 0} />
    </CommentStyle>
  );
}

export default Comentario;
