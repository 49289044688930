import { v2 as cloudinary } from 'cloudinary'
const header_auth = async () => {
  const dados_token = await localStorage.getItem('token_acesso_LCMFIT')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${dados_token}`,
  }
  return headers
}
const urlCdnSocial =
  'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/cdn-backend'
const urlLoginDefinitivo =
  'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_acessos_cuidadoIntegralLCM'
const urlEditarDefinitivo =
  'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/cdn_editarUsuario'

interface dadosSociaisAEnviar {
  nome_usuario?: string
  email_usuario?: string
  id_usuario?: string
  metodo: 'self' | 'others'
}

interface informacoesDeUpdate {
  profilePic: string
  userName: string
  fullNameUsuario: string
  bio: string
}

interface InfoPostagem {
  user_email: string
  post_image: string
  post_description: string
  post_author: string
}

interface DadosTarget {
  target_id: string
  conteudo?: string // Ajustado para usar 'Commentario'
  target: string
}

interface Result {
  status: number
  message: string
  info?: any | string[]
}

export const pedirDadosSaude = async (): Promise<Result> => {
  const dados_token = localStorage.getItem('token_acesso_LCMFIT')
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/dados_saude_LCMFIT/info_saude',
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${dados_token}`,
        },
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    console.error('Erro ao pedir dados de saúde:', error)
    return {
      status: 500,
      message: String(error),
    }
  }
}

interface ContatosEmergencia {
  tel?: string
  name?: string
}

interface InformarDadosSaudeParams {
  user_type_blood?: string
  user_problemas_saude?: string
  user_medicamentos?: string
  user_idiomas?: string
  user_alergies?: string
  user_contatos_emergencia?: ContatosEmergencia[]
}
export const ExcluirPost = async (id_post: string) => {
  try {
    const request = await fetch(urlEditarDefinitivo + '/excluir_post', {
      method: 'POST',
      mode: 'cors',
      headers: await header_auth(),
      body: JSON.stringify({
        id_post,
      }),
    })
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const obterSugestoesUsuarios = async () => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/outros_usuarios',
      {
        method: 'GET',
        mode: 'cors',
        headers: await header_auth(),
      },
    )
    const response = await request.json()
    //console.log('Usuários:', response.info)
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const informarDadosSaude = async (dados: InformarDadosSaudeParams) => {
  //console.log('Dados a serem enviados: ', dados)
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/dados_saude_LCMFIT/edit_info_saude',
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify({
          dados,
        }),
      },
    )
    const response = await request.json()
    //console.log('Resposta do servidor', response)
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const SugestionsFollow = async (limite: number, pagina: number) => {
  try {
    const request = await fetch(
      `https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/cdn_editarUsuario/sugestions_follow?page=${pagina}&limit=${limite}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: await header_auth(),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const ToggleFollow = async (user_name: string) => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/cdn_editarUsuario/toggle_seguir',
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify({ user_name }),
      },
    )
    const response = await request.json()
    //console.log('Verificando resultado da função: ', response)
    /*if (response.info == 'follow') {
    }*/
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const getDesafios = async () => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/desafios',
      {
        method: 'GET',
        mode: 'cors',
        headers: await header_auth(),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const getDesafiosPredefinidos = async () => {
  const request = await fetch(
    'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/desafios_pre_definidos',
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  const response = await request.json()
  return response
}

export const getRankingDesafios = async () => {
  const request = await fetch(
    'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/ranking_desafios',
    {
      method: 'GET',
      mode: 'cors',
      headers: await header_auth(),
    },
  )
  const response = await request.json()
  return response
}

export const responderDesafio = async (dados: {
  id_desafio?: string
  resposta_desafio: {
    mensagem_resposta: string
    foto_resposta: string
  }
}) => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/responder_desafio',
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify(dados),
      },
    )
    const response = await request.json()
    ////console.log('Respostas obtidas da api de resposta do desafio: ', response)
    return response
  } catch (error) {
    return {
      status: 500,
      message: `${error}`,
    }
  }
}

export const validarDesafio = async (id_desafio: string) => {
  try {
    //console.log('ID de desafio obtida', id_desafio)
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/validar_desafio' /*'http://localhost:4000/validar_desafio'*/,
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify({ id_desafio: id_desafio }),
      },
    )
    const response = await request.json()
    ////console.log('Respostas obtidas da api de criacao de desafio: ', response)
    return response
  } catch (error) {
    return {
      status: 500,
      message: `${error}`,
    }
  }
}

export const fazerDesafio = async (dados: {
  nome_desafio?: string
  desafiado?: string
  descricao?: string
  pontuacao?: number
}) => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/enviar_desafio',
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify(dados),
      },
    )
    const response = await request.json()
    ////console.log('Respostas obtidas da api de criacao de desafio: ', response)
    return response
  } catch (error) {
    return {
      status: 500,
      message: `${error}`,
    }
  }
}

export const recuperarSenha = async (email: string) => {
  try {
    //console.log('Email recebido pela função: ', email)
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/alteracao_auth_LCMFIT/esqueci_senha',
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const alterarSenha = async (token_alteracao: string, nova_senha: string) => {
  try {
    const alteracao = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/alteracao_auth_LCMFIT/alterar_senha',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token_alteracao}`,
        },
        body: JSON.stringify({ pass: nova_senha }),
      },
    )
    const resposta = await alteracao.json()
    return resposta
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const requisitarExames = async (): Promise<Result> => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/exames_LCMFIT/exames_usuario',
      {
        method: 'GET',
        headers: await header_auth(),
        mode: 'cors',
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const getDetalhesEvento = async (id_evento: string, tipo_evento: string) => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/detalhes_evento',
      {
        method: 'POST',
        headers: await header_auth(),
        body: JSON.stringify({
          id_evento,
          tipo_evento,
        }),
        mode: 'cors',
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const notificar = async (dados: {
  id_objeto: string
  tipo_objeto: string
  tipo_interacao: string
}) => {
  try {
    ////console.log('Verificando a integridade dos dados: ', JSON.stringify(dados))
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/cdn_notificacoes_LCMFIT/notificar',
      {
        method: 'POST',
        headers: await header_auth(),
        mode: 'cors',
        body: JSON.stringify(dados),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return { status: 500, message: String(error) }
  }
}

export const getNotificacoes = async (pagina: number) => {
  try {
    const request = await fetch(
      `https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/cdn_notificacoes_LCMFIT/notificacoes?pagina=${pagina}`,
      {
        method: 'GET',
        headers: await header_auth(),
        mode: 'cors',
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}
export const marcarEvento = async (dadosAgendamento: {
  tipo_evento: string
  info: {
    data_evento: string
    hora_evento: string
    descricao_evento: string
    imagem_evento: string
    titulo_evento: string
    local_evento: string
  }
}) => {
  try {
    const request = await fetch(
      `https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/criar_evento`,
      {
        method: 'POST',
        headers: await header_auth(),
        mode: 'cors',
        body: JSON.stringify(dadosAgendamento),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return { status: 500, error: String(error) }
  }
}
export const marcarPresenca = async (dadosEvento: { tipo_evento: string; id_evento: string }) => {
  try {
    //console.log('Verificando informações do evento: ', dadosEvento)
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/marcar_presenca',
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(dadosEvento),
        headers: await header_auth(),
      },
    )
    const response = await request.json()
    if (response.status == 200) {
      return response
    } else {
      return response
    }
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const getEventosCorporativos = async () => {
  //console.log('API FOI CHAMADA?')
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/eventos_corporativos',
      {
        method: 'GET',
        mode: 'cors',
        headers: await header_auth(),
      },
    )
    const response = await request.json()
    //console.log('Resposta direta da api', response)
    if (response.status == 204) {
      return {
        status: 204,
        events: [],
      }
    } else {
      return response
    }
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}
export const validarAcesso = async () => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/auth_api_LCMFIT/',
      {
        method: 'GET',
        headers: await header_auth(),
        mode: 'cors',
      },
    )
    const response = await request.json()
    //console.log(response)
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const getEventosComunitarios = async () => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/eventos_comunitarios',
      {
        method: 'GET',
        mode: 'cors',
        headers: await header_auth(),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const criarEventoCorporativo = async (dados: {
  data_agendamento: string
  horario: string
  descricao: string
  imagem: string
}) => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/eventos_corporativos/criar_evento_corporativo',
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify(dados),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const criarEventoComunitario = async (dados: {
  data_agendamento: string
  horario: string
  descricao: string
  imagem: string
}) => {
  try {
    const request = await fetch(
      'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/eventos_LCMFIT/eventos_corporativos/criar_evento_comunitario',
      {
        method: 'POST',
        mode: 'cors',
        headers: await header_auth(),
        body: JSON.stringify(dados),
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}

export const tokenHandler = () => {
  try {
    const dados_token = localStorage.getItem('token_acesso_LCMFIT')
    if (dados_token) {
      const token = JSON.parse(dados_token)
      return { token }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const manterLogado = () => {
  try {
    const dados_token = localStorage.getItem('token_acesso_LCMFIT')
    if (!dados_token) {
      return false
    } else {
      return true
    }
  } catch (error) {
    return false
  }
}

export async function EditarOuCriarSocial(informacoes: informacoesDeUpdate) {
  try {
    const request = await fetch(`${urlEditarDefinitivo}/editar_social`, {
      method: 'POST',
      mode: 'cors',
      headers: await header_auth(),
      body: JSON.stringify({
        foto_perfil: informacoes.profilePic,
        nome_usuario: informacoes.userName,
        nome_completo_usuario: informacoes.fullNameUsuario,
        bio: informacoes.bio,
      }),
    })
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: 'Erro na solicitação',
    }
  }
}
export async function inserirComentario(dados: DadosTarget) {
  const request = await fetch(urlEditarDefinitivo + '/criar_comentario', {
    method: 'POST',
    mode: 'cors',
    headers: await header_auth(),
    body: JSON.stringify({
      dados: dados,
    }),
  })
  const response = await request.json()
  return response
}

export async function toggleLike(dados: DadosTarget) {
  try {
    const request = await fetch(`${urlEditarDefinitivo}/inserir_like`, {
      method: 'POST',
      headers: await header_auth(),
      mode: 'cors',
      body: JSON.stringify({ dados }),
    })
    const response = await request.json()
    if (response) {
      return response
    }
  } catch (error) {
    return {
      status: 500,
      message: 'Falha na requisição',
    }
  }
}

export async function obterPostPorComment(id_comment: string) {
  try {
    const request = await fetch(
      `${urlEditarDefinitivo}/obter_post_comment?id_comment=${id_comment}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: 'Falha ao obter post',
    }
  }
}

export async function obterPostInfo(id_post: string): Promise<Result> {
  try {
    const request = await fetch(`${urlEditarDefinitivo}/obter_info_post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id_post: id_post }),
    })
    //console.log('Informações da request: ', request, id_post)
    const response = await request.json()
    //console.log('Informações de resposta: ', response)
    if (request.ok) {
      return { status: 200, message: 'Sucesso', info: response }
    } else {
      return {
        status: 500,
        message: 'Falha no lado do servidor',
      }
    }
  } catch (error) {
    return {
      status: 400,
      message: 'Falha na requisição',
    }
  }
}

export const verComentario = async (id_post: string) => {
  try {
    const request = await fetch(urlEditarDefinitivo + '/obter_info_comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id_post: id_post,
      }),
    })
    const response = await request.json()
    if (response.status == 200) {
      return response
    } else {
      return {
        status: 500,
        message: 'Falha no lado do servidor',
      }
    }
  } catch (error) {
    return {
      status: 400,
      message: 'Falha na requisição',
    }
  }
}

export const obterPostsFeed = async (pedido: {
  metodo: string
  pagina: string
  limite: string
}) => {
  const request = await fetch(urlEditarDefinitivo + '/obter_posts', {
    method: 'POST',
    headers: await header_auth(),
    body: JSON.stringify({ pedido }),
  })
  const response = await request.json()
  return response
}

export const obterReceitasFeed = async (pedido: {
  metodo: string
  pagina: string
  limite: string
}) => {
  const request = await fetch(urlEditarDefinitivo + '/obter_receitas', {
    method: 'POST',
    headers: await header_auth(),
    body: JSON.stringify({ pedido }),
  })
  const response = await request.json()
  return response
}

export const obterDadosSociais = async (dados: dadosSociaisAEnviar) => {
  if (dados.metodo == 'self') {
    const dados_token = await localStorage.getItem('token_acesso_LCMFIT')
    if (dados_token) {
      const request = await fetch(urlCdnSocial + '/social_info', {
        method: 'POST',
        headers: await header_auth(),
      })
      const response = await request.json()
      return response
    }
  } else if (dados.nome_usuario || dados.email_usuario || dados.id_usuario) {
    const request = await fetch(urlCdnSocial + '/social_info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dados),
    })
    const response = await request.json()
    return response
  }
}

export async function criarPost(info_post: InfoPostagem) {
  //console.log('Dados a serem enviados: ', info_post)
  try {
    const request = await fetch(urlEditarDefinitivo + '/criar_post', {
      method: 'POST',
      headers: await header_auth(),
      mode: 'cors',
      body: JSON.stringify({ postInfo: info_post }),
    })
    //console.log('Formato da requisição:', request)
    const response = await request.json()
    //console.log('Resposta da api:', response)
    return response
  } catch (error) {
    return {
      status: 500,
      message: 'Falha na requisição, tente novamente',
    }
  }
}

export async function criarReceita(info_post: InfoPostagem) {
  try {
    const request = await fetch(urlEditarDefinitivo + '/criar_receita', {
      method: 'POST',
      headers: await header_auth(),
      mode: 'cors',
      body: JSON.stringify({ postInfo: info_post }),
    })
    const response = await request.json()
    return response
  } catch (error) {
    return {
      status: 500,
      message: 'Falha na requisição, tente novamente',
    }
  }
}
interface pacote {
  email: string
  pass: string
  method: string
}

export const accessHandler = async (pacote: pacote) => {
  const auth = JSON.stringify(pacote)
  try {
    const request = await fetch(urlLoginDefinitivo, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth}`,
      },
      mode: 'cors',
    })
    const response = await request.json()
    if (response.status == 200) {
      try {
        localStorage.setItem('token_acesso_LCMFIT', JSON.stringify(response.token))
        return {
          status: response.status,
          message: response.message,
        }
      } catch (error) {
        //console.log(error)
        return {
          status: 500,
          message: response.message,
        }
      }
    } else {
      return response
    }
  } catch (error) {
    return {
      status: 500,
      message: String(error),
    }
  }
}
