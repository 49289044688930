import styled from 'styled-components'

export const Like_meter = styled.div`
  width: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
  color: ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.gray900};
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 2rem;
  transition: all 0.3s;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.primary} !important;
    transform: scale(1.05) !important;
  }
  &.liked {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  &.unliked {
    color: ${(props) => props.theme.colors.gray200} !important;
  }
`
