import { useState, useEffect, useCallback } from "react"
import { Header_feed, Buttons_feed, Posts_grid, SolicitarMaisPosts } from "./feed.style.component"
import Post from "../../components/Post/post.component"
import Navbar from "../../components/Navbar/navbar.component"
import { obterPostsFeed } from "../../components/logics/def_functions"
import ReturnButton from "../../components/Botao retornar/return.component"

interface Information {
  status: number,
  message: string,
  info: {
    paginaAtual: string,
    paginas: string,
    posts: PostProps[]
  }
}

interface PostProps {
  _id: string;
  post_author: string;
  post_date: string;
  user_email: string;
  post_description: string;
  post_image: string;
  post_likes: string[];
  post_comments: string[];
}

const Feed_noticias: React.FC = () => {
  const [feed, defineFeed] = useState('geral')
  const [postsElements, definePosts] = useState<PostProps[]>([])
  const [pagina, setPagina] = useState('1')
  const [isLoading] = useState(false) // Adicionando um estado para evitar duplicações de requisições

  // Função de busca de posts, usando useCallback para garantir que a função não seja recriada a cada render
  const fetchPosts = useCallback(async () => {
    try {
      const posts: Information = await obterPostsFeed({ metodo: feed, pagina: pagina, limite: '20' })

      const novosPosts = posts.info.posts.filter(
        post => !postsElements.some(existingPost => existingPost._id === post._id)
      );

      // Aqui chamamos a função para filtrar duplicados após adicionar novos posts
      definePosts(prevPosts => filtrarPostsDuplicados([...prevPosts, ...novosPosts]));

      console.count("Define Posts executado")
    } catch (error) {
      console.error("Erro ao buscar posts: ", error);
    }
  }, [feed, pagina]);

  const changePage = () => {
    if (isLoading) return; // Evita mudanças de página durante o carregamento
    const proximaPagina = Number(pagina) + 1;
    setPagina(String(proximaPagina));
  }

  const filtrarPostsDuplicados = (posts: PostProps[]): PostProps[] => {
    const postIds = new Set<string>(); // Conjunto para rastrear os ids já encontrados
    return posts.filter(post => {
      if (postIds.has(post._id)) {
        return false; // Remove se o id já existir no conjunto
      } else {
        postIds.add(post._id);
        return true; // Mantém se o id for único
      }
    });
  };

  useEffect(() => {
    definePosts([]);
    setPagina('1');
    console.count("UseEffect de limpeza rolou")
  }, [feed]);

  useEffect(() => {
    fetchPosts();
    console.count("UseEffect de requisição rolou")
  }, [pagina]);

  return (
    <>
      <ReturnButton></ReturnButton>
      <Header_feed>
        <Buttons_feed onClick={() => { defineFeed('geral') }}>Ver famosos em LCM</Buttons_feed>
        <Buttons_feed onClick={() => { defineFeed('amigos') }}>Ver posts de pessoas que eu sigo</Buttons_feed>
      </Header_feed>
      <Posts_grid>
        {postsElements.map((element) => (
          <Post key={element._id} {...element}></Post>
        ))}
      </Posts_grid>
      <SolicitarMaisPosts onClick={changePage}><span>Ver mais posts</span></SolicitarMaisPosts>
      <Navbar active="social"></Navbar>
    </>
  )
}
export default Feed_noticias
