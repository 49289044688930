import Calendar_component from "../../components/calendar/calendar.component";
import Sidebar_saude from "../../components/Sidebar/sidebar.saude.component";
import Navbar from "../../components/Navbar/navbar.component";
import ReturnButton from "../../components/Botao retornar/return.component";
import { Head_container, Title } from "./style";

const Pagina_consultas = () => {

  return (<>
    <ReturnButton></ReturnButton>
    <Head_container><Title>Agendamentos e Consultas com a Dra. Walneia - Clinica médica</Title></Head_container>
    <Sidebar_saude></Sidebar_saude>
    <Calendar_component></Calendar_component>
    <Navbar active="saude"></Navbar>
  </>)
}

export default Pagina_consultas
