import { Sidebar_base, Sidebar_icon, List, LItem, LoginArea, Profile_pic, Profile, UserName, Button, Botao_ajuda } from "./sidebar.style.component";
import { MdOutlineLiveHelp } from "react-icons/md";
import { useState, useEffect } from "react";
import { manterLogado, obterDadosSociais } from "../logics/def_functions";
import { ImExit } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import notificacoes from '../Assets/Images/notificacoes.png'
import feed from '../Assets/Images/postagens.png'
import publicar from '../Assets/Images/publicar.png'
import perfil from '../Assets/Images/perfil.png'
import sugerir from '../Assets/Images/sugerir.png'
import Tutorial_screen from "../Tutorial/tutorial.component";

interface social_info {
  _id?: string;
  bio?: string;
  prof_pic?: string;
  user_email?: string;
  user_full_name?: string;
  user_name?: string;
  posts?: string[];
  followers?: string[];
  following?: string[];
}

const Sidebar_social = () => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const [tutorial, showTutorial] = useState(false)
  const [perfilUsuario, setPerfil] = useState<social_info | null>(null)
  const [emailDeUsuario, setEmailDeUsuario] = useState('')
  const [imagemPerfil, defImagemPerfil] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
  const inserirUsuario = async () => {
    const usuarioLogado = await obterDadosSociais({ metodo: 'self' })
    if (usuarioLogado.status == 200) {
      setPerfil(usuarioLogado.info)
      setEmailDeUsuario(usuarioLogado?.info?.user_full_name)
      defImagemPerfil(usuarioLogado?.info?.prof_pic)
    } else {
      navigate('/social/edit_social')
    }
  }
  const logoff = () => {
    ////console.log('Realizando logoff')
    localStorage.removeItem('token_acesso_LCMFIT');
    navigate('/login')
  };

  useEffect(() => {
    const logado = manterLogado()
    if (!logado) {
      navigate('/login')
    }
    inserirUsuario()
  }, [])

  const toFeed = () => {
    navigate("/social/feed")
  }
  const toPerfil = () => {
    navigate(`/social/perfil?${perfilUsuario?.user_name}`)
  }
  const toPublicar = () => {
    navigate("/social/camera")
  }
  const toNotificacoes = () => {
    navigate("/social/notificacoes")
  }
  const toSugestoes = () => {
    navigate("/social/sugestoes")
  }

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  }

  const conteudos = [{
    setor: 'Notificações',
    imagem: notificacoes,
    descricao: 'Aqui é possível ver as interações de outros usuários com seu conteúdo.'
  }, {
    setor: 'Feed de atividades',
    imagem: feed, descricao: "Veja as publicações mais recentes das pessoas que você segue e de todos os usuários da LCM"
  }, {
    setor: 'Publicar', imagem: publicar, descricao: "Publicar, você pode tirar fotos usando o app ou publicar alguma foto já tirada."
  }, {
    setor: "Perfil", imagem: perfil, descricao: 'Em Perfil, você pode ver e editar suas informações sociais, assim como ver suas publicações'
  }, {
    setor: "Pessoas que você talvez conheça", imagem: sugerir, descricao: 'Nessa seção é possível se conectar com outros usuários e procurar por seus perfis'
  }]

  return (
    <>
      <Sidebar_base isExpanded={isExpanded}>
        {isExpanded && (<><List>
          <LItem onClick={toFeed}>Feed de atividades</LItem>
          <LItem onClick={toPerfil}>Perfil</LItem>
          <LItem onClick={toPublicar}>Publicar</LItem>
          <LItem onClick={toNotificacoes}>Notificacoes</LItem>
          <LItem onClick={toSugestoes}>Sugestões de amizade</LItem>
        </List>
          {tutorial && (<Tutorial_screen estado='show' conteudos={conteudos} onClose={() => showTutorial(false)}></Tutorial_screen>)}
          <Botao_ajuda onClick={() => showTutorial(true)}><MdOutlineLiveHelp size={50} color="white"></MdOutlineLiveHelp><span>Ajuda</span></Botao_ajuda><LoginArea>
            <Profile>
              <Profile_pic src={imagemPerfil}></Profile_pic>
              <UserName>{emailDeUsuario}</UserName>
            </Profile>
            <Button><ImExit size={30} onClick={() => logoff()} /></Button>
          </LoginArea>
        </>)}
        <Sidebar_icon onClick={toggleSidebar}></Sidebar_icon>
      </Sidebar_base>
    </>
  )
}
export default Sidebar_social
