import { Login_body, Form, Button, Subdiv, Input } from "./login.style.component";
import { useNavigate } from 'react-router-dom';
import { accessHandler } from "../logics/def_functions";
import React, { useState, useRef } from "react";
import Tela_confirmacao from "../Tela de confirmacao/confirmacao.component";

const Register_component = () => {
  const [estadoGeral, defEstadoGeral] = useState('standby')
  const [mensagemGeral, defMensagemGeral] = useState('')
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const toLogin = () => {
    navigate('/login');
  }
  const toSaude = () => {
    navigate("/saude")
  }
  const toSSO = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const ssoUrl = 'https://intranet.lcmconstrucao.com.br/sso-login-cuidado-integral';
    window.location.href = ssoUrl;
  };

  const gatherInfo = () => {
    if (emailRef.current && passRef.current) {
      if (emailRef.current.value !== '' && passRef.current.value !== '') {
        const email = emailRef.current.value
        const pass = passRef.current.value
        const regexSenha = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const regexEmail = /^[a-zA-Z0-9._%+-]+@lcmconstrucao\.com\.br$/;
        if (!regexSenha.test(pass)) {
          defEstadoGeral('failed')
          defMensagemGeral('Senha inválida! É necessário que a senha contenha uma letra maiúscula, uma letra minúscula, um número, um caractere especial e um número')
        }
        if (!regexEmail.test(email)) {
          defEstadoGeral('failed')
          defMensagemGeral('Email inválido! Somente emails da empresa podem ser cadastrados')
        }
        if (regexSenha.test(pass) && regexEmail.test(email)) {
          return {
            method: 'create', email, pass
          }
        }
      } else {
        defEstadoGeral('failed')
        defMensagemGeral('Dados inválidos, tente novamente')
      }
    } else {
      defEstadoGeral('failed')
      defMensagemGeral('Dados inválidos, tente novamente')
    }
  };
  const tentarCadastro = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    try {
      const payload = gatherInfo()
      if (payload) {
        const tentativaDeCadastro = await accessHandler(payload)
        //console.log(tentativaDeCadastro.status)
        if (tentativaDeCadastro.status === 200) {
          defEstadoGeral('success')
          defMensagemGeral('Sucesso na criação de conta! Redirecionando')
          toSaude()
        } else {
          defEstadoGeral('failed')
          defMensagemGeral(tentativaDeCadastro.message)
        }
      }
    }
    catch (error) {
      defEstadoGeral('failed')
      defMensagemGeral('Falha na criação da conta!')
    }
  }
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  return (
    <>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Login_body>
        <Form onSubmit={handleFormSubmit}>
          <h2>Crie sua conta</h2>
          <label htmlFor="email">Insira seu email</label>
          <Input
            ref={emailRef}
            id="email"
            type="email"
            name="email"
            placeholder='exemplo@lcmconstrucao.com.br'
            autoComplete="email"
          />
          <label htmlFor="password">Registre sua senha de acesso</label>
          <Input
            ref={passRef}
            id="password"
            type="password"
            name="password"
            placeholder='********'
            autoComplete="current-password"
          />
          <Button className="cadastrar" onClick={tentarCadastro}>Cadastrar</Button>
          <Subdiv>
            <span>Já possui uma conta? </span>
            <Button className="logar" onClick={() => toLogin()}>Faça o login</Button>
            <span>Ou</span>
            <Button className="intranet" onClick={toSSO}>Faça login pela Intranet</Button>
          </Subdiv>
        </Form>
      </Login_body>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    </>
  )
}
export default Register_component;
