import styled from 'styled-components'
export const Container = styled.div`
  overflow: hidden;
  width: calc(100svw - 2rem);
  height: calc(fit-content + 3.5rem);
  position: relative;
  top: 50%;
  left: 0.5rem;
  padding: 1rem 0;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: ${(props) => props.theme.breakpoints.xs} !important;
    height: fit-content;
  }
`
export const PlaceHolder = styled.div`
  width: 100%;
  height: 4rem;
`

export const TitleDiv = styled.div`
  width: calc(100% - 3rem);
  padding: 0.5rem;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h2 {
    color: ${(props) => props.theme.colors.secondary};
  }
`
