import { Login_body, Form, Button, Subdiv, Input, CancelRecovery } from "./login.style.component";
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from "react";
import { accessHandler, recuperarSenha } from "../logics/def_functions";
import Tela_confirmacao from "../Tela de confirmacao/confirmacao.component";
import { IoMdArrowRoundBack } from "react-icons/io";

const Login_component = () => {
  const [estadoGeral, defEstadoGeral] = useState('standby')
  const [mensagemGeral, defMensagemGeral] = useState('')
  const [recovery, setRecovery] = useState(false)
  const emailRef = useRef<HTMLInputElement>(null)
  const emailRecRef = useRef<HTMLInputElement>(null)
  const passRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate();

  const toHome = () => {
    navigate('/saude');
  };

  const toRegister = () => {
    navigate('/register');
  };

  const toSSO = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const ssoUrl = 'https://intranet.lcmconstrucao.com.br/sso-cuidadointegral-testes/';
    window.location.href = ssoUrl;
  };

  const gatherInfo = () => {
    if (emailRef.current && passRef.current) {
      const emailUsuario = emailRef.current.value;
      const passUsuario = passRef.current.value;
      return {
        method: 'login',
        email: emailUsuario,
        pass: passUsuario
      };
    }
  };

  const tentarLogin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = gatherInfo();
    if (payload) {
      const resultado = await accessHandler(payload);
      if (resultado.status === 200) {
        defEstadoGeral('success');
        defMensagemGeral('Sucesso no login, redirecionando');
        toHome();
      } else if (resultado.status == 403) {
        defEstadoGeral('failed');
        defMensagemGeral('Login não autorizado, tente novamente')
      } else {
        defEstadoGeral('failed')
        defMensagemGeral(resultado.message)
      }
    }
  };
  const tentarRecuperacao = async () => {
    if (emailRecRef.current && emailRecRef.current.value) {
      //console.log('Caiu no if')
      const recuperacao = await recuperarSenha(emailRecRef.current.value)
      //console.log(recuperacao)
      if (recuperacao.status == 200) {
        defEstadoGeral('success')
        defMensagemGeral('Sucesso. O e-mail de recuperação foi enviado.')
      } else {
        defEstadoGeral('failed')
        defMensagemGeral(`Falha! ${recuperacao.status} - ${recuperacao.message}`)
      }
    } else {
      console.log('Caiu no else')
      defEstadoGeral('failed')
      defMensagemGeral('Email inválido')
    }
  }
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Login_body>
        <Form onSubmit={handleFormSubmit}>
          {!recovery && <>
            <h2>Entre em sua conta</h2>
            <label htmlFor="email">Insira seu email</label>
            <Input
              ref={emailRef}
              id="email"
              type="email"
              name="email"
              placeholder='exemplo@lcmconstrucao.com.br'
              autoComplete="email"
            />
            <label htmlFor="password">Insira sua senha de acesso</label>
            <Input
              ref={passRef}
              id="password"
              type="password"
              name="password"
              placeholder='********'
              autoComplete="current-password"
            />
            <Button className="entrar" onClick={tentarLogin}>Entrar</Button>
            <Subdiv>
              <span>Ainda não possui uma conta? </span>
              <Button className="cadastrar" onClick={toRegister}>Cadastrar-se</Button>
              <span>Ou</span>
              <Button className="intranet" onClick={toSSO}>Faça login pela Intranet</Button>
              <Button className="recover" onClick={() => setRecovery(true)}>Esqueci minha senha</Button>
            </Subdiv></>
          }
          {recovery && <>
            <h2>Recupere sua conta</h2>
            <CancelRecovery onClick={() => setRecovery(false)}><IoMdArrowRoundBack color="white" size={40}></IoMdArrowRoundBack></CancelRecovery>
            <label htmlFor="email">Insira seu email</label>
            <Input
              ref={emailRecRef}
              id="email"
              type="email"
              name="email"
              placeholder='exemplo@lcmconstrucao.com.br'
              autoComplete="email"
            />
            <Button className="recover" onClick={tentarRecuperacao}>Enviar email de recuperação</Button>
          </>}
        </Form>
      </Login_body>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    </>
  );
};

export default Login_component;
