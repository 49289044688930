import styled, { css } from 'styled-components'
import { MdOutlineCamera } from 'react-icons/md'
import { CiFolderOn, CiTrash } from 'react-icons/ci'
import { AiOutlineSend, AiOutlineSync, AiOutlineArrowLeft } from 'react-icons/ai'
interface ResultProps {
  hasPhoto: boolean
}
interface FolderProps {
  onClick: () => void
}
export const SavePost = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  width: 40%;
  padding: 1rem;
  border-radius: 0.5rem;
  position: fixed;
  bottom: 6rem;
`

export const PostPreview = styled.img`
  width: 80%;
  &.selfie {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`
export const CreatePostContainer = styled.section`
  z-index: 11;
  padding-bottom: 5rem;
  background-color: ${(props) => props.theme.colors.gray100};
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
export const Titulos = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  width: 50%;
  text-align: center;
`
export const Descricao_post = styled.input`
  width: 80%;
  min-height: 5rem;
  background-color: ${(props) => props.theme.colors.gray400};
  padding: 0.5rem;
  border-radius: 0.3rem;
  position: fixed;
  bottom: 10rem;
`

export const Galery = styled.input`
  display: none;
`
export const Folder = styled(CiFolderOn)<FolderProps>`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSize['2xl']};
  &:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    font-size: ${(props) => props.theme.fontSize['2xl']};
    padding: 0.2rem;
  }
`
export const Back = styled(AiOutlineArrowLeft)`
  position: absolute;
  top: 0.5rem;
  left: 10%;
  z-index: 20;
  padding: 1rem;
  transform: scale(1.2);
  background-color: ${(props) => props.theme.colors.gray200};
  color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
`
export const Post = styled(AiOutlineSend)`
  padding: 1rem;
  transform: scale(1.2);
  background-color: ${(props) => props.theme.colors.gray200};
  color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
`

export const Cont_buttons = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 2rem;
  position: fixed;
  bottom: 6rem !important;
  right: 0rem;
  z-index: 5;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    bottom: 10%;
  }
  > * {
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.gray200};
  }
`
export const Shutter = styled(MdOutlineCamera)`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSize['2xl']};
  &:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    font-size: ${(props) => props.theme.fontSize['2xl']};
    padding: 0.2rem;
  }
`

export const Close = styled(CiTrash)`
  color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.gray200};
  border-radius: 50%;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSize['4xl']};
  position: absolute;
  top: 2rem;
  z-index: 5;
  right: 0rem;
  transform: translate(-50%, -50%) scale(1.5);
  &:hover {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
    -moz-box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    font-size: ${(props) => props.theme.fontSize['2xl']};
    padding: 0.2rem;
  }
`

export const Switch = styled(AiOutlineSync)`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSize['2xl']};
  &:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    font-size: ${(props) => props.theme.fontSize['2xl']};
    padding: 0.2rem;
  }
`
export const Cam = styled.div`
  margin-top: 1rem;
  width: 100vw;
  max-width: ${(props) => props.theme.breakpoints.md};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    width: ${(props) => props.theme.breakpoints.xxs};
    height: 100svh;
    align-self: center;
  }
`
export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  &.selfie {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`
export const ButtonSend = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  border: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 6rem;
  z-index: 10;
  right: 0rem;
  padding: 0.5rem;

  &:hover {
    -webkit-box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
    -moz-box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
  }
`
export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.gray200};
  border: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    -webkit-box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
    -moz-box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 29px 0px ${(props) => props.theme.colors.primary};
  }
`
export const Result = styled.div<ResultProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  &.selfie {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  ${(props) =>
    props.hasPhoto == true &&
    css`
      z-index: 4;
    `}
`
export const Canvas = styled.canvas`
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  &.selfie {
    -webkit-transform: scaleX(-1);
    transform: translate(-50%, -50%) scaleX(-1);
  }
`
