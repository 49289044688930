import styled from 'styled-components'
export const Corpo_batePapo = styled.div`
  margin-left: 3rem;
  margin-top: 1.5rem;
  width: 65vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  overflow-x: hidden;
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
  background-color: ${(props) => props.theme.colors.gray300};
`
export const Chats_espaco = styled.div`
  width: 96%;
  height: fit-content;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray700};
`
export const Busca_espaco = styled.div`
  width: 95%;
  height: fit-content;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray500};
  > * {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 0.3rem;
    border: none;
    &.search_form {
      width: 80%;
      > * {
        width: 100%;
        padding: 0.5rem 0rem;
        border-radius: 0.3rem;
        border: none;
      }
    }
    &:hover {
      transform: scale(1.05);
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const Chat = styled.div`
  width: 94%;
  height: 4rem;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`
export const ProfilePic = styled.img`
  width: 2rem;
  background-color: aliceblue;
  aspect-ratio: 1/1;
  border: ${(props) => props.theme.colors.gray900};
  border-radius: 50%;
`
export const MessagePreview = styled.div`
  color: ${(props) => props.theme.colors.black};
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.sm};
`
export const Form_chat = styled.form`
  width: 70%;
  height: 3rem;
  padding: 1rem;
  background-color: lightgreen;
  border-radius: 0.5rem;
  z-index: 10;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 8rem;
  & > .input_message {
    width: 70%;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.primary};
    border-radius: 0.5rem;
  }
  & > .send_message {
    background-color: ${(props) => props.theme.colors.primary};
    padding: 1rem;
    border: none;
    border-radius: 50%;
  }
`
export const Mensagem_chat = styled.div`
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.sm};
  &.sent {
    background-color: ${(props) => props.theme.colors.light_green};
    align-self: flex-end !important;
  }
  &.received {
    background-color: aliceblue;
    color: ${(props) => props.theme.colors.black};
    align-self: flex-start !important;
  }
`
export const Head_conversa = styled.div`
  margin-top: -1rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
`

export const NovoChat = styled.button`
  padding: 1rem;
  position: fixed;
  border-radius: 0.5rem;
  border: none;
  bottom: 8rem;
  right: 1rem;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.primary};
  transition: all 0.5s;
  &:hover {
    transform: scale(1.2);
  }
`
