import styled from 'styled-components'
export const Erro_box = styled.div`
  width: 75svw;
  background-color: #93ca3c;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #ffffff;
  border-radius: 1rem;
  > button {
    background-color: #27403c;
    color: #ffffff;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
`
