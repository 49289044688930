import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './routes'
import { Theme } from './styles/Theme'
import { GlobalStyle } from './styles/global'
import { Normalize } from 'styled-normalize'
import ErrorBoundary from './components/Error_boundary'

export default function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Theme>
          <AppRoutes />
          <GlobalStyle />
          <Normalize />
        </Theme>
      </BrowserRouter>
    </ErrorBoundary>
  )
}
