import styled from 'styled-components'
export const Corpo_confirmacao = styled.div`
  width: 65vw;
  height: 65vh;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  position: fixed;
  top: 50% !important;
  left: 55% !important;
  transform: translate(-50%, -50%) !important;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 25;
  > span {
    color: ${(props) => props.theme.colors.white} !important;
  }
`

export const Loader = styled.div`
  &.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.white};
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
`

export const Button_exit = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.white};
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid ${(props) => props.theme.colors.white};
  aspect-ratio: 1/1;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    border: 0.1rem solid ${(props) => props.theme.colors.orange};
    color: ${(props) => props.theme.colors.orange};
  }
`
