import { Container, PlaceHolder, TitleDiv } from './container_padrao.component.style'
import Mini_section from '../Mini_section/mini_section.component'
import { FaRankingStar } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlinePlayCircle, MdGroups } from "react-icons/md";
import { SlCalculator } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Images/newlogo.svg';
import escrita from '../Assets/Images/escrita.webp'

const Container_exercicios = () => {
  const navigate = useNavigate()
  const toCorpEvents = () => {
    navigate('/exercicios/eventos_corporativos')
  }
  const toEventosSociais = () => {
    navigate('/exercicios/eventos_sociais')
  }
  const toPlaylistTreinos = () => {
    navigate('/exercicios/playlists')
  }
  const toCalculadora = () => {
    navigate('/exercicios/calculadora_imc')
  }
  const toDesafios = () => {
    navigate("/exercicios/desafios")
  }
  return <>
    <Container>
      <TitleDiv><img
        style={{ width: '6rem', height: 'auto' }}
        src={escrita}
        alt="Logo LCM"
      /><img
          style={{ width: '8rem', height: 'auto' }}
          src={logo}
          alt="Logo LCM"
        /></TitleDiv>
      <Mini_section className='exercicios' title='Calculadora de IMC' description='Verifique seu índice de massa corporal' icon={SlCalculator} onClick={toCalculadora}></Mini_section>
      <Mini_section className='exercicios' title='Competitividade' onClick={toDesafios} description='Desafie seus amigos para ver quem é o mais fitness!' icon={FaRankingStar}></Mini_section>
      <Mini_section className='exercicios' title='Eventos' description='Veja e participe dos eventos da comunidade!' icon={FaRegCalendarAlt} onClick={toCorpEvents}></Mini_section>
      <Mini_section className='exercicios' title='Malhe junto!' description='Chame seus amigos para uma sessão de exercícios' icon={MdGroups} onClick={toEventosSociais}></Mini_section>
      <Mini_section className='exercicios' title='Playlists' description='Inicie uma sessão de treino com uma playlist de acordo com seus gostos!' icon={MdOutlinePlayCircle} onClick={toPlaylistTreinos}></Mini_section>
      <PlaceHolder></PlaceHolder>
    </Container>
  </>
}

export default Container_exercicios
