import React from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import Pagina_saude from './pages/Saude';
import Pagina_exercicios from './pages/Malhacao';
import Pagina_social from './pages/Social';
import Pagina_meditacao from './pages/Meditacao';
import Pagina_login from './pages/Login';
import Pagina_register from './pages/Register';
import Home_page from './pages/Home';
import Pagina_consultas from './pages/Consultas';
import Editar_perfil_social from './pages/Editar_perfil_social/edit_social.page';
import Camera_pagina from './pages/Camera';
import Feed_noticias from './pages/Feed/feed.component';
import Feed_receitas from './pages/Feed receitas/feed_receitas.component';
import Pagina_playlists from './pages/Playlists';
import Pagina_nutricao from './pages/Nutricao';
import Pagina_dados_emergencia from './pages/Dados de emergência';
import Pagina_eventos_comunidade from './pages/Eventos da comunidade';
import Pagina_eventos_corporativos from './pages/Eventos corporativos';
import Pagina_exames from './pages/Exames';
import Pagina_calculadora from './pages/Calculadora IMC';
import Pagina_notificacoes from './pages/Notificacoes';
import Pagina_chat from './pages/Bate Papo';
import Pagina_conversa from './pages/Conversa';
import PaginaCriarEventoCorp from './pages/Criar evento corporativo';
import PaginaCriarEventoSocial from './pages/Criar evento social';
import PaginaDetalhesDoEventoCorporativo from './pages/Detalhes do evento corporativo';
import PaginaDetalhesDoEventoSocial from './pages/Detalhes do evento social';
import Pagina_desafios from './pages/Desafios';
import Perfil_social_generico from './pages/Perfil generico';
import Pagina_publicar_nutri from './pages/Publicar nutricao';
import Pagina_Agendar_nutri from './pages/Agendar Nutricionista';
import Post_individual from './pages/Post Individual';
import Pagina_sugestoes from './pages/Sugestoes de Amizade';
import SSOPage from './pages/SSOConnection';
import Pagina_recuperacao_senha from './pages/Esqueci a senha';
const categorias = ['/saude', '/exercicios', '/social', '/meditacao', '/nutricao'];

export function AppRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const paginaAtual = location.pathname.split('/')[1]; // Obtém o segmento principal do caminho
  const indexPaginaAtual = categorias.indexOf(`/${paginaAtual}`);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (indexPaginaAtual < categorias.length - 1) {
        navigate(categorias[indexPaginaAtual + 1]);
      }
    },
    onSwipedRight: () => {
      if (indexPaginaAtual > 0) {
        navigate(categorias[indexPaginaAtual - 1]);
      }
    },
    trackMouse: true
  });
  /*Comentando pra atualizar*/
  return (
    <div {...handlers}>
      <Routes>
        <Route path='/' element={<Home_page />} />
        <Route path='/social' element={<Pagina_social />} />
        <Route path='/social/bate_papo' element={<Pagina_chat />} />
        <Route path='/social/bate_papo/chat' element={<Pagina_conversa />} />
        <Route path='/social/edit_social' element={<Editar_perfil_social />} />
        <Route path='/social/camera' element={<Camera_pagina />} />
        <Route path='/social/feed' element={<Feed_noticias />} />
        <Route path='/social/perfil' element={<Perfil_social_generico />} />
        <Route path='/social/notificacoes' element={<Pagina_notificacoes />} />
        <Route path='/social/notificacoes/post' element={<Post_individual />} />
        <Route path='/social/notificacoes/comment/post' element={<Post_individual />} />
        <Route path='/social/sugestoes' element={<Pagina_sugestoes />} />
        <Route path='/login' element={<Pagina_login />} />
        <Route path='/saude' element={<Pagina_saude />} />
        <Route path='/saude/consultas' element={<Pagina_consultas />} />
        <Route path='/saude/dados' element={<Pagina_dados_emergencia />} />
        <Route path='/saude/exames' element={<Pagina_exames />} />
        <Route path='/register' element={<Pagina_register />} />
        <Route path='/meditacao' element={<Pagina_meditacao />} />
        <Route path='/meditacao/playlist_concentrar' element={<Pagina_playlists />} />
        <Route path='/meditacao/playlist_meditar' element={<Pagina_playlists />} />
        <Route path='/exercicios/calculadora_imc' element={<Pagina_calculadora />} />
        <Route path='/exercicios' element={<Pagina_exercicios />} />
        <Route path='/exercicios/playlists' element={<Pagina_playlists />} />
        <Route path='/exercicios/desafios' element={<Pagina_desafios />} />
        <Route path='/exercicios/eventos_corporativos' element={<Pagina_eventos_corporativos />} />
        <Route path='/exercicios/eventos_corporativos/evento' element={<PaginaDetalhesDoEventoCorporativo />} />
        <Route path='/exercicios/eventos_sociais/evento' element={<PaginaDetalhesDoEventoSocial />} />
        <Route path='/exercicios/eventos_sociais/criar_evento' element={<PaginaCriarEventoSocial />} />
        <Route path='/exercicios/eventos_corporativos/criar_evento' element={<PaginaCriarEventoCorp />} />
        <Route path='/exercicios/eventos_sociais' element={<Pagina_eventos_comunidade />} />
        <Route path='/nutricao' element={<Pagina_nutricao />} />
        <Route path='/nutricao/feed' element={<Feed_receitas />} />
        <Route path='/nutricao/publicar' element={<Pagina_publicar_nutri />} />
        <Route path='/nutricao/agendamento' element={<Pagina_Agendar_nutri />} />
        <Route path='/sso_conexao' element={<SSOPage />} />
        <Route path='/alterar_senha' element={
          <Pagina_recuperacao_senha />
        } />
      </Routes>
    </div>
  );
}
