import React, { useState } from "react";
import { Head_calculadora, Corpo_calculadora } from "./calculadora.style.component";

const IMCCalculator: React.FC = () => {
  const [weight, setWeight] = useState<string>('');
  const [height, setHeight] = useState<string>('');
  const [imc, setIMC] = useState<number | null>(null);

  const calculateIMC = () => {
    if (weight && height) {
      const weightInKg: number = parseFloat(weight);
      const heightInCmOrM: number = parseFloat(height.replace(",", "."));
      let heightToUse: number;
      if (heightInCmOrM < 3) {
        heightToUse = heightInCmOrM;
      } else {
        heightToUse = heightInCmOrM / 100;
      }
      const calculatedIMC: number = weightInKg / (heightToUse * heightToUse);
      setIMC(parseFloat(calculatedIMC.toFixed(2)));
    } else {
      setIMC(null);
    }
  };

  return (
    <>
      <Corpo_calculadora>
        <Head_calculadora>
          <h2>Calculadora de IMC</h2>
        </Head_calculadora>
        <div>
          <label htmlFor="weight">Peso (kg): </label>
          <input
            type="number"
            id="weight"
            value={weight}
            placeholder="Insira seu peso em KG"
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="height">Altura (cm ou m): </label>
          <input
            type="number"
            id="height"
            value={height}
            placeholder="Insira sua altura em Cm ou M"
            onChange={(e) => setHeight(e.target.value)}
          />
        </div>
        <button onClick={calculateIMC}>Calcular</button>
        {imc !== null && (
          <div className="results">
            <h3>Seu IMC é: {imc}</h3>
            {imc !== null && <>
              {imc < 18.5 && <span>Seu IMC pode ser categorizado como abaixo do peso</span>}
              {imc >= 18.5 && imc < 24.9 && <span>Seu IMC pode ser categorizado como peso normal</span>}
              {imc >= 24.9 && imc < 29.9 && <span>Seu IMC pode ser categorizado como sobrepeso</span>}
              {imc >= 29.9 && <span>Seu IMC pode ser categorizado como equivalente a obesidade</span>}
            </>}
          </div>
        )}
      </Corpo_calculadora>
    </>
  );
}

export default IMCCalculator;
