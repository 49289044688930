import styled from 'styled-components'
import { RiMenu5Fill } from 'react-icons/ri'
interface SidebarProps {
  isExpanded: boolean
}

export const Botao_ajuda = styled.div`
  width: 4rem;
  height: auto;
  aspect-ratio: 2/3;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 0.3rem;
  position: absolute;
  top: 3.8rem;
  right: 6rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

export const Sidebar_base = styled.div<SidebarProps>`
  z-index: 10;
  background-color: ${(props) => props.theme.colors.primary};
  height: 100%;
  position: fixed;
  width: ${(props) => (props.isExpanded ? '100%' : '0rem')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${(props) => (props.isExpanded ? '4rem 2rem' : '4rem 1rem')};
  top: 0;
  left: 0;
`

export const Sidebar_icon = styled(RiMenu5Fill)`
  position: absolute;
  top: 2rem;
  left: 1rem;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  transition: all 0.5s;
  border-radius: 0.5rem;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    transform: translate(-50%, -50%) scale(1.1) !important;
  }
`
export const List = styled.ul`
  margin: 0;
  margin-left: 0rem !important;
  display: inline;
  padding-inline: 0;
  margin-block: 0;
  background-color: ${(props) => props.theme.colors.primary};
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: start;
  list-style: none;
`
export const LItem = styled.li`
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
  }
`

export const LoginArea = styled.div`
  position: fixed;
  bottom: 8rem;
  left: 3.5rem;
  width: 70%;
  height: 2rem;
  padding: 0.3rem;
  background-color: ${(props) => props.theme.colors.white};
  border: transparent;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Profile = styled.div`
  width: 75%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Profile_pic = styled.img`
  height: 1.8rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
`

export const UserName = styled.span`
  width: 80%;
  color: ${(props) => props.theme.colors.black};
  overflow: hidden;
`

export const Button = styled.button`
  width: 2.5rem;
  aspect-ratio: 1/1;
  padding: 0.2rem;
  border: transparent;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 25%;
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
  > * {
    color: white;
  }
`
