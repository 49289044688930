import Sidebar_social from "../../components/Sidebar/sidebar.social.component"
import Sugestoes_component from "../../components/Sugestoes de amizade/sugestoes.component"
import Navbar from "../../components/Navbar/navbar.component"
const Pagina_sugestoes = () => {
  return (<>
    <Sugestoes_component></Sugestoes_component>
    <Sidebar_social></Sidebar_social>
    <Navbar active="social"></Navbar></>)
}
export default Pagina_sugestoes
