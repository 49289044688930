const urlAgendaCloud =
  'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/agenda-lcmfit'
export const getEventosDia = async (dataAProcurar: string) => {
  try {
    const request = await fetch(`${urlAgendaCloud}/eventos`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        mode: 'cors',
      },
      body: JSON.stringify({
        data: dataAProcurar,
      }),
    })
    const response = await request.json()
    //console.log(response)
    if (request.status == 200) {
      ////console.log('Sucesso na requisição', response.events)
      return response.events
    } else if (response.message === 'Nenhum evento encontrado') {
      ////console.log('Nenhum evento encontrado.')
      return []
    }
  } catch (error) {
    ////console.log('Erro ao buscar eventos:', error)
    return []
  }
}
interface dadosCriacao {
  data: string
  horario: string
  email_usuario: string
  nome_usuario: string
  descricao: string
}
export const CriarEvento = async ({
  data,
  horario,
  email_usuario,
  nome_usuario,
  descricao,
}: dadosCriacao) => {
  try {
    const dadosAgendamento = {
      data_agendamento: data,
      horario: horario,
      email_usuario: email_usuario,
      nome_usuario: nome_usuario,
      descricao: descricao,
    }
    const request = await fetch(`${urlAgendaCloud}/criar_evento`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        mode: 'cors',
      },
      body: JSON.stringify(dadosAgendamento),
    })
    const response = await request.json()
    //console.log(response)
    //console.log('Resposta do servidor: ', response)
    if (response.status == 200) {
      const status = {
        status: 200,
        message: response.link_agendamento,
      }
      const enviarConfirmacao = await fetch(
        'https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/confirmarConsulta/',
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            mode: 'cors',
          },
          body: JSON.stringify({
            email_usuario: email_usuario,
            nome_usuario: nome_usuario,
            data_agendamento: data,
            horario_agendamento: horario,
          }),
        },
      )
      const responseConfirmation = await enviarConfirmacao.json()
      ////console.log(responseConfirmation)
      return status
    } else {
      const status = {
        status: 400,
        message: 'Falha na criação do evento',
      }
      return status
    }
  } catch (error) {
    const status = {
      status: 500,
      message: 'Falha na criação dos eventos',
    }
    return status
  }
}
export const requisitarEventosDoUsuario = async (email: string, tipo_agendamento: string) => {
  try {
    ////console.log('Requisitar eventos executada: ', email)
    const request = await fetch(urlAgendaCloud + '/eventos_usuario', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
        //'authorization':`Bearer ${}`,
      },
      body: JSON.stringify({ email_usuario: email, tipo_agendamento }),
    })
    const response = await request.json()
    //console.log(response)
    ////console.log(response)
    if (response.events) {
      if (response.events.length > 0) {
        const status = {
          status: 200,
          message: 'Sucesso',
          eventos: response.events,
        }
        return status
      } else {
        const status = {
          status: 200,
          message: 'Sucesso, mas sem eventos',
          eventos: response.events,
        }
        return status
      }
    } else {
      //console.log('Log do else, em que algo deu errado: ', response)
      const status = {
        status: response.status,
        message: 'Algo deu errado!',
        eventos: [],
      }
      return status
    }
  } catch (error) {
    const status = {
      status: 500,
      message: `Falha na obtenção dos eventos pelo frontend:${error}`,
      eventos: [],
    }
    return status
  }
}
