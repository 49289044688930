import styled from 'styled-components'
export const Title = styled.h2`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`

export const Head_container = styled.div`
  margin-top: 1rem;
  width: 60%;
  height: fit-content;
  padding: 0.5rem;
  position: relative;
  top: 5rem;
  left: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;
`
