import { Sidebar_base, Sidebar_icon, List, LItem, LoginArea, Profile_pic, Profile, UserName, Button, Botao_ajuda } from "./sidebar.style.component";
import { useState, useEffect } from "react";
import { ImExit } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { manterLogado, obterDadosSociais } from "../logics/def_functions";
import { MdOutlineLiveHelp } from "react-icons/md";
import Tutorial_screen from "../Tutorial/tutorial.component";
import feed_nutricao from '../Assets/Images/feed_nutricao.png'
import publicar_nutri from '../Assets/Images/publicar_nutri.png'
import agenda_nutri from '../Assets/Images/agenda_nutri.png'
const Sidebar_nutricao = () => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const [emailDeUsuario, setEmailDeUsuario] = useState('')
  const [tutorial, showTutorial] = useState(false)
  const [imagemPerfil, defImagemPerfil] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
  const inserirUsuario = async () => {
    const usuarioLogado = await obterDadosSociais({ metodo: 'self' })
    if (usuarioLogado.status == 200) {
      setEmailDeUsuario(usuarioLogado?.info?.user_full_name)
      defImagemPerfil(usuarioLogado?.info?.prof_pic)
    } else {
      navigate('/social/edit_social')
    }
  }
  const logoff = () => {
    ////console.log('Realizando logoff')
    localStorage.removeItem('token_acesso_LCMFIT');
    navigate('/login')
  };
  const toDicas = () => {
    navigate('/nutricao/feed')
  }
  const toPublicar = () => {
    navigate("/nutricao/publicar")
  }
  const toAgendar = () => {
    navigate("/nutricao/agendamento")
  }
  useEffect(() => {
    const logado = manterLogado()
    if (!logado) {
      navigate('/login')
    }
    inserirUsuario()
  }, [])

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  }
  const conteudos = [{
    setor: 'Dicas de nutrição',
    imagem: feed_nutricao,
    descricao: 'Neste setor, você pode ver as dicas de alimentação publicadas pelos seus colegas de trabalho.'
  }, {
    setor: 'Publicar receita',
    imagem: publicar_nutri, descricao: "Compartilhe uma receita sua com seus amigos e colegas! Mostre a eles o quão fitness você é."
  }, {
    setor: 'Consultar com nutricionista', imagem: agenda_nutri, descricao: "Marque uma consulta com o nutricionista da empresa para receber orientação profissional."
  },]
  return (
    <>
      <Sidebar_base isExpanded={isExpanded}>
        {isExpanded && (<><List>
          <LItem onClick={toDicas}>Dicas de nutrição</LItem>
          <LItem onClick={toPublicar}>Publicar uma receita</LItem>
          <LItem onClick={toAgendar}>Consultar com nutricionista</LItem>
        </List>
          {tutorial && (<Tutorial_screen estado='show' conteudos={conteudos} onClose={() => showTutorial(false)}></Tutorial_screen>)}
          <Botao_ajuda onClick={() => showTutorial(true)}><MdOutlineLiveHelp size={50} color="white"></MdOutlineLiveHelp><span>Ajuda</span></Botao_ajuda>
          <LoginArea>
            <Profile>
              <Profile_pic src={imagemPerfil}></Profile_pic>
              <UserName>{emailDeUsuario}</UserName>
            </Profile>
            <Button><ImExit size={30} onClick={() => logoff()} /></Button>
          </LoginArea>
        </>)}
        <Sidebar_icon onClick={toggleSidebar}></Sidebar_icon>
      </Sidebar_base>
    </>
  )
}
export default Sidebar_nutricao
