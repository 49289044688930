import styled from 'styled-components'
export const Head_notificacoes = styled.div`
  width: 75vw;
  height: fit-content;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  margin-left: 3.5rem;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
`
export const Body_notificacoes = styled.ul`
  padding: 0.5rem 1rem;
  margin-left: 3.5rem;
  gap: 0.2rem;
  width: 75vw;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray200};
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    width: 90%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: ${(props) => props.theme.colors.primary};
    text-align: center;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`
export const Imagem_perfil = styled.img`
  width: 2rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  left: 1rem;
`
