import { CalendarCont, CalendarBody, CalendarWeek, CalendarDay, CalendarHead, Buttons, TableHead, Popup, ButtonExitPopup, ListHorarios, Horario, Prontuario, Formulario, Button, EspacoAgendamentos, SimpleLI } from "./calendar.style";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { infoAtual, proximoMes, mesAnterior, indiceDia } from '../logics/calendar_logic';
import { useEffect, useState, useCallback } from "react";
import { getEventosDia, CriarEvento, requisitarEventosDoUsuario } from "../logics/agenda_nutri_logic";
import { obterDadosSociais } from "../logics/def_functions";
import Tela_confirmacao from "../Tela de confirmacao/confirmacao.component";
interface Data {
  dia: number;
  mes: number;
  ano: string;
}

interface Evento {
  id: string;
  creator: string;
  start: string;
  end: string;
  summary: string;
  link: string
}

const levarUsuario = (link: string) => {
  window.location.href = link
}
const formatarData = (data: Data) => {
  const dia = String(data.dia).padStart(2, '0');
  const mes = String(data.mes).padStart(2, '0');
  return `${data.ano}-${mes}-${dia}`;
}

const CalendarNutri = () => {
  const [estadoGeral, defEstadoGeral] = useState('loading')
  const [mensagemGeral, defMensagemGeral] = useState('Carregando...')
  const [agendamentos, definirAgendamentos] = useState<React.ReactNode[]>([]);
  const [horariosExibidos, exibirHorarios] = useState<React.ReactNode[]>([]);
  const [mes, setMes] = useState('');
  const [popup, showPopup] = useState('false');
  const [dataManuseio, setData] = useState('');
  const [horarioSelecionado, definirHorario] = useState('false');
  const [descricao, setDescricao] = useState('');
  const [atualizarAgendamentos, setAtualizarAgendamentos] = useState<boolean>(false);

  const DadosSociais = async () => {
    const usuarioLogado = await obterDadosSociais({ metodo: 'self' })
    return usuarioLogado
  }

  const fetchDadosSociais = useCallback(async () => {
    return await obterDadosSociais({ metodo: 'self' });
  }, []);

  const exibirPopup = async (data: Data) => {
    setData(`${data.dia}/${data.mes}/${data.ano}`);
    const eventosDia: Evento[] = await getEventosDia(formatarData(data));
    const busyFrame: string[] = eventosDia.map(evento => {
      const time = evento.start.split('T')[1].split('-')[0];
      return `${time.split(':')[0]}:${time.split(':')[1]}`;
    });

    const timeframe = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'];
    const horarios_disponiveis = timeframe.filter(element => !busyFrame.includes(element));

    if (horarios_disponiveis.length > 0) {
      const novosHorarios = horarios_disponiveis.map((evento, index) => (
        <Horario key={`${evento}-${index}`} horarioEscolhido={evento} onClick={prosseguirAgendamento}>{evento}</Horario>
      ));
      exibirHorarios(novosHorarios);
    } else {
      exibirHorarios([]);
    }
    showPopup('true');
  };


  const sairPopup = () => {
    exibirHorarios([]);
    showPopup('false');
    setData('');
    definirHorario('false');
    setAtualizarAgendamentos(true);
  };

  const prosseguirAgendamento = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const horario = event.currentTarget.textContent;
    if (horario) {
      definirHorario(horario);
    }
  };

  const confirmarAgendamento = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    defEstadoGeral('loading')
    defMensagemGeral('Carregando...')
    const descricaoInput = document.querySelector<HTMLInputElement>('#descricao');
    const dadosProntuario = descricaoInput?.value;
    if (dadosProntuario) {
      setDescricao(dadosProntuario);
    }
    const dados = await DadosSociais()
    const dadosAgendamento = {
      data: dataManuseio,
      horario: horarioSelecionado,
      descricao: dadosProntuario || descricao || '',
      especialidade: 'Clinica médica',
      email_usuario: dados?.info?.user_email ?? '',
      nome_usuario: dados?.info?.user_full_name ?? ''
    };
    definirHorario('false');
    const requisicao = await CriarEvento(dadosAgendamento)
    if (requisicao.status == 200) {
      defEstadoGeral('success')
      defMensagemGeral('Sucesso na criação do evento')
    } else {
      /*Inserir falha*/
      defEstadoGeral('failed')
      defMensagemGeral('Falha na criação do evento, tente novamente')
    }
  };

  const montarCalendario = useCallback(async () => {
    const infoDoMes = infoAtual();
    const mesAtual = infoDoMes.primeiro.split(' ')[3];
    const anoAtual = infoDoMes.primeiro.split(' ')[5];
    const hoje = `${infoDoMes.hoje.split(' ')[5]}-${infoDoMes.num_do_mes}-${infoDoMes.hoje.split(' ')[1]}`;
    setMes(`${mesAtual} - ${anoAtual}`);
    const espacos_calendario = document.querySelectorAll('.day');
    const ondeInicia = indiceDia(String(infoDoMes.primeiro.split(',')[0]));
    const ondeAcaba = Number(infoDoMes.ultimo.split(' ')[1]);
    let dataAInserirMes = 1;
    let dataAInserirMesSeg = 1;

    espacos_calendario.forEach((espaco, i) => {
      espaco.innerHTML = '';
      espaco.classList.remove('today', 'next', 'previous');
      espaco.addEventListener('click', () => {
        let mes = infoDoMes.num_do_mes;
        let diaAEnviar = i - ondeInicia + 2;
        if (diaAEnviar < 1) {
          mesAnterior();
          const ultimoDoMesPassado = Number(infoDoMes.ultimo.split(' ')[1]);
          diaAEnviar = ultimoDoMesPassado - (i * -1) - ondeInicia + 1;
          mes--;
          proximoMes();
        } else if (diaAEnviar > ondeAcaba) {
          diaAEnviar = diaAEnviar - ondeAcaba;
          mes++;
        }
        const data = {
          dia: diaAEnviar,
          mes: mes,
          ano: anoAtual
        };
        exibirPopup(data);
      });

      if (i < ondeInicia + 1) {
        mesAnterior();
        const ultimoDoMesPassado = Number(infoDoMes.ultimo.split(' ')[1]);
        const DiaDoMesPassado = (ultimoDoMesPassado - (i * -1) + 1) - (ondeInicia);
        proximoMes();
        espaco.innerHTML = `<span>${DiaDoMesPassado}</span>`;
      }

      if (i >= ondeInicia - 1 && i <= ondeAcaba + ondeInicia - 2) {
        if (Number(hoje.split('/')[0]) === dataAInserirMes && hoje.split('/')[1] === mesAtual) {
          espaco.classList.add('today');
        }
        espaco.innerHTML = `<span>${dataAInserirMes}</span>`;
        dataAInserirMes++;
      } else if (i >= ondeAcaba) {
        espaco.innerHTML = `<span>${dataAInserirMesSeg}</span>`;
        espaco.classList.add('next');
        dataAInserirMesSeg++;
      } else if (i < ondeInicia) {
        espaco.classList.add('previous');
      }
    });
  }, []);

  const fetchDadosEMontarCalendario = useCallback(async () => {
    const dados = await fetchDadosSociais();
    const email = dados.info?.user_email || ''
    const tipo_agendamento = 'Nutricionista'
    const eventosHoje = await requisitarEventosDoUsuario(email, tipo_agendamento);
    //console.log(eventosHoje)

    if (eventosHoje.eventos && eventosHoje.eventos.length > 0) {
      const novosHorarios = eventosHoje.eventos.map((evento: Evento) => (
        <Horario key={evento.id} className='evento' horarioEscolhido={evento?.start} onClick={() => levarUsuario(evento.link)}>
          {`${evento.start.split('T')[0].split('-')[2]}/${evento.start.split('T')[0].split('-')[1]} às ${evento.start.split('T')[1].split("-")[0]}`}
        </Horario>
      ));
      definirAgendamentos(novosHorarios);
    } else {
      definirAgendamentos([]);
    }

    await montarCalendario();
  }, [fetchDadosSociais, montarCalendario]);

  // Função para voltar um mês
  const voltarUmMes = useCallback(async () => {
    mesAnterior();
    await fetchDadosEMontarCalendario();
  }, [fetchDadosEMontarCalendario]);

  // Função para avançar um mês
  const avancarUmMes = useCallback(async () => {
    proximoMes();
    await fetchDadosEMontarCalendario();
  }, [fetchDadosEMontarCalendario]);

  useEffect(() => {
    fetchDadosEMontarCalendario().then(() => {
      defEstadoGeral("standby")
      defMensagemGeral('')
    })
  }, [fetchDadosEMontarCalendario, atualizarAgendamentos]);

  return (
    <>
      <CalendarCont>
        <CalendarHead>
          <tr>
            <TableHead>
              <Buttons onClick={voltarUmMes}>{`<`}</Buttons>
              <h2>{mes}</h2>
              <Buttons onClick={avancarUmMes}>{`>`}</Buttons>
            </TableHead>
          </tr>
        </CalendarHead>
        <CalendarBody>
          <CalendarWeek>
            <CalendarDay className="weekdays"><span>Dom</span></CalendarDay>
            <CalendarDay className="weekdays"><span>Seg</span></CalendarDay>
            <CalendarDay className="weekdays"><span>Ter</span></CalendarDay>
            <CalendarDay className="weekdays"><span>Qua</span></CalendarDay>
            <CalendarDay className="weekdays"><span>Qui</span></CalendarDay>
            <CalendarDay className="weekdays"><span>Sex</span></CalendarDay>
            <CalendarDay className="weekdays"><span>Sab</span></CalendarDay>
          </CalendarWeek>
          {[...Array(6)].map((_, weekIdx) => (
            <CalendarWeek key={weekIdx}>
              {[...Array(7)].map((_, dayIdx) => (
                <CalendarDay className="day" key={dayIdx}></CalendarDay>
              ))}
            </CalendarWeek>
          ))}
        </CalendarBody>
      </CalendarCont>
      {popup === 'true' &&
        <Popup>
          <ButtonExitPopup onClick={sairPopup}>
            <IoIosCloseCircleOutline size={30} />
          </ButtonExitPopup>
          {horarioSelecionado === 'false' && <>
            <h2>Horários disponíveis em {dataManuseio}</h2>
            <ListHorarios>
              {horariosExibidos.length > 0 &&
                <>
                  {horariosExibidos}
                </>
              }
              {horariosExibidos.length == 0 &&
                <SimpleLI>Sem horários disponíveis</SimpleLI>
              }
            </ListHorarios>
          </>}
          {horarioSelecionado !== 'false' && <>
            <Prontuario>
              <h2>Horário definido, dê uma breve descrição a sua necessidade</h2>
              <Formulario type="text" id="descricao"></Formulario>
              <Button onClick={confirmarAgendamento}>Confirmar agendamento</Button>
            </Prontuario>
          </>}
        </Popup>}
      <EspacoAgendamentos>
        <h2>Seus agendamentos</h2>
        <ListHorarios>
          {agendamentos.length > 0 &&
            <>
              {agendamentos}
            </>}
          {agendamentos.length == 0 &&
            <SimpleLI>Sem agendamentos</SimpleLI>
          }
        </ListHorarios>
      </EspacoAgendamentos>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    </>
  );
};

export default CalendarNutri;
