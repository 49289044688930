import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component";
import IMCCalculator from "../../components/calculadora_imc/calculadora.componets";
import ReturnButton from "../../components/Botao retornar/return.component";

const Pagina_calculadora = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <IMCCalculator></IMCCalculator>
    <Sidebar_exercicios></Sidebar_exercicios>
    <Navbar active="exercicios"></Navbar></>)
}

export default Pagina_calculadora
