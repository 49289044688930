import { useLocation, useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'
import { Corpo, Form_alteracao, Field, BotaoConfirmar, VoltarPraHome } from './reset_pass.style'
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component'
import { alterarSenha } from '../logics/def_functions'
import { IoMdArrowRoundBack } from "react-icons/io";
const Reset_senha = () => {
  const [estadoGeral, setEstadoGeral] = useState('standby')
  const [mensagemGeral, setMensagemGeral] = useState('')
  const location = useLocation()
  const informacoesDecodificadas = JSON.parse(decodeURIComponent(location.search.replace("?token=", '')))
  const agora = Date.now()
  console.table(agora, informacoesDecodificadas.expiracao)
  //console.log("Token expirado? ", agora > informacoesDecodificadas.expiracao)
  const senha1ref = useRef<HTMLInputElement | null>(null)
  const senha2ref = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()
  const toHome = () => navigate("/")
  useEffect(() => {
    if (agora > informacoesDecodificadas.expiracao) {
      setEstadoGeral('failed')
      setMensagemGeral('Link expirado, tente novamente')
    }
  }, [])
  const tentarReset = async () => {
    if (senha1ref.current && senha2ref.current) {
      if (senha1ref.current.value !== senha2ref.current.value) {
        setEstadoGeral('failed')
        setMensagemGeral('Erro: Senhas não batem')
      } else {
        const regexSenha = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!regexSenha.test(senha1ref.current.value)) {
          setEstadoGeral('failed')
          setMensagemGeral('Erro: Senha inválida! É necessário que a senha contenha pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.')
        } else {
          const token = decodeURIComponent(location.search.replace("?token=", ''))
          const alterar: {
            status: number,
            message?: string
          } = await alterarSenha(token, senha1ref.current.value)
          //console.log(alterar.message)
          if (alterar.status == 200) {
            setEstadoGeral('success')
            setMensagemGeral('Senha alterada com sucesso!')
          } else {
            setEstadoGeral('failed')
            if (alterar.message)
              setMensagemGeral(alterar.message)
          }
        }
      }
    }
  }
  return (<>
    <Corpo>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Form_alteracao>
        <VoltarPraHome onClick={toHome}><IoMdArrowRoundBack size={30} color='white'></IoMdArrowRoundBack></VoltarPraHome>
        <h1>Alterar senha</h1>
        <Field ref={senha1ref} placeholder='Sua senha'>
        </Field>
        <Field ref={senha2ref} placeholder='Confirme sua senha'>
        </Field>
        <BotaoConfirmar onClick={() => tentarReset()}>Confirmar Alteração</BotaoConfirmar>
      </Form_alteracao>
    </Corpo>
  </>)
}
export default Reset_senha
