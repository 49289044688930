import Register_component from '../../components/Login_component/register.component'
import './style.css'
const Pagina_register = () => {
  return (<>
    <div className="fundo">
      <Register_component></Register_component>
    </div>
  </>)

}
export default Pagina_register
