import React, { useState, useRef, useEffect } from 'react';
import { Container, Formulario, Button, Input_image, Image_area, Button_back } from "./edit_social.style";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { EditarOuCriarSocial } from '../logics/def_functions';
import { CloudinaryService } from '../logics/cloudinaryUploader';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';

const Cadastro_perfil: React.FC = () => {
  const navigate = useNavigate();
  const cloudinaryService = new CloudinaryService

  const [estadoGeral, setEstadoGeral] = useState("standby")
  const [mensagemGeral, setMensagemGeral] = useState('')
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const backToHome = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate('/social');
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const linkImage = await cloudinaryService.upload(file)
      setImageUrl(linkImage.info)
    }
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  const registerInfo = async (event: React.MouseEvent) => {
    event.preventDefault();
    const nomeUsuario = (document.querySelector('#nome_usuario') as HTMLInputElement).value;
    const nomeCompleto = (document.querySelector('#nome_completo') as HTMLInputElement).value;
    const bio = (document.querySelector('#bio') as HTMLInputElement).value;

    const info = {
      profilePic: imageUrl || '',
      userName: nomeUsuario.trim(),
      fullNameUsuario: nomeCompleto.trim(),
      bio: bio
    };

    const edicao = await EditarOuCriarSocial(info);
    if (edicao?.status == 200 || edicao?.status == 201) {
      setEstadoGeral('success')
      setMensagemGeral('Dados atualizados com sucesso')
      navigate(`/social/perfil?${info?.userName}`);
    } else if (edicao?.status == 403) {
      setEstadoGeral('failed')
      setMensagemGeral('Autenticação inválida, realize o login novamente: ')
      navigate('/login')
    } else {
      setEstadoGeral('failed')
      setMensagemGeral(`Houve uma falha: ${String(edicao?.status)}`)
    }
  };
  useEffect(() => {
    const preventPullToRefresh = (event: TouchEvent) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchstart', preventPullToRefresh, { passive: false });

    return () => {
      document.removeEventListener('touchstart', preventPullToRefresh);
    };
  }, []);


  return (
    <Container>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Button_back onClick={backToHome}><IoIosArrowBack /></Button_back>
      <h2>Preencha seu perfil</h2>
      <Formulario>
        <label htmlFor='nome_usuario'>Insira seu nome de usuário:</label>
        <input className="input" id='nome_usuario' type="text" />
        <label htmlFor='nome_completo'>Insira seu nome completo:</label>
        <input className="input" id='nome_completo' type="text" />
        <label htmlFor='bio'>Escreva uma bio para seu perfil:</label>
        <input className="input" id='bio' type="text" />
        <label htmlFor='foto_perfil'>Escolher foto de perfil:</label>
        <Image_area>
          <Input_image type='file' ref={fileInputRef} onChange={handleImageUpload} />
          <Button onClick={handleButtonClick}>
            <MdOutlineAddPhotoAlternate size={30} />
          </Button>
        </Image_area>
        {imageUrl && (
          <>
            <span>Pré-visualização da imagem</span>
            <img src={imageUrl} alt="Preview" style={{ maxWidth: '15rem', marginTop: '10px' }} />
          </>
        )}
        <Button onClick={registerInfo}>Salvar informações</Button>
      </Formulario>
    </Container>
  );
}

export default Cadastro_perfil;
