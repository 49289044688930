import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component";
import Eventos_comunidade from "../../components/Eventos da comunidade/eventos_sociais.component";
import ReturnButton from "../../components/Botao retornar/return.component";
const Pagina_eventos_comunidade = () => {
  return (
    <>
      <ReturnButton></ReturnButton>
      <Eventos_comunidade></Eventos_comunidade>
      <Sidebar_exercicios></Sidebar_exercicios>
      <Navbar active="exercicios"></Navbar>
    </>)
}

export default Pagina_eventos_comunidade
