import { LuPlusCircle } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { Corpo_batePapo, Chats_espaco, Busca_espaco, Chat, NovoChat, ProfilePic, MessagePreview } from "./chat.style";

interface Mensagem {
  id_mensagem: string;
  autor: string;
  conteudo: string;
  data: string;
  anexo?: string;
}

interface dadosChat {
  id_chat: string;
  participantes: string[];
  mensagens: Mensagem[];
}

const dadosCv: dadosChat = {
  id_chat: '564654654',
  participantes: ['6546545646546', '456465456465', '6546546545646'],
  mensagens: [{ id_mensagem: '5645646', autor: '6546545646546', data: '26/05/2024T04:00:00', conteudo: 'Mensagem de teste' }],
};

const conversas = [dadosCv, dadosCv, dadosCv, dadosCv];

const Component_chat = () => {
  const navigate = useNavigate();

  const redirectToChat = (id_chat: string) => {
    navigate(`/social/bate_papo/chat?${id_chat}`);
  };

  const Mapchats = (): React.ReactNode[] => {
    return conversas.map((element, index) => {
      const mensagem = element.mensagens[0]; // Obtém a primeira mensagem
      return (
        <Chat key={index} onClick={() => redirectToChat(element.id_chat)}>
          <ProfilePic src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_0.jpg" />
          <MessagePreview>
            <span>{element.participantes[0]}</span>
            <span>{mensagem ? mensagem.conteudo : "Nenhuma mensagem"}</span>
          </MessagePreview>
        </Chat>
      );
    });
  };

  return (
    <>
      <Corpo_batePapo>
        <NovoChat>
          <LuPlusCircle size={20} color="white" />
        </NovoChat>
        <Busca_espaco>
          <form className="search_form">
            <input />
          </form>
          <button>
            <CiSearch size={20} color="white" />
          </button>
        </Busca_espaco>
        <Chats_espaco>{Mapchats()}</Chats_espaco>
      </Corpo_batePapo>
    </>
  );
};

export default Component_chat;
