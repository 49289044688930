import { Sidebar_base, Sidebar_icon, List, LItem, LoginArea, Profile_pic, Profile, UserName, Button, Botao_ajuda } from "./sidebar.style.component";
import { MdOutlineLiveHelp } from "react-icons/md";
import { useState, useEffect } from "react";
import { ImExit } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { manterLogado, obterDadosSociais } from "../logics/def_functions";
import Tutorial_screen from "../Tutorial/tutorial.component";
import agendamentos from '../Assets/Images/agendamentos.png'
import exames from '../Assets/Images/exames.png'
import dados from '../Assets/Images/dados.png'

const Sidebar_saude = () => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const [emailDeUsuario, setEmailDeUsuario] = useState('')
  const [tutorial, showTutorial] = useState(false)
  const [imagemPerfil, defImagemPerfil] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
  const inserirUsuario = async () => {
    const usuarioLogado = await obterDadosSociais({ metodo: 'self' })
    if (usuarioLogado.status == 200) {
      setEmailDeUsuario(usuarioLogado?.info?.user_full_name)
      defImagemPerfil(usuarioLogado?.info?.prof_pic)
    } else {
      navigate('/social/edit_social')
    }
  }
  const logoff = () => {
    ////console.log('Realizando logoff')
    localStorage.removeItem('token_acesso_LCMFIT');
    navigate('/login')
  };

  useEffect(() => {
    const logado = manterLogado()
    if (!logado) {
      navigate('/login')
    }
    inserirUsuario()
  }, [])

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  }
  const toExames = () => {
    navigate("/saude/exames")
  }
  const toDados = () => {
    navigate("/saude/dados")
  }
  const toConsultas = () => {
    navigate("/saude/consultas")
  }

  const conteudos = [{
    setor: 'Consultas',
    imagem: agendamentos,
    descricao: 'Em consultas, você consegue marcar um exame com a nossa clinica geral, dra. Walneia. As consultas são realizadas via Google Meet e dependem da disponibilidade do profissional.'
  }, {
    setor: 'Meus exames',
    imagem: exames, descricao: "Em meus exames, todos os exames realizados por profissionais da LCM estarão disponíveis pra você em uma pasta separada do drive."
  }, {
    setor: 'Dados de emergência do usuário', imagem: dados, descricao: "Em dados de saúde do usuário, você pode incluir seus dados de emergência para facilitar possíveis socorros. Para editar, clique no ícone a esquerda do perfil."
  },]

  return (
    <>
      <Sidebar_base isExpanded={isExpanded}>
        {isExpanded && (<>
          <List>
            <LItem onClick={toConsultas}>Consultas</LItem>
            <LItem onClick={toDados}>Meus dados</LItem>
            <LItem onClick={toExames}>Meus exames</LItem>
          </List>
          {tutorial && (<Tutorial_screen estado='show' conteudos={conteudos} onClose={() => showTutorial(false)}></Tutorial_screen>)}
          <Botao_ajuda onClick={() => showTutorial(true)}><MdOutlineLiveHelp size={50} color="white"></MdOutlineLiveHelp><span>Ajuda</span></Botao_ajuda>
          <LoginArea>
            <Profile>
              <Profile_pic src={imagemPerfil}></Profile_pic>
              <UserName>{emailDeUsuario}</UserName>
            </Profile>
            <Button><ImExit size={30} onClick={() => logoff()} /></Button>
          </LoginArea>
        </>)}
        <Sidebar_icon onClick={toggleSidebar}></Sidebar_icon>
      </Sidebar_base>
    </>
  )
}
export default Sidebar_saude
