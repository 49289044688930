import { useState, useEffect } from "react";
import { Header_feed, Posts_grid, SolicitarMaisPosts } from "./feed_receitas.style.component";
import Post from "../../components/Post/post.component";
import Navbar from "../../components/Navbar/navbar.component";
import { obterReceitasFeed } from "../../components/logics/def_functions";
import ReturnButton from "../../components/Botao retornar/return.component";
import Sidebar_nutricao from "../../components/Sidebar/sidebar.nutricao.component";

interface Information {
  status: number;
  message: string;
  info: {
    paginaAtual: string;
    paginas: string;
    posts: PostProps[];
  };
}

interface PostProps {
  _id: string;
  post_author: string;
  post_date: string;
  user_email: string;
  post_description: string;
  post_image: string;
  post_likes: string[];
  post_comments: string[];
}

// Função para filtrar posts duplicados por ID
const filtrarPostsDuplicados = (posts: PostProps[]): PostProps[] => {
  const postIds = new Set<string>();
  return posts.filter(post => {
    if (postIds.has(post._id)) {
      return false;
    } else {
      postIds.add(post._id);
      return true;
    }
  });
};

const Feed_receitas: React.FC = () => {
  const [feed] = useState('geral');
  const [postsElements, definePosts] = useState<PostProps[]>([]);
  const [pagina, setPagina] = useState('1');

  const changePage = () => {
    const proximaPagina = Number(pagina) + 1;
    setPagina(String(proximaPagina));
  };

  const fetchPosts = async () => {
    const posts: Information = await obterReceitasFeed({ metodo: feed, pagina: pagina, limite: '20' });

    // Removendo posts duplicados antes de definir o estado
    const novosPosts = posts.info.posts.filter(
      post => !postsElements.some(existingPost => existingPost._id === post._id)
    );

    definePosts(prevPosts => filtrarPostsDuplicados([...prevPosts, ...novosPosts]));
  };

  useEffect(() => {
    definePosts([]);
    setPagina('1');
  }, [feed]);

  useEffect(() => {
    fetchPosts();
  }, [pagina, feed]);

  return (
    <>
      <ReturnButton></ReturnButton>
      <Sidebar_nutricao></Sidebar_nutricao>
      <Header_feed>
        <h2>Dicas de nutrição</h2>
      </Header_feed>
      <Posts_grid>
        {postsElements.map((element) => (
          <Post key={element._id} {...element}></Post>
        ))}
      </Posts_grid>
      <SolicitarMaisPosts onClick={changePage}><span>Ver mais posts</span></SolicitarMaisPosts>
      <Navbar active="nutricao"></Navbar>
    </>
  );
};

export default Feed_receitas;
