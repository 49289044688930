import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tela_confirmacao from "../../components/Tela de confirmacao/confirmacao.component";

const SSOPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const identificarESalvarToken = () => {
    try {
      const tokenCodificado = location.search.split("?")[1];
      if (!tokenCodificado) {
        throw new Error("Token não encontrado na URL");
      }
      const tokenDecodificado = decodeURIComponent(tokenCodificado);
      localStorage.setItem('token_acesso_LCMFIT', tokenDecodificado);
      navigate('/saude');
    } catch (error) {
      console.log("Erro que está me avacalhando: ", error);
    }
  };

  useEffect(() => {
    identificarESalvarToken();
  }, [location.search]);

  return (
    <Tela_confirmacao tipo="loading" texto="Carregando.." />
  );
};

export default SSOPage;
