import styled from 'styled-components'

export const Container = styled.div`
  width: 70%;
  height: fit-content;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  align-self: center;
  > * .input {
    width: 80%;
    padding: 0.4rem;
    border-radius: 0.5rem;
    border: transparent;
  }
`

export const Formulario = styled.form`
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Input_image = styled.input`
  display: none;
`

export const Image_area = styled.div`
  padding: 0.2rem;
  border-radius: 0.3rem;
  gap: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
`

export const Button = styled.button`
  padding: 0.3rem;
  border-radius: 0.4rem;
  border: transparent;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  transition: all 0.5s;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.white};
    transform: scale(1.1);
  }
`
export const Button_back = styled.button`
  width: 2rem;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 0.3rem;
  border: 0.1rem solid ${(props) => props.theme.colors.white};
  position: fixed;
  top: 1rem;
  width: 3rem;
  padding: 0.5rem;
  left: 3rem;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    color: ${(props) => props.theme.colors.white};
  }
`
