import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegCalendar, FaPlus } from 'react-icons/fa';
import { Header_eventos, Container_eventos, Container_evento, Date_evento, Button_CriarNovo, ElementoLi } from "./eventos_sociais.component.style";
import { timestampToData } from '../logics/functions';
import { getEventosComunitarios, obterDadosSociais } from '../logics/def_functions';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';

interface dadosEvento {
  id: string,
  creator: string,
  image: string,
  start: string,
  end: string,
  title: string,
  summary: string,
  link: string,
  location: string
}

const Eventos_comunidade = () => {
  const [estadoGeral, setEstadoGeral] = useState("loading")
  const [mensagemGeral, setMensagemGeral] = useState('Carregando...')
  const [eventos, setEventos] = useState<React.ReactNode[] | null>(null);
  const navigate = useNavigate();

  const toEvento = (id: string) => {
    navigate('/exercicios/eventos_sociais/evento?id=' + id);
  };

  const toCriarEventoCorp = () => {
    navigate('/exercicios/eventos_sociais/criar_evento');
  };

  const ObterNomeSocial = async (informacao: string) => {
    const dados = await obterDadosSociais({ email_usuario: informacao, metodo: 'others' });
    console.log(dados)
    return dados.info.user_full_name;
  };

  const fetchData = useCallback(async () => {
    const eventosComunitarios = await getEventosComunitarios() || [];
    //console.log(eventosComunitarios)
    const eventosAExibirPromises = eventosComunitarios.events.map(async (evento: dadosEvento) => {
      console.log(evento)
      const creatorName = await ObterNomeSocial(String(evento.creator).replace("organizador:", ''));
      return (
        <Container_evento key={evento.id} backgroundImage={String(evento.image).replace('imagem:', '')} onClick={() => toEvento(evento.id)}>
          <FaRegCalendar size={100} className="content" />
          <Date_evento className="content">{timestampToData(evento.start)}</Date_evento>
          <div className='disclaimer_evento content'>
            <h2>{String(evento.title).replace('titulo:', '')}</h2>
            <span>{String(evento.location).replace('local:', '')}</span>
            <span>{creatorName}</span>
          </div>
        </Container_evento>
      );
    });


    const eventosAExibir = await Promise.all(eventosAExibirPromises);
    setEventos(eventosAExibir);
  }, []);

  useEffect(() => {
    fetchData().then(() => {
      setEstadoGeral('standby')
      setMensagemGeral('')
    })
  }, [fetchData]);

  return (
    <>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Header_eventos>
        <h2>Eventos comunitários no próximo mês</h2>
      </Header_eventos>
      <Container_eventos>
        {eventos}
        {eventos == null || eventos.length == 0 && <ElementoLi><span>Sem eventos</span></ElementoLi>}
      </Container_eventos>
      <Button_CriarNovo onClick={toCriarEventoCorp}><FaPlus color='white' size={30}></FaPlus></Button_CriarNovo>
    </>
  );
};

export default Eventos_comunidade;
