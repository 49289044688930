import Navbar from "../../components/Navbar/navbar.component"
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component"
import Cadastro_evento_social from "../../components/Criar evento social/criar_evento_social.component"
const PaginaCriarEventoSocial = () => {
  return (<>
    <Cadastro_evento_social></Cadastro_evento_social>
    <Navbar active="exercicios"></Navbar>
    <Sidebar_exercicios></Sidebar_exercicios>
  </>)
}
export default PaginaCriarEventoSocial
