import Exames_component from "../../components/Exames/exames.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_saude from "../../components/Sidebar/sidebar.saude.component";
import ReturnButton from "../../components/Botao retornar/return.component";
import Tutorial_screen from "../../components/Tutorial/tutorial.component";
const Pagina_exames = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Tutorial_screen></Tutorial_screen>
    <Exames_component></Exames_component>
    <Sidebar_saude></Sidebar_saude>
    <Navbar active="saude"></Navbar></>)
}

export default Pagina_exames
