import { useState, useEffect, useCallback } from 'react';
import { Main_perfil, Prof_pic, Username, Bios, Container, Button_edit, FollowersCont, InterUser, PopupFollowers, ButtonExitPopupFollowers, Lista_followers, Profile_follower } from './profile_main.component.style';
import { obterDadosSociais, ToggleFollow, notificar } from '../logics/def_functions';
import Galeria_generica from '../Galeria generica/galeria.component';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

interface PerfilDeUsuario {
  _id: string,
  bio: string,
  followers: string[],
  following: string[],
  prof_pic: string,
  user_email: string,
  user_full_name: string,
  user_name: string,
  posts: string[]
}

interface Seguidor {
  info: {
    _id: string,
    prof_pic: string,
    user_full_name: string,
    user_name: string
  }
}

const Perfil_generico = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [usuario, setUsuario] = useState<PerfilDeUsuario | null>(null);
  const [ownerProfile, defOwnerProfile] = useState(false);
  const [myProfile, defMyProfile] = useState<PerfilDeUsuario | null>(null)
  const [loading, setLoading] = useState(true);
  const [usuarios, showUsuarios] = useState('none');
  const [following, setFollowing] = useState(false);
  const [followersData, setFollowersData] = useState<JSX.Element[]>([]);
  const [followingData, setFollowingData] = useState<JSX.Element[]>([]);
  const [page, setPage] = useState(1);

  const user_name = decodeURIComponent(location.search.substring(1).trim());

  if (user_name === 'undefined') {
    navigate("/social/edit_social");
  }

  const perfilProprio = useCallback(async () => {
    const meuPerfil = await obterDadosSociais({ metodo: 'self' });
    //console.log('Minhas informações:', meuPerfil.info)
    defMyProfile(meuPerfil.info)
    return meuPerfil;
  }, []);

  const perfilAlheio = useCallback(async () => {
    const response = await obterDadosSociais({ metodo: 'others', nome_usuario: decodeURIComponent(user_name) });
    return response;
  }, [user_name]);

  const toEdit = () => {
    navigate('/social/edit_social');
  };

  const mostrarSeguindo = () => {
    showUsuarios('following');
    setPage(1);
  };

  const mostrarSeguidores = () => {
    showUsuarios('followers');
    setPage(1);
  };

  const ocultarPopupSeguidores = () => {
    showUsuarios('none');
    setFollowersData([]);
    setFollowingData([]);
  };

  const toggleSeguir = async (id_seguidor: string) => {
    const acaoSeguir = await ToggleFollow(user_name);
    //console.log(acaoSeguir)
    if (acaoSeguir.status === 200) {
      setFollowing(!following);
      const notificacao = await notificar({ id_objeto: id_seguidor, tipo_objeto: 'user', tipo_interacao: 'follow' });
      //console.log("Status de notificacao", notificacao)
      if (usuario?.followers.some((element) => {
        return element === myProfile?._id
      })) {
        if (myProfile?._id) {
          const index = usuario?.followers.indexOf(myProfile?._id)
          usuario?.followers.splice(index, 1)
        }
      } else {
        if (myProfile?._id) {
          usuario?.followers.push(myProfile._id)
        }
      }
    }
  };

  const seguirEmOutroPerfil = async (nome_seguidor: string, id_seguidor: string) => {
    const acaoSeguir = await ToggleFollow(nome_seguidor);
    if (acaoSeguir.status == 200) {
      const notificacao = await notificar({ id_objeto: id_seguidor, tipo_objeto: 'user', tipo_interacao: 'follow' });
      //console.log("Status de notificacao", notificacao)
    }
  }

  const fetchUsuarios = useCallback(async (tipo: string, page: number) => {
    const limit = 20;
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;

    if (usuario) {
      if (tipo === 'followers' && usuario.followers) {
        const seguidores = usuario.followers.slice(startIndex, endIndex);
        const fetchedData = await Promise.all(seguidores.map(async (element) => {
          const seguidor: Seguidor = await obterDadosSociais({ metodo: 'others', id_usuario: element });
          //console.log(seguidor, element)
          if (seguidor.info) {
            return (
              <Profile_follower key={seguidor.info?._id} onClick={() => navigate(`/social/perfil?${seguidor.info?.user_name}`)}>
                <img src={seguidor.info?.prof_pic} alt='Foto de perfil' />
                <span>{seguidor.info?.user_full_name}</span>
              </Profile_follower>
            );
          }
          return null;
        }));
        setFollowersData((prevData) => [...prevData, ...fetchedData.filter((item): item is JSX.Element => item !== null)]);
      } else if (tipo === 'following' && usuario.following) {
        const seguidos = usuario.following.slice(startIndex, endIndex);
        const fetchedData = await Promise.all(seguidos.map(async (element) => {
          const seguidor: Seguidor = await obterDadosSociais({ metodo: 'others', id_usuario: element });
          if (seguidor) {
            return (
              <Profile_follower key={seguidor.info?._id} onClick={() => navigate(`/social/perfil?${seguidor.info.user_name}`)}>
                <img src={seguidor.info?.prof_pic} alt='Foto de perfil' />
                <span >{seguidor.info?.user_full_name}</span>
              </Profile_follower>
            );
          }
          return null;
        }));
        setFollowingData((prevData) => [...prevData, ...fetchedData.filter((item): item is JSX.Element => item !== null)]);
      }
    }
  }, [usuario]);
  useEffect(() => {
    fetchUsuarios(usuarios, page);
  }, [usuarios, page, fetchUsuarios]);
  useEffect(() => {
    showUsuarios('none')
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const meuPerfil = await perfilProprio();
        const perfil = await perfilAlheio();
        console.log("Perfil em questão: ", perfil)
        setUsuario(perfil.info);
        setLoading(false);
        //console.log(meuPerfil, perfil)
        if (meuPerfil.info._id === perfil.info._id) {
          defOwnerProfile(true);
        } else {
          defOwnerProfile(false)
        }

        if (perfil.info.followers.some((element: string) => element === meuPerfil.info._id)) {
          setFollowing(true);
        }
      } catch (error) {
        console.error("Erro ao carregar os dados do perfil:", error);
      }
    };
    fetchData();
  }, [perfilProprio, perfilAlheio, user_name]);

  useEffect(() => {
    if (myProfile && usuario) {
      if (usuario.followers.some((element) => element === myProfile._id)) {
        setFollowing(true);
      } else {
        setFollowing(false);
      }
    }
  }, [myProfile, usuario]);

  return (
    <Container>
      <Main_perfil>
        {ownerProfile && <Button_edit onClick={toEdit}><FaEdit /></Button_edit>}
        <Prof_pic alt="Imagem_perfil_mini" src={usuario?.prof_pic} />
        <Username>{usuario?.user_name}</Username>
        <Username>{usuario?.user_full_name}</Username>
        <Bios>{usuario?.bio}</Bios>
        <FollowersCont>
          <div onClick={mostrarSeguidores}><span><strong>Seguidores</strong> {usuario?.followers?.length || 0}</span></div>
          <div onClick={mostrarSeguindo}><span><strong>Seguindo</strong> {usuario?.following?.length || 0}</span></div>
        </FollowersCont>
        {!ownerProfile && <InterUser>{!following ? <button onClick={() => { if (usuario) toggleSeguir(usuario._id,) }}>Seguir</button> : <button onClick={() => { if (usuario) toggleSeguir(usuario._id,) }}>Deixar de seguir</button>}</InterUser>}
      </Main_perfil>
      {usuario?.posts && usuario.posts.length > 0 && <Galeria_generica posts={usuario?.posts}></Galeria_generica>}
      {usuarios !== 'none' && <>
        {usuarios === 'followers' && <>
          <PopupFollowers>
            <ButtonExitPopupFollowers onClick={ocultarPopupSeguidores}><IoClose size={20} color='black'></IoClose></ButtonExitPopupFollowers>
            {followersData.length === 0 && <span>Sem seguidores</span>}
            {followersData.length > 0 && <Lista_followers>{followersData}</Lista_followers>}
            {followersData.length < (usuario?.followers?.length || 0) && <button onClick={() => setPage(page + 1)}>Carregar mais</button>}
          </PopupFollowers>
        </>}
        {usuarios === 'following' && <>
          <PopupFollowers>
            <ButtonExitPopupFollowers onClick={ocultarPopupSeguidores}><IoClose size={20} color='black'></IoClose></ButtonExitPopupFollowers>
            {followingData.length === 0 && <span>Ainda não está seguindo ninguém</span>}
            <Lista_followers><li><span>Seguindo</span></li>{followingData}</Lista_followers>
            {followingData.length < (usuario?.following?.length || 0) && <button onClick={() => setPage(page + 1)}>Carregar mais</button>}
          </PopupFollowers>
        </>}
      </>}
    </Container>
  );
};

export default Perfil_generico;
