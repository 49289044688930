export const diferenca_data = (dataEspecifica: string) => {
  const dataEspecificaObj = new Date(dataEspecifica)
  const dataAtual = new Date()
  const diferencaMilissegundos = dataAtual.getTime() - dataEspecificaObj.getTime()
  const diferencaDias = Math.floor(diferencaMilissegundos / (1000 * 60 * 60 * 24))
  const diferencaHoras = Math.floor(
    (diferencaMilissegundos % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  )
  const diferencaMinutos = Math.floor((diferencaMilissegundos % (1000 * 60 * 60)) / (1000 * 60))
  if (diferencaDias > 1) {
    return `${diferencaDias} dias atrás`
  } else if (diferencaHoras > 1) {
    return `${diferencaHoras} horas atrás`
  } else if (diferencaMinutos > 1) {
    return `${diferencaMinutos} minutos atrás`
  } else {
    return `agora`
  }
}

export const timestampToData = (timestamp: string | undefined) => {
  const data = timestamp?.split('T')[0].split('-')
  const horas = timestamp?.split('T')[1].split('-')[0].split(':')
  if (data && horas) {
    return `${data[2]}/${data[1]} as ${horas[0]}:${horas[1]}`
  }
  return null
}

export const func_calculadora = (altura: number, peso: number) => {
  const imc: number = (altura * altura) / peso
  switch (true) {
    case imc < 18.5:
      return 'Baixo peso'
      break
    case imc > 18.5 && imc < 24.9:
      return 'Peso Normal'
      break
    case imc > 25 && imc < 29.9:
      return 'Excesso de peso'
      break
    case imc > 30 && imc < 34.9:
      return 'Obesidade de Classe 1'
      break
    case imc > 35 && imc < 39.9:
      return 'Obesidade de Classe 2'
      break
    case imc > 40:
      return 'Obesidade de Classe 3'
      break
  }
}
