import Component_chat from "../../components/Chat/chat.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";
import Navbar from "../../components/Navbar/navbar.component";
import ReturnButton from "../../components/Botao retornar/return.component";

const Pagina_chat = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Sidebar_social></Sidebar_social>
    <Component_chat></Component_chat>
    <Navbar></Navbar></>)
}
export default Pagina_chat
