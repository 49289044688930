import Container_exercicios from "../../components/Sections/container_exercicios.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component";

const Pagina_exercicios = () => {
  return (<>
    <Container_exercicios></Container_exercicios>
    <Sidebar_exercicios></Sidebar_exercicios>
    <Navbar active="exercicios"></Navbar>
  </>
  )
}

export default Pagina_exercicios
