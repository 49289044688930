import { useState, useEffect } from "react";
import { HeaderPlaylists, Container_playlist, Container_playlists } from "./playlist.style";

interface Playlist {
  link?: string;
  backgroundImage?: string;
  tituloPlaylist?: string;
  descricaoPlaylist?: string;
}
/*Comentando pra atualizar*/

const Playlist_component = () => {
  const [playlists, exibirPlaylists] = useState<JSX.Element[]>([]);
  const [pageDesignation, setPageDesignation] = useState<string | null>(null);

  const dadosMeditar: Playlist[] = [
    {
      tituloPlaylist: 'Medite com uma frequência especifica',
      descricaoPlaylist: 'Direcione sua meditação a uma das inumeras vantagens que as frequências podem causar',
      backgroundImage: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/06/Design-sem-nome-1.webp',
      link: 'https://www.youtube.com/watch?v=ifn-8csOJvc&list=PL94ZqO5avRSFAwDClModI8vZbDgr1zml9'
    },
    {
      tituloPlaylist: 'Medite ouvindo uma música',
      descricaoPlaylist: 'Musicas calmas e relaxantes para te ajudar a meditar',
      backgroundImage: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/06/Design-sem-nome-1.webp',
      link: 'https://www.youtube.com/watch?v=2RTZNLL0wss&list=PLQkQfzsIUwRYxXaRaMJ2C8z3w768gfi-j'
    }, {
      tituloPlaylist: 'Medite ouvindo som ambiente',
      descricaoPlaylist: 'Sons de natureza podem te ajudar a relaxar e se concentrar',
      backgroundImage: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/06/Design-sem-nome-1.webp',
      link: 'https://www.youtube.com/watch?v=YqvHUsmJunk&list=PLCtw0-YQZqWfgWfYk5SpJJAgfiWIPHYXr'
    },

  ];

  const dadosExercicios: Playlist[] = [
    {
      tituloPlaylist: 'Treinos aeróbicos',
      descricaoPlaylist: 'Treinos voltados a perda de peso e melhoria da mobilidade funcional do corpo, possuem intensidade moderada',
      backgroundImage: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/06/Design-sem-nome-1.webp',
      link: 'https://www.youtube.com/watch?v=i3-WS7YJ2O8&pp=ygUVZXhlcmPDrWNpb3MgYWVyb2JpY29z'
    }
  ];

  const dadosConcentrar: Playlist[] = [
    {
      link: 'https://www.youtube.com/watch?v=vweEQFJA3w8&pp=ygUMcnVpZG8gYnJhbmNv',
      backgroundImage: 'https://intranet.lcmconstrucao.com.br/wp-content/uploads/2024/06/Design-sem-nome-1.webp',
      tituloPlaylist: 'Ruido branco para concentração',
      descricaoPlaylist: 'Indicado por médicos e cientistas, ajuda na concentração e retenção de informação. É altamente recomendado até pra bebês!'
    }
  ];

  useEffect(() => {
    const url = window.location.href;
    let dadosPlaylist: Playlist[] = [];

    if (url.includes('playlist_concentrar')) {
      setPageDesignation('concentracao');
      dadosPlaylist = dadosConcentrar;
    }
    if (url.includes('playlist_meditar')) {
      setPageDesignation('meditacao');
      dadosPlaylist = dadosMeditar;
    }
    if (url.includes('exercicios')) {
      setPageDesignation('exercicios');
      dadosPlaylist = dadosExercicios;
    }

    const videos_playlist: JSX.Element[] = dadosPlaylist.map((dado) => (
      <Container_playlist
        key={dado.link}
        backgroundImage={dado.backgroundImage}
        onClick={() => {
          window.location.href = dado.link!;
        }}
      >
        <h2>{dado.tituloPlaylist}</h2>
        <span>{dado.descricaoPlaylist}</span>
      </Container_playlist>
    ));

    exibirPlaylists(videos_playlist);
  }, []);

  return (
    <>
      <HeaderPlaylists>
        {pageDesignation === 'concentracao' && <h2>Escolha uma playlist para te ajudar a se concentrar</h2>}
        {pageDesignation === 'meditacao' && <h2>Escolha uma playlist para meditar e relaxar</h2>}
        {pageDesignation === 'exercicios' && <h2>Escolha uma playlist treinar e direcionar seus resultados</h2>}
      </HeaderPlaylists>
      <Container_playlists>
        {playlists}
      </Container_playlists>
    </>
  );
};

export default Playlist_component;
