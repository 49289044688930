import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_nutricao from "../../components/Sidebar/sidebar.nutricao.component";
import Camera_nutricao from "../../components/Cam nutricao/cam.component";

const Pagina_publicar_nutri = () => {
  return (<>
    <Camera_nutricao></Camera_nutricao>
    <Navbar active="nutricao"></Navbar>
    <Sidebar_nutricao></Sidebar_nutricao>
  </>)
}
export default Pagina_publicar_nutri
