import React, { useState, useEffect } from "react";
import { Corpo_confirmacao, Loader, Button_exit } from "./confirmacao.style"
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
interface ConteudoProps {
  tipo: string,
  texto: string
}
const Tela_confirmacao: React.FC<ConteudoProps> = ({ tipo, texto }) => {
  const [internalTipo, setInternalTipo] = useState(tipo)
  const [internalTexto, setInternalTexto] = useState(texto)

  useEffect(() => {
    setInternalTipo(tipo)
    setInternalTexto(texto)
    //console.log(tipo, texto)
  }, [tipo, texto])

  const returnToStandby = () => {
    setInternalTipo('standby')
  }
  return (<>
    {internalTipo !== 'standby' && <Corpo_confirmacao>
      {internalTipo !== 'loading' && <Button_exit onClick={returnToStandby}><IoClose /></Button_exit>}
      {internalTipo == 'success' && <FaRegCheckCircle color="white" size={50} />}
      {internalTipo == 'loading' && <Loader className="loader"></Loader>}
      {internalTipo == 'failed' && <IoIosCloseCircleOutline color="white" size={80} />}
      <span>{internalTexto}</span>
    </Corpo_confirmacao>}
  </>)
}
export default Tela_confirmacao
