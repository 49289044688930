import { Erro_box } from './error_component.style';
import React from 'react';
const Componente_erro: React.FC = () => {
  return (
    <Erro_box>
      <h1>Ocorreu um erro!</h1>
      <button onClick={() => window.location.href = 'https://localhost:3000/'}>Voltar a página inicial</button>
    </Erro_box>
  );
};

export default Componente_erro;
