import React, { ReactNode } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'

const round = (num: number) =>
  num
    .toFixed(7)
    .replace(/(\.[0-9]+?)0+$/, '$1')
    .replace(/\.0$/, '')
const rem = (px: number) => `${round(px / 16)}rem`

export const theme: DefaultTheme = {
  colors: {
    white: '#FFFFFF', black: '#312b33', black_translucid: 'rgba(0,0,0,0.8)',
    red: '#E03131', orange: '#ff8b33', yellow: '#FCC419',
    green: '#59b159', teal: '#83e35a', light_green: '#93df75',
    cyan: '#45BCC3', blue: '#006472', indigo: '#4263Eb',
    purple: '#9B1E5F', darkpurple: '#6A4A74', darkpink: '#BC8F8F',
    pink: '#ac3cc9', gray100: '#FAFAFA', gray200: '#E9ECEF',
    gray300: '#DEE2E6', gray400: '#CED4DA', gray500: '#A4ACB4',
    gray600: '#64666B', gray700: '#424449', gray800: '#1D1E21',
    gray900: '#141518', primary: '#93CA3C', secondary: '#75787b',
    cor_paleta_1: '#45BCC3', cor_paleta_2: '#9B1E5F', cor_paleta_3: '#9bc93c',
    cor_paleta_4: '#6a4a74', cor_paleta_5: '#bc8f8f', cor_paleta_6: '#ffab70',
    cor_paleta_7: '#B0CDDF'
  },
  fontFamily: {
    heading: ['IBM Plex Sans', 'sans-serif'].join(','),
    sans: ['Open Sans', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    mono: ['IBM Plex Mono', 'serif'].join(','),
  },
  fontSize: {
    xs: rem(10),
    sm: rem(12),
    md: rem(14),
    base: rem(16),
    lg: rem(18),
    xl: rem(20),
    '2xl': rem(24),
    '3xl': rem(32),
    '4xl': rem(40),
    '5xl': rem(64),
  },
  breakpoints: {
    xxs: '375px',
    xs: '573px',
    sm: '768px',
    md: '968px',
    lg: '1032px',
    xl: '1200px',
  }
}

interface ThemeProps {
  children: ReactNode
}

export function Theme({ children }: ThemeProps) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
