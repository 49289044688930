import Container_saude from "../../components/Sections/container_saude.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_saude from "../../components/Sidebar/sidebar.saude.component";

const Pagina_saude = () => {
  return (<>
    <Container_saude></Container_saude>
    <Sidebar_saude></Sidebar_saude>
    <Navbar active="saude"></Navbar>
  </>
  )
}

export default Pagina_saude
