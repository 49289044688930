import styled from 'styled-components'

export const Corpo_componente = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  > input {
    text-align: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 0.1rem solid ${(props) => props.theme.colors.gray300};
  }
`

export const Expose_results = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  > * {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
    overflow: hidden;
  }
`

export const Cont_perfil = styled.div`
  display: flex;
  width: 70%;
  align-self: center;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem;
  border-radius: 0.5rem;
  > &.selected {
    background-color: ${(props) => props.theme.colors.primary};
  }
`

export const Imagem_perfil = styled.img`
  width: 3rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
`

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  border: 0.1rem solid ${(props) => props.theme.colors.white};
`
