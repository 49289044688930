import styled from 'styled-components'
export const HeaderPlaylists = styled.div`
  width: 75vw;
  height: fit-content;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  margin-left: 4rem;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
`
export const BotaoBack = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
`
interface container_props {
  backgroundImage?: string
}

export const Container_playlists = styled.div`
  width: 90%;
  margin-top: 1rem;
  margin-left: 3rem;
  margin-bottom: 8rem;
  height: fit-content;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-rows: 1fr;
  gap: 1rem;
  align-items: center;
`

export const Container_playlist = styled.div<container_props>`
  width: 80%;
  border-radius: 0.5rem;
  height: fit-content;
  padding: 1rem;
  border: 0.2rem solid ${(props) => props.theme.colors.gray400};
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  position: relative;
  z-index: 1;
  cursor: pointer;
  h2,
  span {
    color: ${(props) => props.theme.colors.white};
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 3;
  }

  &::before {
    width: 100%;
    height: 100%;
    background: rgb(237, 127, 53);
    background: linear-gradient(225deg, rgba(237, 127, 53, 0) 0%, rgba(131, 227, 90, 1) 100%);
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 0.5rem;
    z-index: 2;
  }
`
