import styled from 'styled-components'
interface eventoProps {
  backgroundImage?: string
}

export const Header_eventos = styled.div`
  margin-top: 2rem;
  margin-left: 2.5rem;
  border-radius: 0.5rem;
  width: 75%;
  background-color: ${(props) => props.theme.colors.primary};
  align-self: center;
  padding: 1rem 2rem;
  text-align: center;
`
export const Container_eventos = styled.div`
  width: 70%;
  margin-top: 1rem;
  margin-left: 4rem;
  margin-bottom: 8rem;
  height: fit-content;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-rows: 1fr;
  gap: 1rem;
  align-items: center;
`

export const Container_evento = styled.div<eventoProps>`
  width: 85%;
  padding: 1rem;
  border: 0.2rem solid ${(props) => props.theme.colors.gray400};
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  position: relative;
  z-index: 1;
  cursor: pointer;

  & > .content {
    position: relative;
    z-index: 3;
  }
  & > .disclaimer_evento {
    color: ${(props) => props.theme.colors.white};
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &::before {
    width: 100%;
    height: 100%;
    background: rgb(191, 191, 191);
    background: -moz-linear-gradient(
      220deg,
      rgba(191, 191, 191, 0.6881127450980392) 30%,
      rgba(147, 202, 60, 0.80015756302521) 100%
    );
    background: -webkit-linear-gradient(
      220deg,
      rgba(191, 191, 191, 0.6881127450980392) 30%,
      rgba(147, 202, 60, 0.80015756302521) 100%
    );
    background: linear-gradient(
      220deg,
      rgba(191, 191, 191, 0.6881127450980392) 30%,
      rgba(147, 202, 60, 0.80015756302521) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bfbfbf",endColorstr="#93ca3c",GradientType=1);
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
  }
`
export const Date_evento = styled.h2`
  font-size: ${(props) => props.theme.fontSize['md']};
  color: ${(props) => props.theme.colors.white};
  position: absolute !important;
  text-align: center;
  top: 16%;
  left: 3rem;
  width: 2rem;
`
export const Button_CriarNovo = styled.button`
  background: ${(props) => props.theme.colors.primary};
  background: linear-gradient(
    225deg,
    ${(props) => props.theme.colors.primary} 0%,
    rgba(131, 227, 90, 1) 100%
  );
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 1rem;
  border-radius: 50%;
  position: fixed;
  right: 4rem;
  bottom: 8rem;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  :hover {
    transform: scale(1.2);
  }
`

export const ElementoLi = styled.li`
  background-color: ${(props) => props.theme.colors.secondary};
  list-style: none;
  padding: 0.5rem;
`
