import { Container, Home_body, Home_title, Home_button, Logo_expository } from "./home.style";
import { useNavigate } from "react-router-dom";
import logo_app from '../Assets/Images/escrita.webp'
import logo_lcm from '../Assets/Images/logo_lcm.webp'

const Home_component = () => {
  const navigate = useNavigate()
  const toLogin = () => {
    navigate('/login')
  }
  const toRegister = () => {
    navigate('/register')
  }
  const toSSO = () => {
    window.location.href = 'https://intranet.lcmconstrucao.com.br/sso-login-cuidado-integral'
  }
  return (<>
    <Container>
      <Home_body>
        <Home_title>Sejam bem vindos ao</Home_title>
        <Logo_expository><img src={logo_app} alt="logo_app" />
          <img src={logo_lcm} alt="logo_lcm" /></Logo_expository>
        <Home_button className="register" onClick={toRegister}>Cadastre-se</Home_button>
        <Home_button className="login" onClick={toLogin}>Faça login</Home_button>
        <Home_button className="intranet" onClick={toSSO}>Ou entre diretamente pela Intranet</Home_button>
      </Home_body>
    </Container></>)
}

export default Home_component
