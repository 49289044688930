import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head_notificacoes, Body_notificacoes, Imagem_perfil } from "./notify.style";
import { getNotificacoes, obterDadosSociais, obterPostPorComment } from '../logics/def_functions';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';

interface Interaction {
  _id: string,
  momento_interacao: string,
  tipo_interacao: 'like' | 'comment' | 'follow';
  tipo_objeto?: 'post' | 'comment';
  objeto_interagido?: string;
  pessoas_interacao: string[],
  info?: any
}

interface Notificacao {
  _id: string,
  momento_interacao: string,
  tipo_interacao: 'like' | 'comment' | 'follow';
  tipo_objeto?: 'post' | 'comment';
  objeto_interagido?: string;
  pessoas_interacao: string[],
  prof_pic: string,
  user_full_name: string,
}

const Notify_component = () => {
  const [notificacoes, defNotificacoes] = useState<Notificacao[]>([]);
  const [estadoGeral, setEstadoGeral] = useState("loading");
  const [mensagemGeral, setMensagemGeral] = useState('Carregando...');
  const [pagina, setPagina] = useState(1)

  const navigate = useNavigate();

  const alvo = (target: string | undefined) => {
    if (target === 'post') {
      return 'sua publicação.';
    } else if (target === 'comment') {
      return 'seu comentário.';
    } else {
      return '';
    }
  };

  const acao = (type: string) => {
    if (type === 'like') {
      return ' curtiu ';
    } else if (type === 'comment') {
      return ' comentou ';
    } else if (type === 'follow') {
      return ' seguiu você.';
    } else {
      return '';
    }
  };

  const retornarUmSocial = useCallback(async (id_usuario: string) => {
    const usuario = await obterDadosSociais({ metodo: 'others', id_usuario: id_usuario });
    if (usuario) {
      return usuario;
    }
  }, []);

  const popuparNotificacoes = () => {
    if (notificacoes.length > 0) {
      return notificacoes.map((element, index) => (
        <li key={index} onClick={async () => {
          if (element.tipo_interacao === 'follow') {
            const dadosSeguidor = await obterDadosSociais({ metodo: 'others', id_usuario: element.objeto_interagido })
            navigate(`/social/perfil?${dadosSeguidor.info.user_name}`)
          }
          if (element.tipo_objeto === 'post') {
            navigate(`/social/notificacoes/post?${element.objeto_interagido}`);
          }

          if (element.tipo_objeto === 'comment') {
            if (element.objeto_interagido) {
              const post_obtido = await obterPostPorComment(element.objeto_interagido);
              if (post_obtido.status === 200) {
                navigate(`/social/notificacoes/post?${post_obtido.info._id}`);
              }
            }
          }
        }}>
          <Imagem_perfil src={element.prof_pic} alt="Profile" />
          <div>
            <span>{`${element.user_full_name.split(" ")[0]} ${acao(element.tipo_interacao)} ${alvo(element.tipo_objeto)}`}</span>
          </div>
        </li>
      ));
    }
  };

  const obterNotificacoes = useCallback(async () => {
    const arrayDeNotificacoes = await getNotificacoes(pagina);
    if (arrayDeNotificacoes.status === 200) {
      const objetosAtualizados: Notificacao[] = [];
      await Promise.all(
        arrayDeNotificacoes?.info.map(async (element: Interaction) => {
          const usuario = await obterDadosSociais({ metodo: 'others', id_usuario: element.pessoas_interacao[0] });
          if (usuario) {
            const obj: Notificacao = {
              _id: element?._id,
              momento_interacao: element?.momento_interacao,
              tipo_interacao: element?.tipo_interacao,
              tipo_objeto: element?.tipo_objeto,
              objeto_interagido: element?.objeto_interagido,
              pessoas_interacao: element?.pessoas_interacao,
              prof_pic: usuario.info.prof_pic,
              user_full_name: usuario?.info.user_full_name,
            };
            if (obj) {
              objetosAtualizados.push(obj);
            }
          }
        })
      );
      defNotificacoes(prevNotificacoes => [...prevNotificacoes, ...objetosAtualizados]);
    }
  }, [defNotificacoes]);

  useEffect(() => {
    obterNotificacoes().then(() => {
      setEstadoGeral('standby');
      setMensagemGeral('');
    });
  }, [obterNotificacoes]);

  return (
    <>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Head_notificacoes>
        <h2>Notificações</h2>
      </Head_notificacoes>
      <Body_notificacoes>
        {notificacoes.length > 0 ? popuparNotificacoes() : <span>Sem notificações</span>}
        {notificacoes.length > 0 && <button onClick={() => setPagina(pagina + 1)}>Ver mais</button>}
      </Body_notificacoes>
    </>
  );
};

export default Notify_component;
