import { useLocation } from "react-router-dom";
import { useEffect, useCallback, useState } from "react";
import { Banner_evento, Detalhes_evento_div, Acoes_evento, Button } from "./event_details.component.style"
import { FaRegCalendarCheck, FaCalendarPlus } from "react-icons/fa";
import { getDetalhesEvento, obterDadosSociais, marcarPresenca } from "../logics/def_functions";
import { timestampToData } from '../logics/functions';
import Tela_confirmacao from "../Tela de confirmacao/confirmacao.component";

interface dadosEvento {
  id: string,
  creator: string,
  image: string,
  start: string,
  end: string,
  title: string,
  summary: string,
  link: string
  location: string
}
interface dados {
  id_evento: string,
  tipo_evento: string
}
const Detalhes_evento = () => {
  const [estadoGeral, setEstadoGeral] = useState("standby")
  const [mensagemGeral, setMensagemGeral] = useState('')
  const [evento, setEvento] = useState<dadosEvento | null>(null)
  const location = useLocation()
  const tipo_evento = location.pathname.split("/")[2]
  const id_evento = location.search.split("?")[1].split('id=')[1]
  const fetchDados = useCallback(async () => {
    const detalhesDoEvento = await getDetalhesEvento(id_evento, tipo_evento)
    const criadorInfo = await obterDadosSociais({ email_usuario: String(detalhesDoEvento.info.creator).replace("organizador:", ''), metodo: 'others' })
    setEvento({
      creator: criadorInfo?.info?.user_full_name,
      id: detalhesDoEvento.info.id,
      start: detalhesDoEvento.info.start,
      end: detalhesDoEvento.info.end,
      location: detalhesDoEvento.info.location,
      summary: detalhesDoEvento.info.summary,
      image: detalhesDoEvento.info.image,
      link: detalhesDoEvento.info.link,
      title: detalhesDoEvento.info.title
    })
  }, [])

  const toEvento = () => {
    window.location.href = evento?.link || ''
  }
  const presenca = useCallback(async () => {
    const acao = await marcarPresenca({ id_evento: id_evento, tipo_evento: tipo_evento })
    if (acao.status == 200) {
      setEstadoGeral('success')
      setMensagemGeral('Confirmado!')
    }
  }, [])
  useEffect(() => {
    fetchDados()

  }, [])
  return (<>
    <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    <Banner_evento backgroundImage={String(evento?.image).replace("imagem:", '')}></Banner_evento>
    <Detalhes_evento_div>
      <div className="title">
        <h2>{evento?.title}</h2>
      </div>
      <div className="disclaimer">
        <span>{evento?.summary}</span>
      </div>
      <div className="location">
        <span>Local: {String(evento?.location).replace("local:", '')}</span>
        <span>Data:{timestampToData(evento?.start)} </span>
      </div>
      <div className="creator">
        <span>Criado por: {evento?.creator}</span>
      </div>
    </Detalhes_evento_div>
    <Acoes_evento>
      <Button onClick={presenca}>
        <FaRegCalendarCheck color="white" size={40}></FaRegCalendarCheck>
        <span>Confirmar presença</span>
      </Button>
      <Button onClick={toEvento}>
        <FaCalendarPlus color="white" size={40}></FaCalendarPlus>
        <span>Ver na agenda</span>
      </Button></Acoes_evento>
  </>)
}
export default Detalhes_evento
