import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Mini_perfil, Prof_pic, Username, Last_active } from './profile_mini.component.style'

interface ProfileProps {
  posts: string[],
  _id: string,
  user_name: string,
  user_full_name: string,
  user_email: string,
  prof_pic: string,
  bio: string,
  followers: string[],
  following: string[]
}

const Mini_Perfil: React.FC<{ perfil: ProfileProps | null }> = ({ perfil }) => {
  const navigate = useNavigate()
  const toPerfil = () => {
    if (perfil?.user_name) {
      navigate(`/social/perfil?${encodeURIComponent(perfil?.user_name)}`)
    }
  }
  return (
    <Mini_perfil onClick={toPerfil}>
      <Prof_pic src={perfil?.prof_pic} alt="Imagem_perfil_mini" />
      <Username>{perfil?.user_name}</Username>
      <Last_active></Last_active>
    </Mini_perfil>
  )
}
export default Mini_Perfil;
