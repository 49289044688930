import styled from 'styled-components'

export const Navbar_comp = styled.div`
  width: 100svw;
  padding: 1rem;
  height: fit-content;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(147, 202, 60, 1) 70%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: -1rem;
  bottom: 0;
  z-index: 26;
`

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`

export const Button_navigation = styled.div`
  background-color: ${(props) => props.theme.colors.gray500};
  padding: 1rem;
  border-radius: 1rem;
  border: 0.15rem solid ${(props) => props.theme.colors.gray100};
  transition: all 0.5s;

  &:hover {
    border: 0.3rem solid ${(props) => props.theme.colors.white};
    transform: scale(1.2);
  }
  &.clicked {
    border: 0.3rem solid ${(props) => props.theme.colors.white};
    transform: scale(1.2);
  }
  &.active {
    border: 0.3rem solid ${(props) => props.theme.colors.white};
    transform: scale(1.2);
  }
  &.saude {
    background-color: ${(props) => props.theme.colors.cor_paleta_3} !important;
  }
  &.exercicios {
    background-color: ${(props) => props.theme.colors.cor_paleta_2} !important;
  }
  &.social {
    background-color: ${(props) => props.theme.colors.cor_paleta_4} !important;
  }
  &.meditar {
    background-color: ${(props) => props.theme.colors.cor_paleta_5} !important;
  }
  &.nutricao {
    background-color: ${(props) => props.theme.colors.cor_paleta_1} !important;
  }
  > * {
    > * {
      color: ${(props) => props.theme.colors.white};
    }
  }
`
