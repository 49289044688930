import styled from 'styled-components'
export const Title_lista = styled.div`
  margin-top: 3rem;
  width: 75vw;
  margin-left: 2.5rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.gray200};
  padding: 0 1rem;
  border-radius: 0.3rem;
  text-align: center;
`
export const ListaDesafios = styled.ul`
  width: 75vw;
  border-radius: 0.2rem;
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  margin-left: 3rem;
  padding: 0.5rem;
  list-style: none;
  > * {
    width: 100%;
    display: flex;
    justify-content: center;
    > * {
      width: 100%;
      color: ${(props) => props.theme.colors.secondary};
      background-color: ${(props) => props.theme.colors.gray200};
      padding: 0.2rem;
      border-radius: 0.2rem;
    }
  }
`
export const Challenge_list = styled.ul`
  width: 85vw;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center !important;
  gap: 1rem;
  list-style: none;
  > * {
    padding: 1rem;
    width: 65vw;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &.plataforma {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
      border-radius: 0.3rem;
      &:hover {
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.primary};
        border: 0.1rem solid ${(props) => props.theme.colors.primary};
        transform: scale(1.01);
      }
    }
    &.usuarios {
      background-color: ${(props) => props.theme.colors.blue};
      color: ${(props) => props.theme.colors.white};
      border-radius: 0.3rem;
      &:hover {
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.blue};
        border: 0.1rem solid ${(props) => props.theme.colors.blue};
        transform: scale(1.01);
      }
    }
  }
  &.ranking {
    width: 100%;
    height: fit-content;
    padding: 0 !important;
    gap: 0.2rem !important;
    > h2 {
      width: 100%;
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 0.3rem;
    }
    > li {
      padding: 0.5rem 0.5rem;
      background-color: ${(props) => props.theme.colors.gray300};
      color: ${(props) => props.theme.colors.black};
      text-align: start;
    }
  }
`

export const CriarDesafio = styled.div`
  width: 2rem;
  aspect-ratio: 1/1;
  background-color: ${(props) => props.theme.colors.primary};
  bottom: 8rem;
  right: 2rem;
  position: fixed;
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
    background-color: ${(props) => props.theme.colors.primary};
  }
  &.ranking {
    right: 6.5rem !important;
  }
  &.profile {
    right: 11rem !important;
  }
`

interface OpcaoPlus {
  description?: string
}

export const Opcao = styled.option<OpcaoPlus>`
  width: 60%;
  padding: 0.2rem;
  border-radius: 0.5rem;
`
export const FloatingBox = styled.div`
  width: 80%;
  height: fit-content;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  bottom: 6rem;
`
export const Cont_form_evento = styled.div`
  width: 90vw;
  height: 100vh;
  z-index: 5;
  position: fixed;
  top: 0rem;
  border-radius: 0.5rem;
  left: 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 80%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.2rem;
    &.container_decision {
      display: flex;
      flex-direction: row !important;
      > * {
        width: 40%;
        background-color: ${(props) => props.theme.colors.primary} !important;
        border-radius: 0.5rem;
        border: 0.1rem solid ${(props) => props.theme.colors.white};
      }
    }
    > input {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
    }
    &.perfil {
      background-color: ${(props) => props.theme.colors.secondary} !important;
      padding: 0 !important;
      width: 90% !important;
      border: 1px solid white;
      border-radius: 0.5rem;
      gap: 0.2rem !important;
      > ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        padding: 0 !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
        > * {
          width: 100%;
          padding: 0.5rem;
          background-color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`
export const Normal_button = styled.div`
  padding: 0.5rem;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &.create {
    background-color: ${(props) => props.theme.colors.primary};
    border: 0.1rem solid ${(props) => props.theme.colors.white};
    border-radius: 0.3rem;
    padding: 0.5rem 1rem !important;
    &:hover {
      transform: scale(1.01);
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.primary};
    }
  }
  &.close {
    width: 1rem;
    aspect-ratio: 1/1;
    background-color: ${(props) => props.theme.colors.orange};
    position: fixed;
    top: 1.5rem;
    right: 0.5rem;
    border: 0.1rem solid ${(props) => props.theme.colors.white};
    &:hover {
      background-color: ${(props) => props.theme.colors.white};
      border: 0.1rem solid ${(props) => props.theme.colors.orange};
      transform: scale(1.01);
      > * {
        color: ${(props) => props.theme.colors.orange};
      }
    }
    > * {
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.accept {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.colors.white};
  }
  &.challenge_button {
    background-color: ${(props) => props.theme.colors.blue};
    padding: 1rem;
  }
`
