import Navbar from "../../components/Navbar/navbar.component";
import Corp_events from "../../components/Eventos corporativos/corp_events.component";
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component";
import ReturnButton from "../../components/Botao retornar/return.component";
const Pagina_eventos_corporativos = () => {
  return (
    <>
      <ReturnButton></ReturnButton>
      <Corp_events></Corp_events>
      <Sidebar_exercicios></Sidebar_exercicios>
      <Navbar active="exercicios"></Navbar>
    </>)
}

export default Pagina_eventos_corporativos
