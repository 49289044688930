import { useNavigate, useLocation } from "react-router-dom"
import { CorpoBotao } from "./return.style"
import { IoIosArrowBack } from "react-icons/io";
const ReturnButton = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const voltarUma = () => {
    const dir = location.pathname.split("/")
    const aRemover = dir.slice(0, -1)
    dir.join('/')
    navigate(`${aRemover.join('/')}`)
  }
  return (<>
    <CorpoBotao onClick={voltarUma}><IoIosArrowBack></IoIosArrowBack></CorpoBotao></>)
}
export default ReturnButton
