import styled from 'styled-components'

export const Container = styled.div`
  width: 80vw;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.white};
  padding: 1rem;
  position: relative;
  left: 1.5rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
export const InterUser = styled.div`
  width: 100%;
  height: 3rem;
  border: 0.1rem solid ${(props) => props.theme.colors.gray400};
  display: flex;
  justify-content: space-evenly;
  > * {
    width: 6rem;
    height: 3rem;
    background-color: ${(props) => props.theme.colors.cyan};
    color: ${(props) => props.theme.colors.gray100};
    border: none;
    border-radius: 0.4rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
      color: ${(props) => props.theme.colors.cyan};
      background-color: ${(props) => props.theme.colors.gray200};
    }
  }
`
export const Main_perfil = styled.div`
  width: 85%;
  height: fit-content;
  align-self: center;
  max-width: ${(props) => props.theme.breakpoints.sm};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.gray400};
  justify-content: space-evenly;
  padding: 1rem;
  border-radius: 2rem;
  gap: 1rem;
  align-self: flex-start;
  transition: all 0.3s;
`
export const Prof_pic = styled.img`
  background-color: ${(props) => props.theme.colors.gray500};
  border-radius: 50%;
  height: 8rem;
  width: 8rem;
`
export const Username = styled.span`
  color: ${(props) => props.theme.colors.gray900};
  font-size: 1.1rem;
  font-weight: bold;
`
export const Bios = styled.span`
  font-family: ${(props) => props.theme.fontFamily.mono};
  color: ${(props) => props.theme.colors.gray800};
`
export const Actions = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  > * {
    border-radius: 1rem;
    background-color: ${(props) => props.theme.colors.blue};
    border: transparent;
    color: ${(props) => props.theme.colors.white};
    padding: 0.25rem 1rem;
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.05);
      color: ${(props) => props.theme.colors.blue};
      background-color: ${(props) => props.theme.colors.white};
      border: 0.1rem solid ${(props) => props.theme.colors.blue};
    }
  }
`
export const FollowersCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  color: ${(props) => props.theme.colors.black};
  > * {
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.colors.gray100};
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`
export const Button_edit = styled.button`
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.colors.primary} solid 0.1rem;
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.white};
  }
`
export const PopupFollowers = styled.div`
  position: fixed;
  top: calc(50% - 2.5rem);
  left: calc(50% + 1rem);
  transform: translate(-50%, -50%) !important;
  width: 65svw;
  height: 80svh;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray300};
  display: flex;
  flex-direction: column;
  align-items: center !important;
  text-align: center !important;
  > span {
    color: ${(props) => props.theme.colors.gray700};
  }
`
export const ButtonExitPopupFollowers = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const Lista_followers = styled.ul`
  align-self: center;
  width: 90%;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 0.5rem;
  border-radius: 0.5rem !important;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  > li {
    padding: 0.5rem;
    border-radius: 0.5rem !important;
    background-color: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.gray900};
  }
`
export const Profile_follower = styled.li`
  width: 90%;
  background-color: ${(props) => props.theme.colors.gray200};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colors.gray700};
  > img {
    width: 4rem;
    aspect-ratio: 4/4;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid;
  }
  > button {
    width: 3rem !important;
    background-color: ${(props) => props.theme.colors.cyan};
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSize.sm};
    border-radius: 0.5rem;
    border: none;
  }
  > span {
    font-size: ${(props) => props.theme.fontSize.sm};
  }
`
