import styled from 'styled-components'

export const Login_body = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-width: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const Form = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const Button = styled.button`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 1rem;
  border: transparent;
  color: ${(props) => props.theme.colors.white};
  transition: all 0.5s;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  &:hover {
    background-color: ${(props) => props.theme.colors.white} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const Subdiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 90%;
  height: 70%;
`

export const ClosePopup = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: all 0.5s;
  > * {
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    transform: scale(1.5);
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    transform: scale(1.2);
    > * {
      color: ${(props) => props.theme.colors.white};
    }
  }
`

export const Input = styled.input`
  border: 0.2rem solid ${(props) => props.theme.colors.primary};
  background-color: white;
  width: 90%;
  border-radius: 1rem;
  padding: 1rem;
`

export const CancelRecovery = styled.button`
  position: fixed;
  top: 1rem;
  left: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.secondary};
`
