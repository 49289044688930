import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { Challenge_list, Title_lista, CriarDesafio, Cont_form_evento, Normal_button, Opcao, ListaDesafios, FloatingBox } from "./desafio_style";
import { FaMedal } from "react-icons/fa";
import { TiPlusOutline } from "react-icons/ti";
import { CgProfile } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { FaRegSquareCheck, FaRankingStar } from "react-icons/fa6";
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';
import CameraModule from "../Camera module/cam.component";
import SearchComponent from "../Campo de pesquisa/pesquisa.component";
import { getDesafios, getDesafiosPredefinidos, getRankingDesafios, fazerDesafio, responderDesafio, validarDesafio } from "../logics/def_functions";

const Desafio_component = () => {
  interface Resultado {
    user_name: string,
    user_email: string,
    user_full_name: string
  }

  interface desafioProps {
    desafiante: string,
    desafiado: string,
    _id: string,
    nome_desafio: string,
    descricao_desafio: string,
    pontuacao: number,
    data_desafio: string,
    validado: boolean,
    resposta?: { foto_resposta: string, mensagem_resposta: string }
  }

  interface desafiosBase {
    title: string,
    description: string,
    points: number,
    _id?: string
  }

  interface usuariosRanking {
    _id: string,
    user_name: string,
    points: number
  }

  const titulo_desafio = useRef<HTMLInputElement | null>(null);
  const descricao_desafio = useRef<HTMLInputElement | null>(null);
  const [desafioSelecionado, setDesafioSelecionado] = useState('')
  const [desafio_predef, setDesafioPredef] = useState<desafiosBase | undefined>(undefined);
  const [state, switchState] = useState('standby');
  const [processState, setProcessState] = useState("loading");
  const [desafioInfo, setDesafio] = useState<desafioProps | null>(null);
  const [desafiosPlataforma, setDesafiosPlataforma] = useState<desafioProps[]>([]);
  const [desafiosCumpridos, setDesafiosCumpridos] = useState<desafioProps[]>([]);
  const [desafiosCriados, setDesafiosCriados] = useState<desafioProps[]>([]);
  const [desafiosUsuario, setDesafiosUsuario] = useState<desafioProps[]>([]);
  const [answering, startAnswering] = useState(false);
  const [desafiosBase, setDesafiosBase] = useState<desafiosBase[]>([]);
  const [usuariosRanking, setUsuariosRanking] = useState<usuariosRanking[]>([]);
  const [selectedResult, setSelectedResult] = useState<Resultado | null>(null);
  const [mensagemConfirmacao, defMensagemConfirmacao] = useState('')

  const handleResultClick = (result: Resultado) => {
    setSelectedResult(result);
  };

  const StartResponse = () => {
    if (desafioInfo) {
      startAnswering(true);
    }
  };

  const handleAprovacao = useCallback(async () => {
    ////console.log("Mostre-me o id do desafio: ", desafioInfo?._id)
    const aprovacao = await validarDesafio(desafioSelecionado)
    //console.log(aprovacao)
    if (aprovacao.status == 200 || aprovacao.status == 204) {
      setProcessState('success')
      defMensagemConfirmacao('Sucesso na criação do desafio')
    } else {
      setProcessState('failed')
      defMensagemConfirmacao('Algo deu errado, tente novamente')
    }

  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const nome_desafio = event.target.selectedOptions[0].value;
    if (nome_desafio === 'selecione') {
      setDesafioPredef(undefined);
    } else {
      const desafioEscolhido = desafiosBase.find((element) => element.title === nome_desafio);
      setDesafioPredef(desafioEscolhido);
    }
  };

  const handleCreate = useCallback(async () => {
    const usuario = selectedResult?.user_email;
    const titulo = titulo_desafio.current?.value;
    const descricao = descricao_desafio.current?.value;
    if (desafio_predef) {
      //console.log(usuario, desafio_predef);
      const dados = {
        nome_desafio: desafio_predef.title,
        desafiado: usuario,
        descricao: desafio_predef.description,
        pontuacao: desafio_predef.points
      }
      const desafioFeito = await fazerDesafio(dados)
      if (desafioFeito.status == 200) {
        setProcessState('success')
        defMensagemConfirmacao('Sucesso na criação do desafio')
      } else {
        setProcessState('failed')
        defMensagemConfirmacao('Algo deu errado, tente novamente')
      }
    } else {
      const dados = {
        nome_desafio: titulo,
        desafiado: usuario,
        descricao: descricao,
        pontuacao: 0
      }
      const desafioFeito = await fazerDesafio(dados)
      if (desafioFeito.status == 200) {
        setProcessState('success')
        defMensagemConfirmacao('Sucesso na criação do desafio')
      } else {
        setProcessState('failed')
        defMensagemConfirmacao('Algo deu errado, tente novamente')
      }
    }
  }, [desafio_predef, selectedResult]);

  const rankingUsers = useMemo(() => {
    return usuariosRanking.slice(0, usuariosRanking.length).map((user, i) => {
      let medalColor;
      if (i === 0) medalColor = "yellow";
      else if (i === 1) medalColor = "gray";
      else if (i === 2) medalColor = "orange";
      return (
        <li key={user._id}>
          {medalColor && <FaMedal color={medalColor} />} {i + 1}º {user.user_name.split(' ')[0]} - {user.points} pontos.
        </li>
      );
    });
  }, [usuariosRanking]);

  const ShowDesafiosCriados = useCallback((tipoDesafio: string): JSX.Element[] => {
    const desafios = tipoDesafio == 'cumpridos' ? desafiosCumpridos : desafiosCriados;
    return desafios.map((desafio) => (
      <li key={desafio._id} className={tipoDesafio} onClick={() => detalhesDesafio(desafio._id)}>
        {desafio.nome_desafio} - {desafio.pontuacao} pts.
      </li>
    ));
  }, [desafiosCriados, desafiosCumpridos]);

  const ShowDesafios = useCallback((tipoDesafio: string): JSX.Element[] => {
    const desafios = tipoDesafio === 'plataforma' ? desafiosPlataforma : desafiosUsuario;
    return desafios.map((desafio) => (
      <li key={desafio._id} className={tipoDesafio} onClick={() => detalhesDesafio(desafio._id)}><span> {desafio.nome_desafio} - {desafio.pontuacao} pts.</span>
      </li>
    ));
  }, [desafiosPlataforma, desafiosUsuario]);

  const mostrarOpcoesDeDesafio = useCallback((): JSX.Element[] => {
    return desafiosBase.map((desafio) => (
      <Opcao description={desafio.description} key={desafio._id} value={desafio.title}>{desafio.title}</Opcao>
    ));
  }, [desafiosBase]);

  const mostrarRanking = useCallback(() => {
    switchState('seeRanking');
  }, []);

  const mostrarPerfil = useCallback(() => {
    switchState('seeProfile');
  }, []);

  const detalhesDesafio = useCallback((id_desafio: string) => {
    switchState('seeChallenge');

    if (!id_desafio) {
      setProcessState('failed');
      defMensagemConfirmacao('Erro: ID do desafio não identificado.');
      return;
    }

    const desafio = [...desafiosPlataforma, ...desafiosUsuario, ...desafiosCumpridos, ...desafiosCriados]
      .find(desafio => desafio._id === id_desafio);

    if (!desafio) {
      setProcessState('failed');
      defMensagemConfirmacao('Erro: Desafio não encontrado.');
      return;
    }

    setDesafio(desafio);
    setDesafioSelecionado(id_desafio);
  }, [desafiosPlataforma, desafiosUsuario, desafiosCumpridos, desafiosCriados]);

  const handleResponse = useCallback(async (imageLink: string, description: string) => {
    startAnswering(false);
    switchState('standby');

    if (!desafioSelecionado) {
      setProcessState('failed');
      defMensagemConfirmacao('Erro: Desafio não selecionado.');
      return;
    }

    const dados = {
      id_desafio: desafioSelecionado,
      resposta_desafio: {
        mensagem_resposta: description,
        foto_resposta: imageLink
      }
    };

    const resposta = await responderDesafio(dados);

    if (resposta.status === 200) {
      setProcessState('success');
      defMensagemConfirmacao('Sucesso na resposta');
    } else {
      setProcessState('failed');
      defMensagemConfirmacao('Algo deu errado, tente novamente');
    }
  }, [desafioSelecionado]);

  useEffect(() => {
    const fetchData = async () => {
      const dadosDesafios = await getDesafios();
      setDesafiosPlataforma(dadosDesafios.info?.desafios_recebidos_plataforma || []);
      setDesafiosUsuario(dadosDesafios.info?.desafios_recebidos_usuarios || []);
      setDesafiosCriados(dadosDesafios.info?.desafios_para_avaliar || []);
      setDesafiosCumpridos(dadosDesafios.info?.desafios_recebidos_realizados || []);
      const desafiosPredefinidos = await getDesafiosPredefinidos();
      setDesafiosBase(desafiosPredefinidos.info);
      const getUsersRanking = await getRankingDesafios();
      setUsuariosRanking(getUsersRanking.info);
      setProcessState("standby")
    };
    fetchData();
  }, []);

  return (
    <>
      {state === 'seeRanking' && (
        <Cont_form_evento>
          <Challenge_list className="ranking">
            <Normal_button className="close" onClick={() => { switchState('standby'); setProcessState('standby'); }}>
              <IoMdClose size={20} />
            </Normal_button>
            <h3>Ranking desafios</h3>
            {rankingUsers}
          </Challenge_list>
        </Cont_form_evento>
      )}
      {answering && <CameraModule onComplete={handleResponse} />}
      {state === 'seeProfile' && (
        <Cont_form_evento>
          <Normal_button className="close" onClick={() => { switchState('standby'); setProcessState('standby'); }}>
            <IoMdClose size={20} />
          </Normal_button>
          <h2>Meu perfil de desafios</h2>
          <div className="perfil">
            <h3>Desafios a avaliar</h3>
            <ul>{ShowDesafiosCriados('feitos')}</ul>
          </div>
          <div className="perfil">
            <h3>Desafios Finalizados</h3>
            <ul>{ShowDesafiosCriados('cumpridos')}</ul>
          </div>
        </Cont_form_evento>)}
      {state === 'seeChallenge' && (
        <Cont_form_evento>
          <Normal_button className="close" onClick={() => { switchState('standby'); setProcessState('standby'); }}>
            <IoMdClose size={20} />
          </Normal_button>
          <span>{desafioInfo?.nome_desafio}</span>
          <span>{desafioInfo?.descricao_desafio}</span>
          {!desafioInfo?.resposta && !desafioInfo?.validado && <span>Criado por: {desafioInfo?.desafiante}</span>}
          {desafioInfo?.resposta && !desafioInfo?.validado && <span>Respondido por: {desafioInfo?.desafiado}</span>}
          {desafioInfo?.resposta && <>
            <img alt="Imagem de resposta do desafio" src={desafioInfo.resposta.foto_resposta}></img>
            <span>Resposta: {desafioInfo.resposta.mensagem_resposta}</span></>}
          {!desafioInfo?.resposta && <><Normal_button className="accept" onClick={StartResponse}>Aceitar e responder!</Normal_button></>}
          {desafioInfo?.resposta && !desafioInfo.validado && <FloatingBox>
            {desafioInfo?.resposta.mensagem_resposta && <span>Respondeu: {`"${desafioInfo.resposta.mensagem_resposta}"`}</span>}<Normal_button className="accept" onClick={handleAprovacao}>Aceitar resposta</Normal_button></FloatingBox>}
        </Cont_form_evento>
      )}
      {state === 'newChallenge' && (
        <Cont_form_evento>
          <h2>Criar novo desafio:</h2>
          <form>
            <Normal_button className="close" onClick={() => { setProcessState('standby'); switchState("standby"); }}>
              <IoMdClose size={20} />
            </Normal_button>
            <SearchComponent onResultClick={handleResultClick} id='descricao_desafio'></SearchComponent>
            <label htmlFor="nome_desafio">Insira o nome do desafio:</label>
            <input ref={titulo_desafio} id="nome_desafio"></input>
            <label htmlFor="descricao_desafio">Insira o conteúdo do desafio:</label>
            <input ref={descricao_desafio} id="descricao_desafio" placeholder="Faça 20 polichinelos"></input>
            <label htmlFor="lista_desafios">Ou escolha um desafio predefinido</label>
            <select id="lista_desafios" onChange={handleChange}>
              <option value='selecione'>Selecione</option>
              {mostrarOpcoesDeDesafio()}
            </select>
            <Normal_button className="create" onClick={handleCreate}>Enviar</Normal_button>
          </form>
        </Cont_form_evento>
      )}
      <Title_lista><h2>Desafios da plataforma</h2></Title_lista>
      <ListaDesafios>
        {ShowDesafios('plataforma')}
        {desafiosPlataforma.length == 0 && <li><span>Sem desafios</span></li>}
      </ListaDesafios>
      <Title_lista><h2>Desafios dos usuários</h2></Title_lista>
      <ListaDesafios>
        {ShowDesafios('usuarios')}
        {desafiosUsuario.length == 0 && <li><span>Sem desafios</span></li>}
      </ListaDesafios>
      <CriarDesafio onClick={() => switchState("newChallenge")}>
        <TiPlusOutline color="white" size={35} />
      </CriarDesafio>
      <CriarDesafio className="ranking" onClick={mostrarRanking}>
        <FaRankingStar color="white" size={35} />
      </CriarDesafio>
      <CriarDesafio className="profile" onClick={mostrarPerfil}>
        <CgProfile color="white" size={35} />
      </CriarDesafio>
      <Tela_confirmacao tipo={processState} texto={mensagemConfirmacao} />
    </>
  );
}

export default Desafio_component;
