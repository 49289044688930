import { useState, useEffect, useCallback, ReactElement, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Header_sugestoes, Campo_pesquisa, Lista_usuarios, Perfil_usuario, Botao_seguir } from "./sugestoes.style";
import SearchComponent from "../Campo de pesquisa/pesquisa_seguir.component";
import ReturnButton from "../Botao retornar/return.component";
import { SugestionsFollow, ToggleFollow, notificar } from "../logics/def_functions";

interface Sugestoes {
  user_name: string;
  user_full_name: string;
  prof_pic: string;
  _id: string;
}

const Sugestoes_component = () => {
  const [resultados, setResultados] = useState<Sugestoes[]>([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [sugestoes, setSugestoes] = useState<ReactElement[]>([]);
  const navigate = useNavigate();
  const buttonRefs = useRef<Map<string, HTMLButtonElement>>(new Map());

  // Função para buscar sugestões
  const requestSugestoes = useCallback(async () => {
    const usuarios = await SugestionsFollow(limit, page);
    return usuarios.info;
  }, [limit, page]);

  // Função para renderizar resultados
  const renderizarResultados = useCallback((resultados: Sugestoes[]) => {
    const sugestoesRenderizadas = resultados.map((element) => (
      <Perfil_usuario key={element._id} onClick={() => irAoPerfil(element.user_name)}>
        <img src={element.prof_pic} alt="Foto de perfil" />
        <span>{element.user_full_name}</span>
        <Botao_seguir
          ref={(el) => el && buttonRefs.current.set(element._id, el)}
          onClick={(e) => { e.stopPropagation(); Seguir(element.user_name, element._id); }}
        >
          Seguir
        </Botao_seguir>
      </Perfil_usuario>
    ));
    setSugestoes(sugestoesRenderizadas);
  }, []);

  // Função para seguir um usuário
  const Seguir = async (nome_usuario: string, userId: string) => {
    await ToggleFollow(nome_usuario);
    await notificar({ id_objeto: userId, tipo_objeto: 'user', tipo_interacao: 'follow' })
    //console.log(notificacao)
    const button = buttonRefs.current.get(userId);
    if (button) {
      button.textContent = "Seguindo";
      button.classList.add("seguindo");
    }
  };

  // Função para navegar para o perfil
  const irAoPerfil = (nome_usuario: string) => {
    navigate(`/social/perfil?${encodeURIComponent(nome_usuario)}`);
  };

  // Função para carregar mais resultados
  const pedirMais = () => {
    setPage(prevPage => prevPage + 1);
  };

  // Efeito para buscar sugestões e renderizar resultados
  useEffect(() => {
    const fetchData = async () => {
      const usuarios = await requestSugestoes();
      setResultados(usuarios);
      renderizarResultados(usuarios);
    };

    fetchData();
  }, [requestSugestoes, renderizarResultados]);

  return (
    <>
      <ReturnButton />
      <Campo_pesquisa>
        <SearchComponent />
      </Campo_pesquisa>
      <Header_sugestoes>
        <h3>Sugestões para seguir</h3>
        <Lista_usuarios>
          {resultados.length === 0 && <li>Sem sugestões no momento</li>}
          {resultados.length > 0 && sugestoes}
          {resultados.length >= limit &&
            <li onClick={pedirMais}>Ver mais</li>
          }
        </Lista_usuarios>
      </Header_sugestoes>
    </>
  );
};

export default Sugestoes_component;
