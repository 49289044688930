import Login_component from "../../components/Login_component/login.component";
import './style.css'
const Pagina_login = () => {
  return (<>
    <div className="fundo">
      <Login_component></Login_component>
    </div>
  </>)

}
export default Pagina_login
