import Container_meditacao from "../../components/Sections/container_meditacao.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_meditacao from "../../components/Sidebar/sidebar.meditacao.component";

const Pagina_meditacao = () => {
  return (<>
    <Container_meditacao></Container_meditacao>
    <Sidebar_meditacao></Sidebar_meditacao>
    <Navbar active="meditar"></Navbar>
  </>
  )
}

export default Pagina_meditacao
