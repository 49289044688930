import { Container, PlaceHolder, TitleDiv } from './container_padrao.component.style'
import Mini_section from '../Mini_section/mini_section.component'
import { useNavigate } from 'react-router-dom';
import { TiHeadphones } from "react-icons/ti";
import { RiPsychotherapyLine } from "react-icons/ri";
import { MdOutlinePlayCircle } from "react-icons/md";
import logo from '../Assets/Images/newlogo.svg';
import escrita from '../Assets/Images/escrita.webp'


const Container_meditacao = () => {
  const navigate = useNavigate()
  const toMeditacao = () => {
    navigate('/meditacao/playlist_meditar')
  }
  const toPlaylistMeditacao = () => {
    navigate('/meditacao/playlist_concentrar')
  }
  const toPsicologiaViva = () => {
    window.location.href = 'https://consultorioonline.psicologiaviva.com.br/cadastro'
  }
  return <>
    <Container>
      <TitleDiv><img
        style={{ width: '6rem', height: 'auto' }}
        src={escrita}
        alt="Logo LCM"
      /><img
          style={{ width: '8rem', height: 'auto' }}
          src={logo}
          alt="Logo LCM"
        /></TitleDiv>
      <Mini_section className='meditacao' title='Iniciar meditação' description='Inicie uma sessão de meditação com algumas assistencias' icon={TiHeadphones} onClick={toMeditacao}></Mini_section>
      <Mini_section className='meditacao' title='Saude mental' description='Acesse a plataforma Psicologia Viva e agende uma sessão de terapia' icon={RiPsychotherapyLine} onClick={toPsicologiaViva}></Mini_section>
      <Mini_section className='meditacao' title='Playlists' description='Consulte playlists criadas pela comunidade com foco em relaxamento e concentração' icon={MdOutlinePlayCircle} onClick={toPlaylistMeditacao}></Mini_section>
      <PlaceHolder></PlaceHolder>
    </Container>
  </>
}

export default Container_meditacao
