'use strict'

interface MesInfo {
  primeiro: string
  ultimo: string
  num_do_mes: number
  hoje: string
}

class Calendar {
  dataHoje: Date
  anoAtual: number
  mesEmQuestao: number
  options: Intl.DateTimeFormatOptions

  constructor() {
    this.dataHoje = new Date()
    this.anoAtual = this.dataHoje.getFullYear()
    this.mesEmQuestao = this.dataHoje.getMonth() + 1
    this.options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  }

  proxMes() {
    this.mesEmQuestao++
    if (this.mesEmQuestao > 12) {
      this.mesEmQuestao = 1
      this.anoAtual++
    }
    this.dataHoje = new Date(this.anoAtual, this.mesEmQuestao - 1, 1)
  }

  ultimoMes() {
    this.mesEmQuestao--
    if (this.mesEmQuestao < 1) {
      this.mesEmQuestao = 12
      this.anoAtual--
    }
    this.dataHoje = new Date(this.anoAtual, this.mesEmQuestao - 1, 1)
  }

  getInfoDoMes(): MesInfo {
    const dataInicial = new Date(this.anoAtual, this.mesEmQuestao - 1, 1)
    const primeiroDia = dataInicial.toLocaleDateString('pt-BR', this.options)
    const dataFinal = new Date(this.anoAtual, this.mesEmQuestao, 0)
    const ultimoDia = dataFinal.toLocaleDateString('pt-BR', this.options)
    const hoje = new Date().toLocaleDateString('pt-BR', this.options)
    return {
      primeiro: primeiroDia,
      ultimo: ultimoDia,
      num_do_mes: this.mesEmQuestao,
      hoje: hoje,
    }
  }
}

export const indiceDia = (day: string): number => {
  switch (true) {
    case day === 'domingo':
      return 1
    case day === 'segunda-feira':
      return 2
    case day === 'terça-feira':
      return 3
    case day === 'quarta-feira':
      return 4
    case day === 'quinta-feira':
      return 5
    case day === 'sexta-feira':
      return 6
    case day === 'sábado':
      return 7
    default:
      return 0
  }
}

export const infoAtual = (): MesInfo => {
  const dados = calendario.getInfoDoMes()
  return dados
}

export const proximoMes = (): MesInfo => {
  calendario.proxMes()
  const dados = calendario.getInfoDoMes()
  return dados
}

export const mesAnterior = (): MesInfo => {
  calendario.ultimoMes()
  const dados = calendario.getInfoDoMes()
  return dados
}

export const indiceDiaDaSemana = (dia: string): number => {
  const index = indiceDia(dia)
  return index
}

// Exemplo de uso:
const calendario = new Calendar()
