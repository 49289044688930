import { Sidebar_base, Sidebar_icon, List, LItem, LoginArea, Profile_pic, Profile, UserName, Button, Botao_ajuda } from "./sidebar.style.component";
import { useState, useEffect } from "react";
import { ImExit } from "react-icons/im";
import { MdOutlineLiveHelp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { manterLogado, obterDadosSociais } from "../logics/def_functions";
import imc from '../Assets/Images/imc.webp'
import desafios from '../Assets/Images/desafios.webp'
import eventos from '../Assets/Images/eventos.webp'
import playlists from '../Assets/Images/playlists-exercicios.png'
import Tutorial_screen from "../Tutorial/tutorial.component";

const Sidebar_exercicios = () => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const [emailDeUsuario, setEmailDeUsuario] = useState('')
  const [tutorial, showTutorial] = useState(false)
  const [imagemPerfil, defImagemPerfil] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
  const inserirUsuario = async () => {
    const usuarioLogado = await obterDadosSociais({ metodo: 'self' })
    if (usuarioLogado.status == 200) {
      setEmailDeUsuario(usuarioLogado?.info?.user_full_name)
      defImagemPerfil(usuarioLogado?.info?.prof_pic)
    } else {
      navigate('/social/edit_social')
    }
  }
  const logoff = () => {
    localStorage.removeItem('token_acesso_LCMFIT');
    navigate('/login')
  };
  const toCorpEvents = () => {
    navigate('/exercicios/eventos_corporativos')
  }
  const toEventosSociais = () => {
    navigate('/exercicios/eventos_sociais')
  }
  const toPlaylists = () => {
    navigate("/exercicios/playlists")
  }
  const toDesafios = () => {
    navigate("/exercicios/desafios")
  }
  const toCalculadora = () => {
    navigate('/exercicios/calculadora_imc')
  }

  useEffect(() => {
    const logado = manterLogado()
    if (!logado) {
      navigate('/login')
    }
    inserirUsuario()
  }, [])

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  }
  const conteudos = [{
    setor: 'Calculadora de IMC',
    imagem: imc,
    descricao: 'Com a calculadora de IMC, você consegue calcular seu índice de massa corporal, para definir seus próximos passos para ser cada vez mais saudavel.'
  }, {
    setor: 'Competitividade',
    imagem: desafios, descricao: "Desafie seus amigos e colegas para ver quem de vocês é o mais fitness, o sistema conta com um ranking que sobe toda vez que um desafio predefinido é cumprido! Desafios personalizados não contam."
  }, {
    setor: 'Eventos', imagem: eventos, descricao: "Em eventos, você pode ver os eventos fitness promovidos pelo projeto Corpo Saudável, Mente Produtiva!"
  }, { setor: 'Malhe junto', imagem: eventos, descricao: 'Em malhe junto, você pode criar eventos pra reunir a galera!' }, {
    setor: "Playlists de exercícios", imagem: playlists, descricao: 'Em playlists, você pode achar uma série de playlists de exercícios pensadas em melhorar sua saúde e cuidar do seu bem estar'
  }]
  return (
    <>
      <Sidebar_base isExpanded={isExpanded}>
        {isExpanded && (<><List>
          <LItem onClick={toCalculadora}>Calcular IMC</LItem>
          <LItem onClick={toDesafios}>Competitividade</LItem>
          <LItem onClick={toCorpEvents}>Eventos</LItem>
          <LItem onClick={toEventosSociais}>Malhe junto!</LItem>
          <LItem onClick={toPlaylists}>Playlists</LItem>
        </List>
          {tutorial && (<Tutorial_screen estado='show' conteudos={conteudos} onClose={() => showTutorial(false)}></Tutorial_screen>)}
          <Botao_ajuda onClick={() => showTutorial(true)}><MdOutlineLiveHelp size={50} color="white"></MdOutlineLiveHelp><span>Ajuda</span></Botao_ajuda>
          <LoginArea>
            <Profile>
              <Profile_pic src={imagemPerfil}></Profile_pic>
              <UserName>{emailDeUsuario}</UserName>
            </Profile>
            <Button><ImExit size={30} onClick={() => logoff()} /></Button>
          </LoginArea>
        </>)}
        <Sidebar_icon onClick={toggleSidebar}></Sidebar_icon>
      </Sidebar_base>
    </>
  )
}
export default Sidebar_exercicios
