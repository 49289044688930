import styled from 'styled-components'

interface ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const CalendarCont = styled.table`
  position: relative;
  left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: ${(props) => props.theme.breakpoints.sm};
  background-color: ${(props) => props.theme.colors.white};
  gap: 0.1rem;
  transform: scale(0.85);
`
export const CalendarBody = styled.tbody`
  width: 100%;
  display: grid;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  padding: 0.2rem;
  grid-template-rows: repeat(6, 1fr);
  gap: 0.2rem;
`
export const CalendarWeek = styled.tr`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.1rem;
`

export const CalendarDay = styled.td`
  background-color: ${(props) => props.theme.colors.gray900};
  display: flex;
  align-items: center;
  aspect-ratio: 1/1;
  justify-content: center;
  border-radius: 50% 50%;
  transition: all 0.5s;
  &.previous {
    background-color: ${(props) => props.theme.colors.gray600};
    > * {
      color: ${(props) => props.theme.colors.gray300};
    }
  }
  &.next {
    background-color: ${(props) => props.theme.colors.gray600};
    > * {
      color: ${(props) => props.theme.colors.gray300};
    }
  }
  &.weekdays {
    background-color: ${(props) => props.theme.colors.primary};
    > * {
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.today {
    border: 0.2rem solid ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.light_green};
    > * {
      color: ${(props) => props.theme.colors.black};
    }
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.light_green};
  }
`

export const CalendarHead = styled.thead`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0.5rem 0;
  border-radius: 1rem;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-evenly;
`

export const Buttons = styled.button`
  height: 3rem;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  border: transparent;
  border-radius: 1rem;
  aspect-ratio: 1/1;
`

export const TableHead = styled.td`
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  display: flex;
`

export const Popup = styled.div`
  padding: 3.5rem 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 13;
  background-color: ${(props) => props.theme.colors.black_translucid};
`

export const ButtonExitPopup = styled.button<ButtonProps>`
  color: ${(props) => props.theme.colors.primary};
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.gray900};
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  > * {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const ListHorarios = styled.ul`
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.5rem;
  border-radius: 1rem;
  width: 80%;
  height: fit-content;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
`

interface HorarioProps {
  horarioEscolhido: string
}

export const SimpleLink = styled.a`
  text-decoration: none;
`

export const SimpleLI = styled.li`
  width: 80%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.gray600};
  padding: 0.5rem;
  border-radius: 1rem;
  color: ${(props) => props.theme.colors.white};
`
export const Horario = styled.li.attrs<HorarioProps>((props) => ({
  horarioEscolhido: props.horarioEscolhido,
}))<HorarioProps>`
  width: 80%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.gray600};
  padding: 0.5rem;
  border-radius: 1rem;
  color: ${(props) => props.theme.colors.white};
`

export const Prontuario = styled.form`
  width: 80%;
  background-color: ${(props) => props.theme.colors.primary};
  text-align: center;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`

export const Formulario = styled.input`
  width: 80%;
  height: 10rem;
  border: transparent;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  padding: 1rem;
  border-radius: 1rem;
`

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.white};
  transition: all 0.5s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.white};
  }
`
export const EspacoAgendamentos = styled.div`
  width: 80%;
  height: fit-content;
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
  left: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7rem;
  background-color: ${(props) => props.theme.colors.primary};
`
