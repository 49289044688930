import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cam, Canvas, Video, Button, ButtonSend, Result, Switch, Close, Folder, Shutter, Cont_buttons, Galery, Post, Back, CreatePostContainer, PostPreview, Titulos, Descricao_post, SavePost } from './cam.component.style';
import { CloudinaryService } from '../logics/cloudinaryUploader';
import { obterDadosSociais, criarPost } from '../logics/def_functions';
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';

const Camera = () => {
  const descriptionRef = useRef<HTMLInputElement | null>(null);
  const galleryRef = useRef<HTMLInputElement | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [side, changeSide] = useState('user');
  const [selfie, defSelfie] = useState('selfie');
  const [imageRendered, setImage] = useState('');
  const [publish, goToPublish] = useState(false)
  const [estadoGeral, setEstadoGeral] = useState("standby")
  const [mensagemGeral, setMensagemGeral] = useState('')
  const cloudinaryService = new CloudinaryService()
  const navigate = useNavigate();

  const goBack = () => {
    confirmViews()
    if (imageRendered != '') {
      goToPublish(false)
      setImage('')
    } else {
      stopVideoStream();
      navigate('/social');
    }
  };

  const switchViews = async () => {
    const newSide = side === 'user' ? 'environment' : 'user';
    changeSide(newSide);
    defSelfie(newSide === 'user' ? 'selfie' : '');
  };

  const handleFolderClick = () => {
    galleryRef.current?.click();
  };

  const stopVideoStream = () => {
    const video = videoRef.current;
    if (video?.srcObject) {
      const stream = video.srcObject as MediaStream;
      stream.getTracks().forEach(track => track.stop());
      video.srcObject = null;
    }
  };

  const getVideo = async (cameraSide: string) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 },
          facingMode: { exact: cameraSide }
        }
      });

      const video = videoRef.current;
      if (video) {
        stopVideoStream();
        video.srcObject = stream;
        await video.play();
      }
    } catch (error) {
      console.error('Erro ao acessar a câmera:', error);
      setEstadoGeral('failed')
      setMensagemGeral(`Falha ao acessar sua câmera: ${String(error)}`)
    }
  };

  const takePhoto = async () => {
    const video = videoRef.current;
    const photo = photoRef.current;
    if (video && photo) {
      const width = video.videoWidth;
      const height = video.videoHeight;

      photo.width = width;
      photo.height = height;

      const ctx = photo.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, width, height);
        setHasPhoto(true);
        setImage(await photo.toDataURL())
      }
      stopVideoStream()
    }
  };

  const erasePhoto = async () => {
    confirmViews()
    await getVideo(side)
    setHasPhoto(false);
    const photo = photoRef.current;
    if (photo) {
      const ctx = photo.getContext('2d');
      ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }
    goToPublish(false)
  };

  const uploadImage = async (imagePath: File) => {
    try {
      event?.preventDefault()
      const url = await cloudinaryService.upload(imagePath)
      console.log(url)
      if (url.status == 200) {
        return url.info
      } else {
        throw new Error("Falha no upload")
      }
    } catch (error) {
      console.error(error);
      setEstadoGeral('failed');
      setMensagemGeral(`Falha no upload do arquivo: ${String(error)}`);
      return null;
    }
  };

  const base64ToFile = (base64Data: string, filename: string): File => {
    const [metadata, data] = base64Data.split(',');
    const mimeType = metadata.split(':')[1].split(';')[0];
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], filename, { type: mimeType });
  };

  const handleSelectedImage = () => {
    changeSide('environment');
    stopVideoStream();
    const file = galleryRef.current?.files?.[0];
    if (file) {
      const image = new Image();
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        const photo = photoRef.current;
        if (photo) {
          photo.width = width;
          photo.height = height;
          const ctx = photo.getContext('2d');
          if (ctx) {
            ctx.drawImage(image, 0, 0, width, height);
            setHasPhoto(true);
            setImage(photo.toDataURL());
          }
        }
      };
      image.src = URL.createObjectURL(file);
      defSelfie('');
      goToPublish(true);
    }
  };

  const makePublication = async () => {
    const imageLink = await uploadImage(base64ToFile(imageRendered, 'file.png'));
    const description = descriptionRef.current?.value
    const dadosSociais = await obterDadosSociais({ metodo: 'self' })
    const InfoPost = {
      user_email: dadosSociais?.info?.user_email ?? '',
      post_image: imageLink ?? '',
      post_description: description ?? '',
      post_author: dadosSociais?.info?.user_name ?? '',
    }
    const tentarPublicacao = await criarPost(InfoPost)
    //console.log('Checando o status final da publicação: ', tentarPublicacao.status)
    if (tentarPublicacao?.status == 200 || tentarPublicacao?.status == 201 || tentarPublicacao?.status == 204) {
      setEstadoGeral('success')
      setMensagemGeral("Sucesso na publicação")
    } else {
      setEstadoGeral('failed')
      setMensagemGeral(`Falha na publicação, tente novamente`)
    }
  };

  const confirmViews = async () => {
    if (side == 'user' && selfie == '') {
      defSelfie('selfie')
    }
  }

  useEffect(() => {
    getVideo(side);
    return () => stopVideoStream();
  }, [side]);

  return (
    <Cam>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral} />
      <Video className={selfie} ref={videoRef}></Video>
      <Back onClick={goBack}></Back>
      <Close onClick={erasePhoto}></Close>
      <Result hasPhoto={hasPhoto}>
        <Canvas className={selfie} ref={photoRef}></Canvas>
      </Result>
      {!hasPhoto && (
        <Cont_buttons>
          <Button>
            <Folder onClick={handleFolderClick}></Folder>
            <Galery onChange={handleSelectedImage} ref={galleryRef} type='file' accept='image/*'></Galery>
          </Button>
          <Button>
            <Shutter onClick={takePhoto}></Shutter>
          </Button>
          <Button onClick={switchViews}>
            <Switch></Switch>
          </Button>
        </Cont_buttons>
      )}
      {hasPhoto && <ButtonSend onClick={() => goToPublish(true)}><Post></Post></ButtonSend>}
      {publish && imageRendered !== '' &&
        <CreatePostContainer>
          <Titulos>Criar nova publicação</Titulos>
          <PostPreview className={selfie} src={imageRendered}></PostPreview>
          <Descricao_post ref={descriptionRef} type='text' placeholder='Escreva uma descrição para seu post'></Descricao_post>
          <SavePost onClick={makePublication}>Criar</SavePost>
        </CreatePostContainer>}
    </Cam>
  );
};

export default Camera;
