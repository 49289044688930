import styled from 'styled-components'
export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Logo_expository = styled.div`
  width: 50svw;
  height: 6rem;
  display: flex;
  justify-content: space-around;
  > * {
    height: 100%;
    object-fit: cover !important ;
  }
`
export const Home_body = styled.div`
  width: 80%;
  height: fit-content;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  background-color: ${(props) => props.theme.colors.white};
`
export const Home_title = styled.h2`
  color: ${(props) => props.theme.colors.secondary};
`

export const Home_button = styled.button`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 1rem;
  border: transparent;
  color: ${(props) => props.theme.colors.primary};
  transition: all 0.5s;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  &:hover {
    background-color: ${(props) => props.theme.colors.white} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`
