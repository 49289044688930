import styled from 'styled-components'
export const Corpo = styled.div`
  width: 100svw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
`
export const Form_alteracao = styled.div`
  width: 70%;
  max-width: 50rem;
  height: 70%;
  max-height: 50rem;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  & h1 {
    color: ${(props) => props.theme.colors.white};
  }
`
export const Field = styled.input`
  width: 80%;
  border: 0.1rem solid ${(props) => props.theme.colors.gray400};
  background-color: ${(props) => props.theme.colors.gray200};
  color: ${(props) => props.theme.colors.black};
  padding: 0.5rem;
  border-radius: 0.2rem;
`
export const BotaoConfirmar = styled.button`
  width: 80%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colors.white};
  border: 0.1rem solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`
export const VoltarPraHome = styled.button`
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 1rem;
  border: transparent;
  position: fixed;
  top: 1rem;
  left: 1rem;
  color: ${(props) => props.theme.colors.white};
  transition: all 0.5s;
  &:hover {
    background-color: ${(props) => props.theme.colors.white} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`
