import styled from 'styled-components'

export const Comment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center !important;
  justify-content: space-between;
  align-self: center;
  padding: 0.7rem;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  border-radius: 1rem;
  font-size: ${(props) => props.theme.fontSize.md};
  background-color: ${(props) => props.theme.colors.gray200};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: ${(props) => props.theme.breakpoints.xs} !important;
    font-size: ${(props) => props.theme.fontSize.sm};
  }
`
export const Comment_organizer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Prof_pic_mini = styled.img`
  width: 3rem;
  height: 3rem;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 50%;
`
