import { Container, PlaceHolder, TitleDiv } from './container_padrao.component.style'
import Mini_section from '../Mini_section/mini_section.component'
import { MdOutlineAddAPhoto } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";
import { GiKnifeFork } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Images/newlogo.svg';
import escrita from '../Assets/Images/escrita.webp'


const Container_nutricao = () => {
  const navigate = useNavigate()
  const toagendamento = () => {
    navigate('/nutricao/agendamento')
  }

  const toCamera = () => {
    navigate('/nutricao/publicar')
  }
  const toFeed = () => {
    navigate('/nutricao/feed')
  }

  return <>
    <Container>
      <TitleDiv><img
        style={{ width: '6rem', height: 'auto' }}
        src={escrita}
        alt="Logo LCM"
      /><img
          style={{ width: '8rem', height: 'auto' }}
          src={logo}
          alt="Logo LCM"
        /></TitleDiv>
      <Mini_section className='nutricao' title='Dicas de nutrição' description='Veja dicas de nutrição publicadas por nosso profissional especializado' icon={GiKnifeFork} onClick={toFeed}></Mini_section>
      <Mini_section className='nutricao' title='Publicar' description='Publique uma nova receita ou dica de nutrição' icon={MdOutlineAddAPhoto} onClick={toCamera}></Mini_section>
      <Mini_section className='nutricao' title='Consultar com nutricionista' description='Agende uma consulta com nosso profissional especializado' icon={CiCalendarDate} onClick={toagendamento}></Mini_section>
      <PlaceHolder></PlaceHolder>
    </Container>
  </>
}

export default Container_nutricao
