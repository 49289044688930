import Container_social from "../../components/Sections/container_social.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";

const Pagina_social = () => {

  return (<>
    <Container_social></Container_social>
    <Sidebar_social></Sidebar_social>
    <Navbar active="social"></Navbar>
  </>
  )
}

export default Pagina_social
