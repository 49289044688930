import styled from 'styled-components'

export const Posts = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  align-self: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.black};
  width: 90%;
  padding: 1rem;
  border-radius: 1rem;
  gap: 20px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: ${(props) => props.theme.breakpoints.xs} !important;
  }
  .comentarios_destaque {
    width: 90% !important;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`
export const Post_img = styled.img`
  width: 95%;
  height: fit-content;
  border-radius: 1rem;
  cursor: pointer;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    max-width: ${(props) => props.theme.breakpoints.xs};
  }
`
export const Big_img_div = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
export const Big_img = styled.img`
  top: 1rem;
  border-radius: 0.3rem;
  transform: scale(1.1);
  width: 70%;
`
export const Close_button = styled.button`
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSize['2xl']};
  border-radius: 0.2rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.theme.colors.gray300};
  }
`
