import styled from 'styled-components'

interface eventoProps {
  backgroundImage?: string
}

export const Banner_evento = styled.div<eventoProps>`
  margin-top: 0.5rem;
  margin-left: 3rem;
  width: 75vw;
  height: 8rem;
  padding: 1rem;
  border: 0.2rem solid ${(props) => props.theme.colors.gray400};
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &::before {
    width: 100%;
    height: 100%;
    background: rgb(237, 127, 53);
    background: linear-gradient(225deg, rgba(237, 127, 53, 0) 0%, rgba(147, 202, 60, 1) 100%);
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
  }
`

export const Detalhes_evento_div = styled.div`
  width: 75vw;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 3rem;
  background-color: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & > .location {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`

export const Acoes_evento = styled.div`
  width: 75vw;
  height: 3rem;
  position: fixed;
  bottom: 9rem;
  padding: 0.2rem;
  margin-top: 1rem;
  margin-left: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  > * {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const Button = styled.button`
  width: 80%;
  background: rgb(147, 202, 60);
  background: linear-gradient(225deg, rgb(147, 202, 60) 0%, rgba(131, 227, 90, 1) 100%);
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.5rem;
  border-radius: 1rem;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  :hover {
    transform: scale(1.2);
  }
`
