import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Expose_results, Corpo_componente, Cont_perfil, Imagem_perfil, Button } from './pesquisa.style';

interface Resultado {
  user_name: string,
  user_email: string,
  user_full_name: string,
  prof_pic: string,
}

interface SearchComponentProps {
  id: string,
  onResultClick: (result: Resultado) => void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onResultClick }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Resultado[]>([]);
  const [selectedUser, setSelectedUser] = useState<Resultado | null>(null);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (query.length > 2) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeout = setTimeout(() => {
        const fetchResults = async () => {
          try {
            const response = await axios.get(`https://southamerica-east1-semiotic-creek-405114.cloudfunctions.net/api_cdn_lcmcuidadointegral/api/search?query=${query}`);
            setResults(response.data);
          } catch (error) {
            console.error('Error fetching search results', error);
          }
        };
        fetchResults();
      }, 500); // 0.5 seconds debounce

      setDebounceTimeout(timeout);
    } else {
      setResults([]);
    }
  }, [query]);

  return (
    <Corpo_componente>
      {selectedUser == null && <><label htmlFor="desafiado">Insira o nome de usuário ou email do desafiado:</label>
        <input
          type="text"
          id="desafiado"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          disabled={selectedUser !== null}
        /></>}
      <Expose_results>
        {selectedUser == null && results.map((result, index) => (
          <Cont_perfil key={index} className={selectedUser ? 'selected' : ''} onClick={() => { onResultClick(result); setSelectedUser(result); }}>
            <Imagem_perfil src={result.prof_pic} alt={result.user_full_name} />
            {result.user_full_name}
          </Cont_perfil>
        ))}
      </Expose_results>
      {selectedUser && (<>
        <span>Usuário Selecionado:</span>
        <Cont_perfil>
          <Cont_perfil className='selected'>
            <Imagem_perfil src={selectedUser.prof_pic} alt={selectedUser.user_full_name} />
            <p>{selectedUser.user_full_name}</p>
          </Cont_perfil>
          <Button onClick={() => { setSelectedUser(null); setQuery(''); }}><IoIosCloseCircleOutline color='white' size={20} />
          </Button>
        </Cont_perfil></>
      )}
    </Corpo_componente>
  );
};

export default SearchComponent;
