import styled from 'styled-components'

export const CorpoBotao = styled.div`
  width: 2rem;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 0.3rem;
  border: 0.1rem solid ${(props) => props.theme.colors.white};
  position: fixed;
  top: 1rem;
  padding: 0.5rem;
  left: 3rem;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    color: ${(props) => props.theme.colors.white};
  }
`
