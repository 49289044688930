import styled from 'styled-components'
export const Body_tutorial = styled.div`
  width: 80svw;
  height: 80svh;
  display: fixed;
  top: 45%;
  left: 54%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: 0.5rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  z-index: 30;
  & > .fechar {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    aspect-ratio: 2/1;
    border-radius: 0.2rem;
    border: 0.1rem solid ${(props) => props.theme.colors.white};
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.orange};
    color: ${(props) => props.theme.colors.white};
  }
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > .balao_fala {
      padding: 1rem;
      color: ${(props) => props.theme.colors.black};
      width: 45%;
      background-color: ${(props) => props.theme.colors.white};
      border-radius: 0.5rem;
      position: relative;
      ::before {
        position: absolute;
        top: 50%;
        left: -9%;
        transform: rotate(45deg) translate(-50%, -50%) !important;
        width: 2rem;
        height: 2rem;
        background-color: ${(props) => props.theme.colors.white};
        content: '';
        z-index: -1;
      }
    }
  }
`
export const Balao_exemplo = styled.div`
  margin-top: 1rem;
  position: relative;
  width: 80%;
  height: auto;
  aspect-ratio: 16/9 !important;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  ::before {
    position: absolute;
    top: 5%;
    left: 25%;
    transform: rotate(45deg) translate(-50%, -50%) !important;
    width: 2rem;
    height: 2rem;
    background-color: ${(props) => props.theme.colors.white};
    content: '';
    z-index: -1;
  }
  > img {
    width: 100%;
    height: 100%;
  }
`
export const Balao_exemplo_2 = styled.div`
  margin-top: 2rem;
  position: relative;
  border-radius: 0.5rem;
  width: 94% !important;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  ::before {
    position: absolute;
    top: 40%;
    left: 25%;
    transform: rotate(45deg) translate(-50%, -50%) !important;
    width: 2rem;
    height: 2rem;
    background-color: ${(props) => props.theme.colors.white};
    content: '';
    z-index: -1;
  }
`

export const Robo_tutorial = styled.img`
  width: 30svw;
  height: auto;
  aspect-ratio: 2/2;
  align-self: flex-start !important;
`
export const Caixa_navegacao = styled.div`
  width: 60% !important;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 1rem;
  & > .left,
  .right {
    border-radius: 1rem;
    width: 3rem;
    height: auto;
    aspect-ratio: 2/1;
    background-color: ${(props) => props.theme.colors.secondary};
    border: 0.1rem solid ${(props) => props.theme.colors.white};
  }
`
