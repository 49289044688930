import Notify_component from "../../components/Notificacoes/notify.component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_social from "../../components/Sidebar/sidebar.social.component";
import ReturnButton from "../../components/Botao retornar/return.component";

const Pagina_notificacoes = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Sidebar_social></Sidebar_social>
    <Notify_component></Notify_component>
    <Navbar active="social"></Navbar>
  </>)
}

export default Pagina_notificacoes
