/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import { Post_grid, Mini_post, Mini_post_container, Interactions_Box, Header_interactions, Comments_section, New_comment, New_comment_container, Comentar, ButtonDelete, PopupConfirmation } from './galeria.component.style';
import Comentario from '../Coment/comment.component';
import { Big_img_div, Big_img, Close_button } from '../Post/post.component.style';
import QuantidadeLikes from '../Like_meter/like_meter.component';
import { IoMdClose, IoIosSend } from "react-icons/io";
import { CiTrash } from "react-icons/ci";
import Tela_confirmacao from '../Tela de confirmacao/confirmacao.component';
import { ExcluirPost, obterDadosSociais, verComentario, inserirComentario, notificar, obterPostInfo } from '../logics/def_functions';

interface Postagem {
  _id: string,
  user_email: string;
  post_image: string;
  post_description: string;
  post_comments: string[],
  post_likes: string[],
  post_author: string;
  post_date: string;
}
interface Commentario {
  user_email: string | '';
  comment_text: string | '';
  comment_author: string | '';
  comment_likes: string[]; // Ajustado para string[]
  comment_replies: string[]; // Ajustado para string[]
  comment_date: string;
}

interface DadosTarget {
  target_id: string;
  conteudo?: string; // Ajustado para usar 'Commentario'
  target: string;
}
interface GaleriaProps {
  posts?: string[]
}

const Galeria: React.FC<GaleriaProps> = ({ posts }) => {
  console.log(posts)
  const [statusGeral, setStatusGeral] = useState('standby')
  const [mensagemGeral, setMensagemGeral] = useState('')
  const [postTohandle, startHandlingPost] = useState<Postagem | undefined>(undefined);
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [imagemSelecionada, setImagemSelecionada] = useState('');
  const [descricaoPost, setDescricao] = useState('');
  const [usuarioLogado, setUsuarioLogado] = useState('')
  const [autor, setAutor] = useState('')
  const [liked, defLiked] = useState(false)
  const [likes, setLikes] = useState(0)
  const [toDelete, setToDelete] = useState(false)
  const [postElements, definePosts] = useState<JSX.Element[]>([]);
  const [commentElements, defineComments] = useState<JSX.Element[]>([])
  const comment_input = useRef<HTMLInputElement>(null)

  const handleClickImg = async (Bool: boolean, postagem: Postagem | undefined) => {
    defineComments(await renderComments(postagem))
    startHandlingPost(postagem)
    setImagemSelecionada(postagem?.post_image || '');
    setMostrarComponente(Bool);
    setDescricao(postagem?.post_description || '')
    setAutor(postagem?.post_author || '')
    setLikes(postagem?.post_likes.length || 0)
  };
  const renderComments = async (postagem: Postagem | undefined) => {
    const arrayComentarios = [];
    if (postagem) {
      for (const element of postagem.post_comments) {
        const comentario = await verComentario(element)
        const comentante = await obterDadosSociais({ metodo: 'others', nome_usuario: comentario.info.comment_author })
        const comentarioForm = { ...comentario.info, usuario: comentante.info }
        arrayComentarios.push(<Comentario key={comentario._id} {...comentarioForm}></Comentario>)
        /*preciso buscar o comentário, e o perfil que comentou pra exibir corretamente*/
      }
    }
    return arrayComentarios;
  }

  const makeComment = async () => {
    if (comment_input != null) {
      const textComment = comment_input.current?.value || '';
      const pacote: DadosTarget = {
        target_id: postTohandle?._id || '',
        conteudo: textComment,
        target: 'post'
      };
      try {
        const salvarComentario = await inserirComentario(pacote);
        const notificacao = await notificar({ id_objeto: pacote.target_id, tipo_objeto: pacote.target, tipo_interacao: 'comment' })
        if (salvarComentario?.status == 200) {
          setStatusGeral('success')
          setMensagemGeral('Comentário inserido com sucesso')
        }
      } catch (error) {
        setStatusGeral('failed')
        setMensagemGeral('Falha na inserção do comentário')
        console.error('Erro ao comentar: ', error)
      }
    }
  };
  const toggleDeletePopup = () => {
    if (!toDelete) {
      setToDelete(true)
    } else {
      setToDelete(false)
    }
  }

  const renderPosts = async () => {
    const postagensPromises = posts?.map(async (element) => {
      const informacao_post = await obterPostInfo(element);
      console.log("Info post", informacao_post)
      if (informacao_post.status == 200) {
        return (
          <Mini_post_container key={informacao_post.info.info._id}>
            <Mini_post
              onClick={() => handleClickImg(true, informacao_post.info.info)}
              src={informacao_post.info.info.post_image}
            />
          </Mini_post_container>
        );
      }
      return null; // Retorna null se a informação do post não for bem-sucedida
    }) || [];
    const postagens = await Promise.all(postagensPromises);
    definePosts(postagens.filter(postagem => postagem !== null) as React.ReactElement[]);
  }
  const definirUsuarioLogado = async () => {
    const usuario = await obterDadosSociais({ metodo: 'self' })
    console.log('Usuário identificado', usuario)
    setUsuarioLogado(usuario.info.user_name)
  }
  const deletarPost = async () => {
    const id_post = postTohandle?._id
    if (id_post) {
      const exclusao = await ExcluirPost(id_post)
      if (exclusao?.status == 200) {
        setStatusGeral('success')
        setMensagemGeral('Sucesso na exclusão do post')
      } else {
        setStatusGeral('failed')
        setMensagemGeral('Falha na exclusão do post')
      }
    }
  }
  useEffect(() => {
    definirUsuarioLogado();
    renderPosts();
  }, [statusGeral]);

  return (
    <Post_grid>
      {mostrarComponente && (
        <Big_img_div>
          {postTohandle?.post_author == usuarioLogado && <ButtonDelete onClick={toggleDeletePopup}><CiTrash size={30} color='#93ca3c' />
          </ButtonDelete>}
          {toDelete && <><PopupConfirmation>
            <span>Você realmente deseja excluir esse post?</span>
            <div><button onClick={deletarPost}>Sim</button><button onClick={toggleDeletePopup}>Não</button></div>
          </PopupConfirmation>
          </>}
          <Big_img src={imagemSelecionada} />
          <Interactions_Box>
            <Header_interactions>
              <div>
                <span>
                  <strong>@{autor} </strong>
                </span>
                <span>{descricaoPost}</span>
              </div>
              <QuantidadeLikes likes={likes} liked={liked} info_post={postTohandle}></QuantidadeLikes>
            </Header_interactions>
            <Comments_section>
              {Number(postTohandle?.post_comments.length) == 0 && <>
                <span>Sem comentários, seja o primeiro!</span></>}
              {Number(postTohandle?.post_comments.length) > 0 && <>{commentElements}
              </>}
              <New_comment_container>
                <New_comment ref={comment_input} placeholder='Insira um comentário'></New_comment>
                <Comentar onClick={makeComment}><IoIosSend size={20} color='white'></IoIosSend></Comentar>
              </New_comment_container>
            </Comments_section>
          </Interactions_Box>
          <Close_button onClick={() => handleClickImg(false, undefined)}>
            <IoMdClose />
          </Close_button>
        </Big_img_div>
      )}
      {postElements}
      <Tela_confirmacao tipo={statusGeral} texto={mensagemGeral}></Tela_confirmacao>
    </Post_grid>
  );
};

export default Galeria;
