import React, { useState, useEffect, useCallback } from 'react';
import { FaHeart } from 'react-icons/fa';
import { Like_meter } from './like_meter.component.style';
import { obterDadosSociais, toggleLike, obterPostInfo, verComentario, notificar } from '../logics/def_functions';

interface PropsLike {
  likes: number;
  liked: boolean;
  info_post?: Postagem | undefined;
  info_comment?: CommentProps
}

interface CommentProps {
  comment_author: string;
  comment_date: string;
  comment_likes?: string[];
  comment_replies?: string[];
  comment_text: string;
  user_email: string;
  _id: string;
}

interface Postagem {
  _id: string;
  user_email: string;
  post_image: string;
  post_description: string;
  post_comments: string[];
  post_likes: string[];
  post_author: string;
  post_date: string;
}

interface DadosTarget {
  target_id: string;
  conteudo?: string; // Ajustado para usar 'Commentario'
  target: string;
}

const QuantidadeLikes: React.FC<PropsLike> = ({ info_post, info_comment }) => {
  const [likesState, setLikes] = useState(0);
  const [hasBeenLiked, setLiked] = useState(false);

  const verificarLikes = useCallback(async () => {
    if (info_post) {
      const whosme = await obterDadosSociais({ metodo: 'self' });
      const meuId = whosme.info?._id;
      const newInfo = await obterPostInfo(info_post._id);
      ////console.log("Atualizando as informações do post");
      setLikes(newInfo?.info?.info?.post_likes.length);
      setLiked(newInfo?.info?.info?.post_likes.includes(meuId));
    }
    if (info_comment) {
      const whosme = await obterDadosSociais({ metodo: 'self' });
      const meuId = whosme.info?._id;
      const idComentario = info_comment._id
      const newInfo = await verComentario(idComentario)
      ////console.log('Informações do comentario', newInfo)
      setLikes(newInfo.info.comment_likes.length);
      setLiked(newInfo.info.comment_likes.includes(meuId));
    }

  }, [info_post]);

  useEffect(() => {
    verificarLikes();
  }, [verificarLikes]);

  const increaseLikes = async () => {
    if (info_post) {
      const dadosTarget: DadosTarget = {
        target_id: info_post._id,
        target: 'post',
      };
      ////console.log("Revisão dos dados de curtida: ", dadosTarget)
      const mudanca = await toggleLike(dadosTarget);
      ////console.log("Resultado: ", mudanca)
      const dadosANotificar = { id_objeto: info_post._id, tipo_objeto: 'post', tipo_interacao: 'like' }
      const notificacao = await notificar(dadosANotificar)
    }

    if (info_comment) {
      const dadosTarget: DadosTarget = {
        target_id: info_comment._id,
        target: 'comment',
      };
      ////console.log("Revisão dos dados de curtida: ", dadosTarget)
      const mudanca = await toggleLike(dadosTarget);
      ////console.log("Resultado: ", mudanca)
      const dadosANotificar = { id_objeto: info_comment._id, tipo_objeto: 'comment', tipo_interacao: 'like' }
      const notificacao = await notificar(dadosANotificar)
      ////console.log("Resposta da notificação: ", notificacao)
    }
    if (!info_post || !info_comment) {
      ////console.log('Dados insuficientes para a operação')
    }
    verificarLikes(); // Atualizar informações de likes após a mudança
  };

  return (
    <>
      <Like_meter className={hasBeenLiked ? 'liked' : 'unliked'} onClick={increaseLikes}>
        <FaHeart />
        <span className='icon_like'>{likesState}</span>
      </Like_meter>
    </>
  );
};

export default QuantidadeLikes;
