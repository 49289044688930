import React, { useState, useEffect } from "react";
import { Corpo_lista_exames, Lista_exames } from "./exames.style";
import { requisitarExames } from "../logics/def_functions";
import Tutorial_screen from "../Tutorial/tutorial.component";
import exames_image from '../Assets/Images/exames.png'
import Tela_confirmacao from "../Tela de confirmacao/confirmacao.component";

interface ConteudoTutorial {
  setor?: string;
  imagem?: string;
  explicacao?: string;
}

const Exames_component = () => {
  const conteudoAExibir: ConteudoTutorial = {
    setor: 'Exames efetuados',
    imagem: exames_image,
    explicacao: 'Aqui você pode ver os resultados dos exames efetuados com os profissionais de saúde da LCM!',
  };

  const [exames, setExames] = useState<{ status: number, info?: any[], message: string }>({ status: 404, info: [], message: "Aguardando" });
  const [estadoGeral, setEstadoGeral] = useState('loading')
  const [mensagemGeral, setMensagemGeral] = useState('Carregando...')
  const pedirExames = async () => {
    const examesObtidos = await requisitarExames();
    setExames(examesObtidos);
  };

  const listarExames = (): React.ReactNode[] | undefined => {
    return exames.info?.map((element: any, index: number) => (
      <li key={index} onClick={() => {
        window.location.href = element.webViewLink;
      }}>{`${element.name}`}</li>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      await pedirExames();
      setEstadoGeral('standby')
      setMensagemGeral('')
    };
    fetchData();
  }, []);

  return (
    <>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
      <Tutorial_screen estado='hide' conteudos={[conteudoAExibir]} />
      <Corpo_lista_exames>
        <h2>Exames disponíveis</h2>
      </Corpo_lista_exames>
      <Lista_exames>
        {exames.info?.length === 0 && <li>Sem exames</li>}
        {exames.info?.length !== 0 && listarExames()}
      </Lista_exames>
    </>
  );
};

export default Exames_component;
