import styled from 'styled-components'
export const Head_calculadora = styled.div`
  width: 75vw;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
  > * {
    margin: 0 auto;
  }
`
export const Corpo_calculadora = styled.div`
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-left: 3rem;
  padding: 2rem;
  color: ${(props) => props.theme.colors.black};
  & > .results {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  & > button {
    width: auto;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    border: none;
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
  > * {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > input {
      padding: 0.5rem;
    }
  }
`
