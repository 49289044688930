import React, { useState, useRef, useCallback, useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { TfiSave } from "react-icons/tfi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { obterDadosSociais, pedirDadosSaude, informarDadosSaude } from "../logics/def_functions";
import { Perfil_emergencia, Imagem_perfil, Campos_input, Edit_button, Normal_button, List_contacts, Organizer } from "./perfil_emergencia.style";
import Tela_confirmacao from "../Tela de confirmacao/confirmacao.component";

interface DadosSaude {
  status: number;
  message: string;
  info?: any;
}

interface ContatosEmergencia {
  tel?: string;
  name?: string;
}

const Perfil_emergencia_component = () => {
  const [estadoGeral, defEstadoGeral] = useState('loading')
  const [mensagemGeral, defMensagemGeral] = useState('Carregando...')
  const [isEditable, setIsEditable] = useState(false);
  const [contatos, setContatos] = useState<ContatosEmergencia[]>([{ tel: '3199999999', name: 'Exemplo' }]);
  const [dadosUsuario, setDadosUsuario] = useState<DadosSaude | null>(null);
  const [dadosSaude, setDadosSaude] = useState<DadosSaude | null>(null);
  const [adicionando, setAdicionando] = useState(false);

  const sangueRef = useRef<HTMLInputElement>(null);
  const alergiasRef = useRef<HTMLInputElement>(null);
  const remediosRef = useRef<HTMLInputElement>(null);
  const problemasRef = useRef<HTMLInputElement>(null);
  const contatosRef = useRef<HTMLUListElement>(null);
  const contatoRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const nomeRef = useRef<HTMLInputElement | null>(null);
  const telefoneRef = useRef<HTMLInputElement | null>(null);
  const linguagensRef = useRef<HTMLInputElement | null>(null)

  const handleClick = async () => {
    if (isEditable) {
      defEstadoGeral('loading')
      defMensagemGeral('Carregando...')
      const dados = {
        user_type_blood: sangueRef.current?.value,
        user_alergies: alergiasRef.current?.value,
        user_medicamentos: remediosRef.current?.value,
        user_contatos_emergencia: contatos,
        user_problemas_saude: problemasRef.current?.value,
        user_idiomas: linguagensRef.current?.value
      };
      console.table(dados)
      const requisicao = await informarDadosSaude(dados);
      if (requisicao.status == 200) {
        defEstadoGeral("success")
        defMensagemGeral("Sucesso na atualização dos dados!")
      }
    }
    setIsEditable(!isEditable);
  };

  const addElement = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAdicionando(true);
  };

  const removeElement = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newContatos = contatos.filter((_, i) => i !== index);
    setContatos(newContatos);
  };

  const confirmAdd = () => {
    const contato: ContatosEmergencia = {
      name: nomeRef?.current?.value || '',
      tel: telefoneRef?.current?.value || ''
    };
    setContatos([...contatos, contato]);
    setAdicionando(false);
    defEstadoGeral('success')
    defMensagemGeral('Sucesso na inclusão do contato')
  };

  const fetchData = useCallback(async () => {
    const dadosUsuario = await obterDadosSociais({ metodo: 'self' });
    setDadosUsuario(dadosUsuario);
    const dadosSaude = await pedirDadosSaude();
    console.table(dadosSaude)
    setDadosSaude(dadosSaude);
    setContatos(dadosSaude?.info?.user_contatos_emergencia || []);
  }, []);

  useEffect(() => {
    fetchData().then(() => {
      defEstadoGeral('standby')
      defMensagemGeral('')
    })
  }, [fetchData]);

  const fillContacts = (): React.ReactNode[] => {
    return contatos.map((element, index) => (
      <Organizer key={index}>
        <li>
          <div>
            <input
              type="text"
              disabled={!isEditable}
              placeholder={element?.name}
              readOnly={!isEditable}
            />
            <input
              type="text"
              disabled={!isEditable}
              placeholder={element?.tel}
              readOnly={!isEditable}
            />
          </div>
          {isEditable && (
            <Normal_button
              ref={el => contatoRefs.current[index] = el}
              onClick={(event) => removeElement(index, event)}
            >
              <AiOutlineCloseCircle color="white" size={30} />
            </Normal_button>
          )}
        </li>
      </Organizer>
    ));
  };

  return (
    <>
      <Perfil_emergencia>
        <h2>Dados de emergência do usuário</h2>
      </Perfil_emergencia>
      <Perfil_emergencia>
        <Edit_button onClick={handleClick}>
          {!isEditable && <FaRegEdit color="white" size={30} />}
          {isEditable && <TfiSave color="white" size={30} />}
        </Edit_button>
        <Imagem_perfil src={dadosUsuario?.info?.prof_pic} />
        <div><h2>Nome: {dadosUsuario?.info?.user_full_name}</h2></div>
      </Perfil_emergencia>
      <Campos_input>
        <label htmlFor="tipo_sanguineo">Tipo sanguíneo do usuário</label>
        <input
          type="text"
          id="tipo_sanguineo"
          disabled={!isEditable}
          placeholder={dadosSaude?.info?.user_type_blood || 'Sem dados informados'}
          readOnly={!isEditable}
          ref={sangueRef}
        />
        <label htmlFor="alergias">Alergias que o usuário possui</label>
        <input
          type="text"
          id="alergias"
          disabled={!isEditable}
          placeholder={dadosSaude?.info?.user_alergies || 'Sem dados informados'}
          readOnly={!isEditable}
          ref={alergiasRef}
        />
        <label htmlFor="medicacoes">Medicações regulares do usuário</label>
        <input
          type="text"
          id="medicacoes"
          disabled={!isEditable}
          placeholder={dadosSaude?.info?.user_medicamentos || 'Sem dados informados'}
          readOnly={!isEditable}
          ref={remediosRef}
        />
        <label htmlFor="problemas">Problemas de saude recorrentes</label>
        <input
          type="text"
          id="problemas"
          disabled={!isEditable}
          placeholder={dadosSaude?.info?.user_problemas_saude || 'Sem dados informados'}
          readOnly={!isEditable}
          ref={problemasRef}
        />
        <label htmlFor="linguagens">Linguagens faladas pelo usuário</label>
        <input
          type="text"
          id="linguagens"
          disabled={!isEditable}
          placeholder={dadosSaude?.info?.user_idiomas || 'Sem dados informados'}
          readOnly={!isEditable}
          ref={linguagensRef}
        />
        <label htmlFor="contatos">Contatos de emergência do usuário</label>
        <List_contacts ref={contatosRef}>
          {contatos.length === 0 && <span>Sem dados informados</span>}
          {fillContacts()}
          {adicionando && (
            <div>
              <input
                ref={nomeRef}
                type="text"
                placeholder="Insira o nome"
              />
              <input
                ref={telefoneRef}
                type="text"
                placeholder="Insira o telefone"
              />
              <Normal_button onClick={confirmAdd}>Confirmar</Normal_button>
            </div>
          )}
          {isEditable && !adicionando && (
            <Normal_button onClick={addElement}>Adicionar contato</Normal_button>
          )}
        </List_contacts>
      </Campos_input>
      <Tela_confirmacao tipo={estadoGeral} texto={mensagemGeral}></Tela_confirmacao>
    </>
  );
};

export default Perfil_emergencia_component;
