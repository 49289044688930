import Desafio_component from "../../components/Desafio/desafio_component";
import Navbar from "../../components/Navbar/navbar.component";
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component";
import ReturnButton from "../../components/Botao retornar/return.component";

const Pagina_desafios = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Desafio_component></Desafio_component>
    <Navbar active="exercicios"></Navbar>
    <Sidebar_exercicios></Sidebar_exercicios>
  </>)
}
export default Pagina_desafios
