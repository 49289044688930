import Navbar from "../../components/Navbar/navbar.component"
import Sidebar_exercicios from "../../components/Sidebar/sidebar.exercicios.component"
import Detalhes_evento from "../../components/Detalhes do Evento/event_details.component"
import ReturnButton from "../../components/Botao retornar/return.component"
const PaginaDetalhesDoEventoSocial = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Detalhes_evento></Detalhes_evento>
    <Navbar active="exercicios"></Navbar>
    <Sidebar_exercicios></Sidebar_exercicios>
  </>)
}
export default PaginaDetalhesDoEventoSocial
