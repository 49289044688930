import Navbar from "../../components/Navbar/navbar.component"
import Sidebar_saude from "../../components/Sidebar/sidebar.saude.component"
import Perfil_emergencia_component from "../../components/Dados de Emergência/perfil_emergencia.component"
import ReturnButton from "../../components/Botao retornar/return.component"
const Pagina_dados_emergencia = () => {
  return (<>
    <ReturnButton></ReturnButton>
    <Perfil_emergencia_component></Perfil_emergencia_component>
    <Navbar active="saude"></Navbar>
    <Sidebar_saude></Sidebar_saude>
  </>)
}
export default Pagina_dados_emergencia
